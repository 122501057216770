import styles from "./UpgradeToPro.module.sass";
import { Link } from "react-router-dom";
import Image from "../../components/Image";

const CreatorContract = () => {
  return (
    <>
      <header className={styles.header}>
        <Link className={styles.logo} to="/">
          <Image
            className={styles.pic}
            src="/images/logo-light.svg"
            srcDark="/images/logo-dark.svg"
            alt="OnlyGamers Logo"
          />
        </Link>
      </header>
      <section className={styles.contract}>
        <div className={styles.contract_heading}>
          <h1 className={styles.contract_heading_title}>
            Standard Contract Between Fan and Creator
          </h1>
        </div>
        <div className={styles.contract_content}>
          <ol>
            <li className={styles.contract_content_list}>
              <strong className={styles.contract_content_list_bold}>
                Introduction:
              </strong>
              This Standard Contract between Fan and Creator ("this agreement")
              sets out the terms which govern each transaction between a Fan and
              a Creator on OnlyGamers.
            </li>
            <li className={styles.contract_content_list}>
              <strong className={styles.contract_content_list_bold}>
                When does this agreement apply?
              </strong>
              Each time a Fan/Creator Transaction is initiated on OnlyGamers,
              this Standard Contract between Fan and Creator will apply to the
              exclusion of any other terms which the Fan or Creator may propose,
              legally binding the participating Fan and Creator.
            </li>
            <li className={styles.contract_content_list}>
              <strong className={styles.contract_content_list_bold}>
                Parties:
              </strong>
              The only parties to this agreement are the Fan and Creator
              participating in the Fan/Creator Transaction. OnlyGamers, or any
              of its subsidiary companies, is not a party to this agreement and
              grants no rights in respect of, nor participates in, any
              Fan/Creator Transaction, except as a payment intermediary as
              detailed in section 5.
            </li>
            <li className={styles.contract_content_list}>
              <strong className={styles.contract_content_list_bold}>
                Interpretation:
              </strong>
              In this agreement, defined terms have the following meanings:
              <ol>
                <li className={styles.contract_content_list}>
                  <strong className={styles.contract_content_list_bold}>
                    "OnlyGamers"
                  </strong>
                  means the website accessed via the URL www.onlygamers.com or
                  any web browser;
                </li>
                <li className={styles.contract_content_list}>
                  <strong className={styles.contract_content_list_bold}>
                    "Content"
                  </strong>
                  means any material uploaded to OnlyGamers by any User (whether
                  a Creator or a Fan), including but not limited to gameplays,
                  tutorials, livestreams, in-game assets, screenshots, game
                  music, comments, and any other gaming-related material;
                </li>
                <li className={styles.contract_content_list}>
                  <strong className={styles.contract_content_list_bold}>
                    "Creator"
                  </strong>
                  means a User who has set up their OnlyGamers account as a
                  Creator account to post Content on OnlyGamers to be viewed by
                  other Users;
                </li>
                <li className={styles.contract_content_list}>
                  <strong className={styles.contract_content_list_bold}>
                    "Fan"
                  </strong>
                  means a User who follows a Creator and is able to view the
                  Creator's Content;
                </li>
                <li className={styles.contract_content_list}>
                  <strong className={styles.contract_content_list_bold}>
                    "Fan/Creator Transaction"
                  </strong>
                  means any transaction between a Fan and a Creator on
                  OnlyGamers by which access is granted to the Creator's
                  Content, including in any of the following ways: a
                  Subscription;
                </li>
                <li className={styles.contract_content_list}>
                  <strong className={styles.contract_content_list_bold}>
                    "Fan Payment"
                  </strong>
                  means any and all payments made by a Fan in respect of any
                  Fan/Creator Transaction, including any and all tips;
                </li>
                <li className={styles.contract_content_list}>
                  <strong className={styles.contract_content_list_bold}>
                    "OG Fee"
                  </strong>
                  {/* ADD LINK FOR TERMS OF USE FOR CREATORS */}
                  means the fee charged to Creators in accordance with section 5
                  of the OnlyGamers Terms of Use for Creators;
                </li>
                <li className={styles.contract_content_list}>
                  <strong className={styles.contract_content_list_bold}>
                    "Subscription"
                  </strong>
                  means a Fan's subscription to a Creator's account;
                </li>
                <li className={styles.contract_content_list}>
                  <strong className={styles.contract_content_list_bold}>
                    "VAT"
                  </strong>
                  means Value Added Tax and any other tax imposed in addition to
                  or in substitution for it at the rate from time to time
                  imposed and any equivalent or similar governmental, state,
                  federal, provincial, or municipal indirect tax, charge, duty,
                  impost, or levy in any other jurisdiction;
                </li>
                <li className={styles.contract_content_list}>
                  <strong className={styles.contract_content_list_bold}>
                    "Tax"
                  </strong>
                  shall include all forms of tax and statutory, governmental,
                  state, federal, provincial, local government, or municipal
                  charges, duties, imposts, contributions, levies, withholdings,
                  or liabilities wherever chargeable and whether of the UK or
                  any other jurisdiction.
                </li>
              </ol>
            </li>
            <li className={styles.contract_content_list}>
              <strong className={styles.contract_content_list_bold}>
                Pricing and Payment:
              </strong>
              By initiating a Fan/Creator Transaction on OnlyGamers, the Fan
              agrees to the specified Fan Payment, which provides access to the
              Creator's designated Content as outlined by the Creator. This Fan
              Payment is comprehensive of any taxes, like VAT, applicable based
              on the Fan's location. Creators set and publicly disclose their
              pricing for content or subscription services on their OnlyGamers
              account. Utilizing Stripe Connect as our payment infrastructure,
              OnlyGamers acts on behalf of the creators' connected accounts to
              efficiently manage transactions. When a Fan Payment is made,
              OnlyGamers facilitates a direct charge through the creator’s
              Stripe Connect account, simultaneously deducting service fees as
              specified in the OnlyGamers Terms of Use for Creators, and then
              remitting the balance to the Creator. The particulars of service
              fees and payment processing are detailed in the OnlyGamers Terms
              of Use and Payment Policy. Fans and Creators authorize OnlyGamers,
              through Stripe Connect, to handle payments on the creators'
              behalf, which includes collecting, holding, and disbursing
              payments, in accordance with these terms. This setup empowers
              OnlyGamers to process payments, mediate disputes, and administer
              refunds in alignment with the OnlyGamers Refund Policy, while also
              collecting an application fee for facilitating these transactions.
              It is incumbent upon Creators to establish their prices clearly
              and fairly, ensuring all pricing for Content or subscriptions on
              OnlyGamers is straightforward and free from hidden fees.
              OnlyGamers reserves the right to inspect and suggest modifications
              to pricing to guarantee fairness and adherence to regulatory
              standards.
            </li>
            <li className={styles.contract_content_list}>
              <strong className={styles.contract_content_list_bold}>
                License of Content:
              </strong>
              {/* NEED TO ADD LINK FOR "ACCEPTABLE USE POLICY" */}
              License of Content: Upon completion of the Fan Payment for a
              Fan/Creator Transaction, the Creator awards the Fan a limited
              license to access the specific Content related to that transaction
              ("Relevant Content"). This license is designed to be
              non-transferable, non-sublicensable, and non-exclusive. It grants
              the Fan the right to access and view the Relevant Content on the
              Fan's personal device through standard web browsing methods.
              Additionally, this license allows for the temporary copying of
              such Content to the extent necessary for accessing the Content,
              such as caching. These activities must be conducted in full
              compliance with the OnlyGamers{" "}
              <Link to="/terms">Acceptable Use Policy.</Link>
            </li>
            <li className={styles.contract_content_list}>
              <strong className={styles.contract_content_list_bold}>
                Ownership of Content:
              </strong>
              By engaging in a Fan/Creator Transaction, the Fan acknowledges and
              concurs that the license granted for the Relevant Content, as
              detailed in the preceding section, does not convey any ownership
              rights over the Relevant Content to the Fan. All rights related to
              the Relevant Content are expressly retained by the Creator. This
              ensures that while Fans have the privilege to access and enjoy the
              content, the intellectual property and all associated rights
              remain with the Creator, preserving their creative and legal
              ownership.
            </li>
            <li className={styles.contract_content_list}>
              <strong className={styles.contract_content_list_bold}>
                Expiry of license:
              </strong>
              The license provided to a Fan for accessing the Relevant Content
              is subject to automatic expiration without prior notification
              under several conditions:
              <ol>
                <li className={styles.contract_content_list}>
                  If the Fan Payment for the Fan/Creator Transaction fails, or
                  if it's charged back or reversed for any reason.
                </li>
                <li className={styles.contract_content_list}>
                  For Subscriptions, the license expires at the end of the
                  subscribed period, except when the Subscription is set to
                  auto-renew, as detailed in the applicable terms of use.
                </li>
                <li className={styles.contract_content_list}>
                  The license will expire if the Fan’s User account is either
                  suspended or terminated for any reason.
                </li>
                <li className={styles.contract_content_list}>
                  Any breach of the platform's Acceptable Use Policy by the Fan,
                  whether related to the Relevant Content specifically or not,
                  will result in license termination.
                </li>
                <li className={styles.contract_content_list}>
                  If the Creator decides to remove the Content from their
                  account, the license will expire.
                </li>
                <li className={styles.contract_content_list}>
                  The license expires if the Fan chooses to close their User
                  account on the platform.
                </li>
              </ol>
            </li>
            <li className={styles.contract_content_list}>
              <strong className={styles.contract_content_list_bold}>
                Cancellation and refunds:
              </strong>
              Pertaining to every Fan/Creator Transaction:
              <ol>
                <li className={styles.contract_content_list}>
                  The Fan acknowledges and expressly consents to the immediate
                  supply of the relevant Content following a Fan/Creator
                  Transaction, understanding that this service begins before the
                  expiry of the 14-day cancellation period provided by the
                  Consumer Rights Act 2015 or any equivalent legislation. By
                  proceeding with the transaction, the Fan agrees to waive any
                  statutory right to cancel the transaction once the content
                  supply has started, as they have been informed and have
                  consented to this condition at the time of the transaction.
                </li>
                <li className={styles.contract_content_list}>
                  This agreement does not affect any statutory right to receive
                  a refund from the Creator which a Fan may have under the
                  Consumer Rights Act 2015 or any other applicable law.
                </li>
              </ol>
            </li>
            <li className={styles.contract_content_list}>
              <strong className={styles.contract_content_list_bold}>
                Obligations between Creator and Fan:
              </strong>
              Regarding every Fan/Creator Transaction:
              <ol>
                <li className={styles.contract_content_list}>
                  Both the Fan and the Creator engaged in the Fan/Creator
                  Transaction commit to adhering to the platform's Acceptable
                  Use Policy concerning the Relevant Content. This includes
                  compliance during access, viewing, and any form of interaction
                  with the Content.
                </li>
                <li className={styles.contract_content_list}>
                  The Fan is obligated to complete the necessary Fan Payment to
                  gain access to, view, or interact with the Relevant Content.
                  The Fan agrees to refrain from initiating a chargeback except
                  in cases where the transaction is disputed in good faith.
                </li>
                <li className={styles.contract_content_list}>
                  Upon receipt of the Fan Payment specific to the Relevant
                  Content, the Creator is bound to make said Content available
                  to the Fan.
                </li>
                <li className={styles.contract_content_list}>
                  The Creator assures (through a legally binding promise) that
                  they hold all required rights to the Relevant Content, enough
                  to grant a license to the Fan within the territory where the
                  Fan will access the Content. This includes having secured any
                  necessary permissions and consents to provide the license as
                  outlined in the agreement.
                </li>
                <li className={styles.contract_content_list}>
                  The responsibility for creating and uploading the Relevant
                  Content solely rests with the Creator. There is no commitment
                  from the Creator to continue producing and uploading new
                  Content indefinitely.
                </li>
                <li className={styles.contract_content_list}>
                  Accessing the Creator's Content by the Fan, in the absence of
                  negligence or a breach of duty by the Creator, is done at the
                  Fan's risk entirely.
                </li>
              </ol>
            </li>
            <li className={styles.contract_content_list}>
              <strong className={styles.contract_content_list_bold}>
                No Guarantees:
              </strong>
              The Fan engaged in a Fan/Creator Transaction recognizes that
              Creators have the autonomy to add and remove Content from their
              account at any given time and to decide the types of Content they
              wish to make available. Moreover, the Fan acknowledges that
              situations may arise preventing access to the Relevant Content as
              agreed upon in the Fan/Creator Transaction. Such situations
              include, but are not limited to:
              <ol>
                <li className={styles.contract_content_list}>
                  Suspension or deletion of the Creator's account;
                </li>
                <li className={styles.contract_content_list}>
                  Suspension or deletion of the Fan's account;
                </li>
                <li className={styles.contract_content_list}>
                  Partial or total unavailability of the platform due to
                  technical issues or suspensions;
                </li>
                <li className={styles.contract_content_list}>
                  The Creator's future inability to produce or upload new
                  Relevant Content.
                </li>
              </ol>
            </li>
            <li className={styles.contract_content_list}>
              <strong className={styles.contract_content_list_bold}>
                Terms relating to disputes:
              </strong>
              <ol>
                <li className={styles.contract_content_list}>
                  This agreement is subject to and will be interpreted in
                  accordance with English law, which shall govern any claim or
                  dispute arising out of or in connection with this agreement.
                  Fans are entitled to rely upon the mandatory provisions of the
                  law of the country in which they reside.
                </li>
                <li className={styles.contract_content_list}>
                  Where Claims Can Be Brought:
                  <ol>
                    <li className={styles.contract_content_list}>
                      For Fans residing within the United Kingdom or the
                      European Union, any legal claim arising under this
                      agreement may be pursued in either the courts of England
                      and Wales or in the courts of the Fan's home country,
                      providing a choice to seek resolution within a familiar
                      legal jurisdiction.
                    </li>
                    <li className={styles.contract_content_list}>
                      For Fans residing outside the United Kingdom or the
                      European Union, any legal claim under this agreement
                      should be brought to the courts of England and Wales,
                      except where an alternative jurisdiction has been mutually
                      agreed upon by the Fan and Creator.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
          Last updated: March 2024
        </div>
      </section>
    </>
  );
};

export default CreatorContract;

import { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./Answer.module.sass";
import { createComments } from "../../graphql/mutations";
import amplifyConfig from "../../amplifyConfig";
import { Amplify } from "aws-amplify";
import awsExports from "../../aws-exports"
import { generateClient } from "aws-amplify/api";
import { useSelector } from "react-redux";
const Answer = ({
    className,
    avatar,
    onClose,
    currentValue,
    postId,
    parentId,
    setCurrentValue,
    ...etc
}) => {
    const textareaRef = useRef(null);
    Amplify.configure(amplifyConfig)
	Amplify.configure(awsExports);
    const client = generateClient();
    const [isLoading,setIsloading] = useState(false);
    const[showErrorMessage,setShowErrorMessage]= useState();
    const userId = useSelector(state=>state.auth.userDetails.id);
    const userDetails = useSelector(state => state.auth.userDetails);


    useEffect(() => {
        textareaRef.current.style.height = "0px";
        const scrollHeight = textareaRef.current.scrollHeight;
        textareaRef.current.style.height = scrollHeight + "px";
    }, [currentValue]);


    const submitReply=async()=>{
        setIsloading(true);
       try{

           const createResult = await client.graphql({
               query: createComments,
               variables: {
                   input: {
                       parentCommentID:parentId,
                       postID:postId,
                       content:currentValue,
                       userID:userId,
                    },
                },
            });
            setIsloading(false)
            setCurrentValue('')
            onClose(false)
        }catch(error){
                setShowErrorMessage("Failed to submit reply, please try again")
        }
    }

   
    return (
        <div className={cn(styles.answer, className)}>
            <div className={styles.avatar}>
                {userDetails.avatar ? <img src={`${process.env.REACT_APP_S3_PUBLIC}${userDetails.avatar}`} alt="Avatar" /> : <div style={{ backgroundColor: `${userDetails?.theme}` }} className={`profile-img text-[28px] h-full rounded-full flex items-center justify-center`}>{userDetails?.username?.[0].toUpperCase()}</div>}
            </div>
            <div className={styles.details}>
                <div className={styles.message}>
                    <textarea
                        ref={textareaRef}
                        {...etc}
                        value={currentValue}
                        placeholder="Leave something to reply"
                        onChange={(e) => {
                            setCurrentValue(e.target.value);
                        }}
                    />
                </div>
                <div className={styles.btns}>
                        {isLoading?<button
                    className={cn("button button-small", styles.button, {
                        disabled: isLoading === true ,

                    })}
                    >   
                      Posting..
                    </button>:
                    <button
                    onClick={submitReply}
                    className={cn("button button-small", styles.button, {
                        disabled: currentValue === "",

                    })}
                    >   
                        Reply
                    </button>
                }
                    <button
                        className={cn(
                            "button-stroke button-small",
                            styles.button
                        )}
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Answer;

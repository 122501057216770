import { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './Dropdown.module.sass'
import { Link, NavLink, useLocation } from 'react-router-dom'
import Icon from '../../Icon'
import { useSelector } from 'react-redux'
const Dropdown = ({
  img,
  className,
  item,
  subscribersLength,
  visibleSidebar,
  setValue,
  onClose,
  key
}) => {
  const { pathname } = useLocation()
  const userSubscriptions = useSelector(state => state.userSubs.userSubscriptions);
  const [visible, setVisible] = useState(true)
  const handleClick = () => {
    setVisible(!visible)
    setValue(true)
  }
  useEffect(() => {
    if(userSubscriptions?.length > 0){
      setVisible(true)
    }
  },[userSubscriptions])
  const Head = () => {
    return (
      <button
        className={cn(
          styles.head,
          {
            [styles.active]: pathname.includes(item.slug)
          },
          { [styles.wide]: visibleSidebar }
        )}
        onClick={() => handleClick()}
      >
        <Icon name={item.icon} size='24' />
        {item.title}
        <Icon name='arrow-down' size='24' />
      </button>
    )
  }

  return (
    <div
      className={cn(
        styles.dropdown,
        className,
        { [styles.active]: visible },
        {
          [styles.active]: pathname.includes(item.slug)
        },
        { [styles.wide]: visibleSidebar }
      )}
    >

      {item.add ? (
        <div
          className={cn(styles.top, {
            [styles.active]: pathname.startsWith('/products/add')
          })}
        >
          <Head />

          <Link
            className={cn(styles.add, {
              [styles.active]: pathname.startsWith('/products/add')
            })}
            to='/products/add'
            onClick={onClose}
          >
            <Icon name='plus' size='10' />
          </Link>
        </div>
      ) : (
        <Head />
      )}
      <div className={styles.body}>
        {item.dropdown.map((x, index) => (
          <NavLink
            className={cn(styles.link, {
              [styles.active]: pathname === x.title
            })}
            to={`${subscribersLength > 0 ? x.title : '/'}`}
            key={index}
            onClick={onClose}
          >
            <div ></div>
            {subscribersLength > 0 && <img className={styles.avatar} src={x.avatar} alt="avatar" />} {x.title}
            {x.counter ? (
              <div
                className={styles.counter}
                style={{ backgroundColor: x.colorCounter }}
              >
                {x.counter}
              </div>
            ) : (
              <Icon name='arrow-next' size='24' />
            )}
          </NavLink>
        ))}
      </div>
    </div>
  )
}

export default Dropdown

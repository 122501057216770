import { generateClient } from 'aws-amplify/api';
import React from 'react'
import { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import amplifyConfig from '../../../../amplifyConfig';
import { Amplify } from 'aws-amplify';
import { deletePosts } from '../../../../graphql/mutations';


function DeletePosts({id,title,setShowDeletePosts}) {

    const userDetails = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const cardRef = useRef(null);
    const [enteredName, setEnteredName] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    Amplify.configure(amplifyConfig)
 
    const client = generateClient()
    const handleOutsideClick = (e) => {
        if (cardRef.current && !cardRef.current.contains(e.target)) {
            setShowDeletePosts(false)
            
        }
    }

    const handleDeleteUser = async () => {
        try {
          // Perform GraphQL mutation to delete the post
          await client.graphql({
            query: deletePosts,
            variables: {
              input: {
                id: id
              }
            }
          });
          
          
          setLoading(false);
          window.location.reload();
        } catch (error) {
            setError("Error deleting post. Please try again later.");   
        //   console.error('Error deleting post:', error);
          
        }
      };
    const onSubmit = () => {
        handleDeleteUser();
            setLoading(true);
    }

    const username = useSelector((state) => state.auth?.userDetails?.username);
let isVisible= true;
    return (
        createPortal(
            <div onClick={(e) => handleOutsideClick(e)} className={`deleteModal-overlay ${isVisible ? "opacity-1 pointer-events-auto" : "opacity-0 pointer-events-none"} top-0 left-0 right-0 bottom-0 z-[10000] bg-[rgb(0,0,0,0.5)] fixed w-full h-screen grid place-items-center`}>
                <div ref={cardRef} className='modal w-full max-w-[529px] bg-[#1A1D1F] flex flex-col gap-8  p-6 rounded-lg'>
                    <header className='deleteModal__header flex gap-4 items-center'>
                        <div className='box bg-[#A52222] w-[16px] h-[32px] rounded-[4px]'></div>
                        <p className='text-white font-semibold text-xl'>Delete Post</p>
                    </header>
                    <div className='deleteModal__body flex flex-col gap-3 w-full'>
                        <p>Deleting {title}</p>
                        <button onClick={onSubmit} className='delete-button self-end bg-[#A52222] rounded-xl p-3 hover:bg-[#a52222a8]'>{loading ? "Deleting..." : "Delete Post"}</button>
                    </div>
                    {error && (
                        <p className='text-[#A52222]'>{error}</p>
                    )}
                </div>
            </div>,
            document.body)
    );
}



  

export default DeletePosts
import { useNavigate }  from 'react-router-dom'
import styles from './CreatorModal.module.sass'
export default function Welcome() {
    const navigate = useNavigate();
    return (
        <div className='modalBody__container flex flex-col gap-6'>
            <header className="modalBody__header flex items-center">
                <div className="left flex items-center gap-4">
                    <span className='box block w-4 h-8 rounded bg-[#CABDFF]'></span>
                    <p className={`font-semibold ${styles.subText} text-xl`}>Welcome as a creator !</p>
                </div>
            </header>
            <div className={`p-6 ${styles.body} rounded-lg flex flex-col gap-3`}>
                <button onClick={() => {
                    navigate('/');
                }} className={`w-full relative transition-all duration-300 hover:scale-105 active:scale-100 h-[48px] ${styles.subText} font-semibold text-[15px] px-5 rounded-xl bg-[#2A85FF]`}>
                    Finish
                </button>
            </div>
        </div>
    )
}

import React, { useState } from 'react'
import cn from 'classnames'
import { confirmResetPassword, resetPassword } from 'aws-amplify/auth'
import styles from './ForgotPassword.module.sass'
import { use100vh } from 'react-div-100vh'
import TextInput from '../../components/TextInput'
import { Link } from 'react-router-dom' // Assuming you are using React Router for routing
import { useEffect } from 'react'
import Image from '../../components/Image'
const ForgotPassword = () => {
  const heightWindow = use100vh()
  const [email, setEmail] = useState('')
  const [resetCode, setResetCode] = useState('')
  const [newpassword, setNewPassword] = useState('')
  const [newConformpassword, setConformNewPassword] = useState('')
  const [resetStep, setResetStep] = useState('')
  const [resetSuccess, setResetSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const [errorMsg, seterrorMsg] = useState('')
  const handleForgotPassword = async () => {
    try {
      const username = email
      await resetPassword({ username })
      setResetStep('CONFIRM_RESET_PASSWORD_WITH_CODE')
    } catch (error) {
      setShowError(true)
      if (error.message && error.message.includes('LimitExceededException')) {
        seterrorMsg('Attempt limit exceeded, please try after some time.')
      } else if (
        error.message &&
        error.message.includes('no registered/verified email or phone_number')
      ) {
        seterrorMsg('No registered or verified email or phone number.')
      } else {
        seterrorMsg(error.message)
      }
     
    }
  }

  const handleConfirmResetPassword = async () => {
    try {
      if (newpassword !== newConformpassword) {
        setShowError(true)
        seterrorMsg('Passwords do not match.')
        return
      }
      const username = email
      const confirmationCode = resetCode
      const newPassword = newpassword
      await confirmResetPassword({
        username,
        confirmationCode,
        newPassword
      })
      
      setResetSuccess(true)
    } catch (error) {
      setShowError(true)
      if (error.message && error.message.includes('LimitExceededException')) {
        seterrorMsg('Attempt limit exceeded, please try after some time.')
      } else if (
        error.message &&
        error.message.includes('Password not long enough')
      ) {
        seterrorMsg('Password not long enough ! Should atleast be 8 character.')
      } else {
        seterrorMsg('Please Enter Correct Confirmation Code ! ')
      }

     
    }
  }
  useEffect(() => {
    let timeoutId // Automatically hide error messages after 2 seconds if there is an error
    if (showError) {
      timeoutId = setTimeout(() => setShowError(false), 3500)
    } else {
      setShowError(false) // Hide error messages immediately if there is no error
    }
    return () => {
      clearTimeout(timeoutId) //Clear the timeout when the component unmounts or when showError changes
    }
  }, [showError])
  return (
    <div>
      <div className={styles.login} style={{ minHeight: heightWindow }}>
        <div className={styles.wrapper}>
          <Link className={styles.logo} to='/'>
            <Image
              className={styles.pic}
              src='/images/logo-light.svg'
              srcDark='/images/logo-dark.svg'
              alt='Core'
            />
          </Link>
          <div className={cn('h2', styles.title)}>Forgot Password</div>
          {resetStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE' ? (
            <div>
              {resetSuccess ? (
                <div>
                  <p>Password reset successful!</p>
                  <Link to='/my/sign-in'>
                    {' '}
                    Go to <span className={styles.nav_signIn}>
                      Sign In
                    </span>{' '}
                  </Link>
                </div>
              ) : (
                <div>
                  <div>
                    <TextInput
                      className={styles.field}
                      name='new password'
                      type='password'
                      placeholder='Your new password'
                      required
                      icon='lock'
                      onChange={event => setNewPassword(event.target.value)}
                    />
                    <TextInput
                      className={styles.field}
                      name='new password'
                      type='password'
                      placeholder='Conform Your new password'
                      required
                      icon='lock'
                      onChange={event =>
                        setConformNewPassword(event.target.value)
                      }
                    />
                    <TextInput
                      className={styles.field}
                      name='code'
                      type='code'
                      value={resetCode}
                      placeholder='Verification Code'
                      required
                      icon='lock'
                      onChange={event =>
                        setResetCode(event.target.value.trim())
                      }
                    />
                    <button
                      className={cn('button ', styles.button)}
                      onClick={handleConfirmResetPassword}
                    >
                      Reset Password
                    </button>
                  </div>
                  {showError && (
                    <span className={styles.error}>
                      <br />

                      {errorMsg}
                    </span>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div>
              <TextInput
                className={styles.field}
                name='email'
                type='email'
                placeholder='Your email'
                required
                icon='mail'
                onChange={e => setEmail(e.target.value)}
              />

              <button
                className={cn('button ', styles.button)}
                onClick={handleForgotPassword}
              >
                Send Reset Code
              </button>
              {showError && (
                <span className={styles.error}>
                  <br />
                  <br />
                  {errorMsg}
                  <br />
                </span>
              )}
            </div>
          )}
          <div className={styles.note}>
            This site is protected by reCAPTCHA and the Google Privacy Policy.
          </div>
        </div>
      </div>{' '}
    </div>
  )
}

export default ForgotPassword

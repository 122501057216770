import { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';
import { deleteUser as amplifyDeleteUser } from 'aws-amplify/auth';
import { reduxLogout } from '../../slices/authSlice';

const DeleteAccountModal = ({ isVisible, onClose }) => {
    const userDetails = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const cardRef = useRef(null);
    const [enteredName, setEnteredName] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleOutsideClick = (e) => {
        if (cardRef.current && !cardRef.current.contains(e.target)) {
            onClose();
            setEnteredName("");
            setError("");
        }
    }

    const handleDeleteUser = async () => {
        try {
            // Sending request to Lambda function endpoint to delete user
            const response = await fetch(process.env.REACT_APP_DELETE_ACC, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id: userDetails?.userDetails?.id }) // Assuming user ID is available in userDetails
            });

            if (!response.ok) {
                throw new Error('Failed to delete user');
            }

            

            // After Lambda function call finishes, execute Amplify deleteUser function
            await amplifyDeleteUser();

         
        } catch (error) {
            console.error('Error deleting user:', error);
        } finally {
            setLoading(false);
            onClose();
        }
    }

    const onSubmit = () => {
        if (enteredName !== userDetails.userDetails.username) {
            setError('Username does not match');
        } else {
            setError(null);
            setLoading(true);
            handleDeleteUser();
        }
    }

    const username = useSelector((state) => state.auth?.userDetails?.username);

    return (
        createPortal(
            <div onClick={(e) => handleOutsideClick(e)} className={`deleteModal-overlay ${isVisible ? "opacity-1 pointer-events-auto" : "opacity-0 pointer-events-none"} top-0 left-0 right-0 bottom-0 z-[10000] bg-[rgb(0,0,0,0.5)] fixed w-full h-screen grid place-items-center`}>
                <div ref={cardRef} className='modal w-full max-w-[529px] bg-[#1A1D1F] flex flex-col gap-8  p-6 rounded-lg'>
                    <header className='deleteModal__header flex gap-4 items-center'>
                        <div className='box bg-[#A52222] w-[16px] h-[32px] rounded-[4px]'></div>
                        <p className='text-white font-semibold text-xl'>Delete Account</p>
                    </header>
                    <div className='deleteModal__body flex flex-col gap-3 w-full'>
                        <p>To confirm deletion, type {username} in the text input field</p>
                        <input value={enteredName} onChange={(e) => setEnteredName(e.target.value)} placeholder={username} className='p-3 bg-[#272B30] rounded-xl' />
                        <button onClick={onSubmit} className='delete-button self-end bg-[#A52222] rounded-xl p-3 hover:bg-[#a52222a8]'>{loading ? "Deleting..." : "Delete account"}</button>
                    </div>
                    {error && (
                        <p className='text-[#A52222]'>{error}</p>
                    )}
                </div>
            </div>,
            document.body)
    );
}

export default DeleteAccountModal;

import { useState, useRef, useEffect } from "react";
import styles from "./CreatorModal.module.sass";
import cn from "classnames";
// import { useAuth } from "../../../AuthContext";
import { generateClient } from "aws-amplify/api";
import TextInput from "../../TextInput";
import { useSelector, useDispatch } from "react-redux";
import { updateUser } from "../../../graphql/mutations";
import { Link } from "react-router-dom";
import InformationTerms from "./InformationTerms";
import ConnectStripe from "./ConnectStripe";
import Welcome from "./Welcome";
import { setCreator, userDetails } from "../../../slices/authSlice";
import PaginationButton from "./PaginationButton";
import { useLocation } from "react-router-dom";
import SelectPrice from "./SelectPrice";
const CreatorModalDetail = ({ item, ModalVisible, heightPx }) => {
	const client = generateClient();
	// const { setIsCreator, setUserDetails, userDetails } = useAuth();
	const sliderContainerRef = useRef();
	const userDetailsValue = useSelector((state) => state.auth.userDetails);
	const [slideItemWidth, setSlideItemWidth] = useState(0);
	const [price, setPrice] = useState(0);
	const [error, setError] = useState(false);
	const paginationArray = ['pag1', 'pag2', 'pag3', 'pag4'];
	const [activePaginationIndex, setActivePaginationIndex] = useState(0);
	const [slideWidthPerItem, setSlideWidthPerItem] = useState(0);
	const dispatch = useDispatch();
	const userId = useSelector(state => state.auth.userDetails.id);
	const location = useLocation();
	const modalStages = [<InformationTerms onPagination={() => setActivePaginationIndex(1)} />, <ConnectStripe />, <SelectPrice onPagination={() => setActivePaginationIndex(3)} />, <Welcome />]
	async function addToGroup() {
		if (!(price >= 4.99) || !(price <= 99.99)) {
			showError();
			return null;
		}

		let options = {
			method: "POST",
			body: JSON.stringify({
				userId: userDetailsValue
			}),

		};

		fetch(
			"https://ktguq45gwn4rzznxdwwicochh40tlwlx.lambda-url.eu-north-1.on.aws/",
			options
		)
			.then(async (response) => {
				const updateResult = await client.graphql({
					query: updateUser,
					variables: {
						input: {
							id: userDetailsValue.id,
							isCreator: true,
							email: userDetailsValue.email,
							creator_price: price,
						},
					},
				});
				dispatch(userDetails(updateResult.data.updateUser));
			})
			.then(() => {
				dispatch(setCreator(true));
				ModalVisible();
			})
	}

	function showError() {
		setError(true);

		setTimeout(() => {
			setError(false);
		}, 2000);
	}

	const updateSliderWidth = () => {
		if (sliderContainerRef.current) {
			setSlideItemWidth(sliderContainerRef.current.offsetWidth);
		}
	};

	useEffect(() => {
		updateSliderWidth();
		window.addEventListener('resize', updateSliderWidth);
		return () => {
			window.removeEventListener('resize', updateSliderWidth);
		};

	}, []);
	useEffect(() => {
		if (activePaginationIndex === 0) {
			setSlideWidthPerItem(activePaginationIndex * slideItemWidth);
		} else if (activePaginationIndex === 2) {
			setSlideWidthPerItem((activePaginationIndex * slideItemWidth) + 40);
		} else if (activePaginationIndex === 3) {
			setSlideWidthPerItem((activePaginationIndex * slideItemWidth) + 60);
		} else {
			setSlideWidthPerItem((activePaginationIndex * slideItemWidth) + 20);
		}

	}, [activePaginationIndex, slideItemWidth]);
	useEffect(() => {
		const urlEndsWithStripe = location.pathname.endsWith('my/stripe');
		if (urlEndsWithStripe) setActivePaginationIndex(2);
	}, [location]); // Update when the URL changes 
	return (
		<>
			<div className={styles.details}>
				{/* Slider container */}
				<div className="sliderContainer overflow-hidden">
					<div ref={sliderContainerRef} style={{ transform: `translateX(-${slideWidthPerItem}px)` }} className={cn("sliders flex max-w-full whitespace-nowrap gap-5")}>
						{/* Slide 	items */}
						{modalStages.map((_, index) => (
							<div key={index} className={styles.row}>
								<div style={{ width: `${slideItemWidth}px` }}>
									{modalStages[index]}
								</div>
							</div>
						))}
					</div>
				</div>

				{/* Pagination buttons */}
				<div className="pagination-buttons flex gap-4 justify-center items-center mt-6">
					{paginationArray.map((item, idx) => (
						<PaginationButton key={item} isActive={idx === activePaginationIndex} />
					))}
				</div>
			</div>
		</>
	);
};

export default CreatorModalDetail;

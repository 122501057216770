/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createLikes = /* GraphQL */ `
  mutation CreateLikes(
    $input: CreateLikesInput!
    $condition: ModelLikesConditionInput
  ) {
    createLikes(input: $input, condition: $condition) {
      id
      postID
      userID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateLikes = /* GraphQL */ `
  mutation UpdateLikes(
    $input: UpdateLikesInput!
    $condition: ModelLikesConditionInput
  ) {
    updateLikes(input: $input, condition: $condition) {
      id
      postID
      userID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteLikes = /* GraphQL */ `
  mutation DeleteLikes(
    $input: DeleteLikesInput!
    $condition: ModelLikesConditionInput
  ) {
    deleteLikes(input: $input, condition: $condition) {
      id
      postID
      userID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createComments = /* GraphQL */ `
  mutation CreateComments(
    $input: CreateCommentsInput!
    $condition: ModelCommentsConditionInput
  ) {
    createComments(input: $input, condition: $condition) {
      id
      content
      userID
      postID
      parentCommentID
      replies {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateComments = /* GraphQL */ `
  mutation UpdateComments(
    $input: UpdateCommentsInput!
    $condition: ModelCommentsConditionInput
  ) {
    updateComments(input: $input, condition: $condition) {
      id
      content
      userID
      postID
      parentCommentID
      replies {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteComments = /* GraphQL */ `
  mutation DeleteComments(
    $input: DeleteCommentsInput!
    $condition: ModelCommentsConditionInput
  ) {
    deleteComments(input: $input, condition: $condition) {
      id
      content
      userID
      postID
      parentCommentID
      replies {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCreatorSubscription = /* GraphQL */ `
  mutation CreateCreatorSubscription(
    $input: CreateCreatorSubscriptionInput!
    $condition: ModelCreatorSubscriptionConditionInput
  ) {
    createCreatorSubscription(input: $input, condition: $condition) {
      id
      stripeSubscriptionId
      subscriber_id
      subscribed_to_id
      price
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCreatorSubscription = /* GraphQL */ `
  mutation UpdateCreatorSubscription(
    $input: UpdateCreatorSubscriptionInput!
    $condition: ModelCreatorSubscriptionConditionInput
  ) {
    updateCreatorSubscription(input: $input, condition: $condition) {
      id
      stripeSubscriptionId
      subscriber_id
      subscribed_to_id
      price
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCreatorSubscription = /* GraphQL */ `
  mutation DeleteCreatorSubscription(
    $input: DeleteCreatorSubscriptionInput!
    $condition: ModelCreatorSubscriptionConditionInput
  ) {
    deleteCreatorSubscription(input: $input, condition: $condition) {
      id
      stripeSubscriptionId
      subscriber_id
      subscribed_to_id
      price
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCreatorDetails = /* GraphQL */ `
  mutation CreateCreatorDetails(
    $input: CreateCreatorDetailsInput!
    $condition: ModelCreatorDetailsConditionInput
  ) {
    createCreatorDetails(input: $input, condition: $condition) {
      id
      stripeAccountID
      stripeSubscriptionID
      stripePriceID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCreatorDetails = /* GraphQL */ `
  mutation UpdateCreatorDetails(
    $input: UpdateCreatorDetailsInput!
    $condition: ModelCreatorDetailsConditionInput
  ) {
    updateCreatorDetails(input: $input, condition: $condition) {
      id
      stripeAccountID
      stripeSubscriptionID
      stripePriceID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCreatorDetails = /* GraphQL */ `
  mutation DeleteCreatorDetails(
    $input: DeleteCreatorDetailsInput!
    $condition: ModelCreatorDetailsConditionInput
  ) {
    deleteCreatorDetails(input: $input, condition: $condition) {
      id
      stripeAccountID
      stripeSubscriptionID
      stripePriceID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSubscriberRequest = /* GraphQL */ `
  mutation CreateSubscriberRequest(
    $input: CreateSubscriberRequestInput!
    $condition: ModelSubscriberRequestConditionInput
  ) {
    createSubscriberRequest(input: $input, condition: $condition) {
      id
      user_id
      creator_id
      title
      status
      content
      likes
      Creator {
        id
        email
        username
        banner
        tagline
        creator_price
        subscribers
        postsID
        theme
        requestsID
        recommended
        commentID
        avatar
        socials
        isCreator
        createdAt
        updatedAt
        __typename
      }
      donations {
        userId
        invoiceId
        amount
        __typename
      }
      createdAt
      updatedAt
      subscriberRequestCreatorId
      __typename
    }
  }
`;
export const updateSubscriberRequest = /* GraphQL */ `
  mutation UpdateSubscriberRequest(
    $input: UpdateSubscriberRequestInput!
    $condition: ModelSubscriberRequestConditionInput
  ) {
    updateSubscriberRequest(input: $input, condition: $condition) {
      id
      user_id
      creator_id
      title
      status
      content
      likes
      Creator {
        id
        email
        username
        banner
        tagline
        creator_price
        subscribers
        postsID
        theme
        requestsID
        recommended
        commentID
        avatar
        socials
        isCreator
        createdAt
        updatedAt
        __typename
      }
      donations {
        userId
        invoiceId
        amount
        __typename
      }
      createdAt
      updatedAt
      subscriberRequestCreatorId
      __typename
    }
  }
`;
export const deleteSubscriberRequest = /* GraphQL */ `
  mutation DeleteSubscriberRequest(
    $input: DeleteSubscriberRequestInput!
    $condition: ModelSubscriberRequestConditionInput
  ) {
    deleteSubscriberRequest(input: $input, condition: $condition) {
      id
      user_id
      creator_id
      title
      status
      content
      likes
      Creator {
        id
        email
        username
        banner
        tagline
        creator_price
        subscribers
        postsID
        theme
        requestsID
        recommended
        commentID
        avatar
        socials
        isCreator
        createdAt
        updatedAt
        __typename
      }
      donations {
        userId
        invoiceId
        amount
        __typename
      }
      createdAt
      updatedAt
      subscriberRequestCreatorId
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      username
      banner
      tagline
      creator_price
      subscriptions {
        userId
        status
        expireDate
        stripeSubscriptionID
        __typename
      }
      subscribers
      postsID
      theme
      requestsID
      recommended
      commentID
      avatar
      Posts {
        nextToken
        __typename
      }
      socials
      isCreator
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      username
      banner
      tagline
      creator_price
      subscriptions {
        userId
        status
        expireDate
        stripeSubscriptionID
        __typename
      }
      subscribers
      postsID
      theme
      requestsID
      recommended
      commentID
      avatar
      Posts {
        nextToken
        __typename
      }
      socials
      isCreator
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      username
      banner
      tagline
      creator_price
      subscriptions {
        userId
        status
        expireDate
        stripeSubscriptionID
        __typename
      }
      subscribers
      postsID
      theme
      requestsID
      recommended
      commentID
      avatar
      Posts {
        nextToken
        __typename
      }
      socials
      isCreator
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createReportBug = /* GraphQL */ `
  mutation CreateReportBug(
    $input: CreateReportBugInput!
    $condition: ModelReportBugConditionInput
  ) {
    createReportBug(input: $input, condition: $condition) {
      userEmail
      description
      image
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateReportBug = /* GraphQL */ `
  mutation UpdateReportBug(
    $input: UpdateReportBugInput!
    $condition: ModelReportBugConditionInput
  ) {
    updateReportBug(input: $input, condition: $condition) {
      userEmail
      description
      image
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteReportBug = /* GraphQL */ `
  mutation DeleteReportBug(
    $input: DeleteReportBugInput!
    $condition: ModelReportBugConditionInput
  ) {
    deleteReportBug(input: $input, condition: $condition) {
      userEmail
      description
      image
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPosts = /* GraphQL */ `
  mutation CreatePosts(
    $input: CreatePostsInput!
    $condition: ModelPostsConditionInput
  ) {
    createPosts(input: $input, condition: $condition) {
      id
      title
      description
      uploadContent
      thumbnail
      tags
      likes
      user {
        id
        email
        username
        banner
        tagline
        creator_price
        subscribers
        postsID
        theme
        requestsID
        recommended
        commentID
        avatar
        socials
        isCreator
        createdAt
        updatedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePosts = /* GraphQL */ `
  mutation UpdatePosts(
    $input: UpdatePostsInput!
    $condition: ModelPostsConditionInput
  ) {
    updatePosts(input: $input, condition: $condition) {
      id
      title
      description
      uploadContent
      thumbnail
      tags
      likes
      user {
        id
        email
        username
        banner
        tagline
        creator_price
        subscribers
        postsID
        theme
        requestsID
        recommended
        commentID
        avatar
        socials
        isCreator
        createdAt
        updatedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePosts = /* GraphQL */ `
  mutation DeletePosts(
    $input: DeletePostsInput!
    $condition: ModelPostsConditionInput
  ) {
    deletePosts(input: $input, condition: $condition) {
      id
      title
      description
      uploadContent
      thumbnail
      tags
      likes
      user {
        id
        email
        username
        banner
        tagline
        creator_price
        subscribers
        postsID
        theme
        requestsID
        recommended
        commentID
        avatar
        socials
        isCreator
        createdAt
        updatedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;

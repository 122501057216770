import { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from '../../Item.module.sass'
import Control from '../../Control'
// import { useContext } from 'react'
// import { UserContext } from '../../../../../UserContext'
import { useSelector } from 'react-redux'
import awsExports from "../../../../../../../aws-exports";
import { getUser } from '../../../../../../../graphql/queries'
import amplifyConfig from '../../../../../../../amplifyConfig'
import { Amplify } from 'aws-amplify'
import { generateClient } from 'aws-amplify/api'
const Replies = ({ className, item ,postId}) => {
  Amplify.configure(amplifyConfig)
	Amplify.configure(awsExports);

	const client = generateClient();
  const [currentValue, setCurrentValue] = useState('')
  const [currentValueAnswer, setCurrentValueAnswer] = useState('')
  // const userData = useSelector((state) => state.user.userData);
  const [userName,setUserName]= useState();
  const [userAvatar,setUserAvatar]= useState();

 useEffect(()=>{
  const fetchUserData = async()=>{
    const {data} = await client.graphql({
      query: `
        query GetUser($id: ID!) {
          getUser(id: $id) {
            avatar
            username
          }
        }
      `,
      variables: {
        id: item.userID
      }
    });
     
  
    setUserName(data.getUser.username,"username")
    setUserAvatar(data.getUser.avatar,"avatar")
  }
  fetchUserData();
 })


  return (
    <><div className={styles.replies}> 

      <div className={cn(styles.item, className)}>
        <div className={styles.avatar}>
          <img src={`${process.env.REACT_APP_S3_PUBLIC}${userAvatar}`} alt='Avatar' />
        </div>
        <div className={styles.details}>
          <div className={styles.line}>
            <div className={styles.author}>{userName}</div>

          </div>
          <div className={styles.login}>{item.login}</div>
          <div
            className={styles.comment}
            dangerouslySetInnerHTML={{ __html: item.content }}
            ></div>
          <Control
            postId={postId}
            parentId={item.id}
            valueAnswer={currentValue}
            setValueAnswer={setCurrentValue}
            />
        </div>
      </div> 
    
            </div>
      
    </>
  )
}

export default Replies

import { useState } from "react";
import styles from "./NewProduct.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Modal from "../../components/Modal";
import NameAndDescription from "./NameAndDescription";
import UploadContent from "./UploadContent_Component";
import Tags from "./Tags_Component";
import Thumbnail from "./Thumbnail_Component";
import Panel from "./Panel";
import { FileProvider } from "./FileContext";
const CreatePost = () => {
	const [visibleModal, setVisibleModal] = useState(false); // Likely for scheduling

	return (
		<FileProvider>	
      		<div className={styles.row}>
				<div className={styles.col}>
					{/* Components for entering post details */}
					<NameAndDescription className={styles.card} />
					<UploadContent className={styles.card} />
					<Thumbnail className={styles.card}/>
					<Tags className={styles.card} />
				</div>
				<div className={styles.col}>
					{/* Potentially empty for layout purposes? */}
				</div>
			</div>
		
			<Panel
				setVisibleSchedule={setVisibleModal} // Updates modal visibility
			/>
			<TooltipGlodal />
			<Modal
				visible={visibleModal}
				onClose={() => setVisibleModal(false)}
			>
				{/* Content for the scheduling/publishing modal */}
			</Modal>
		</FileProvider>
	);
};

export default CreatePost;

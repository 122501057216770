import  { useState } from "react";
import cn from "classnames";
import styles from "./Control.module.sass";
import Icon from "../../../../../Icon";
import Answer from "../../../../../Answer";
import Replies from "./Replies";
import { listComments } from "../../../../../../graphql/queries";
import { generateClient } from "aws-amplify/api";
import amplifyConfig from "../../../../../../amplifyConfig";
import { Amplify } from "aws-amplify";

const Control = ({ valueAnswer, setValueAnswer,postId, parentId }) => {
  const [visibleFavorite, setVisibleFavorite] = useState(false);
  const [visibleReply, setVisibleReply] = useState(false);

	const client = generateClient();
  const [allReplies,setAllReplies] = useState([]);
  const fetchComments = async () => {
    try {
      const { data } = await client.graphql({
        query: listComments,
        variables: {
          filter: { parentCommentID: { contains: `${parentId}` } },
        },
      });
  
      setAllReplies(data.listComments.items);
    } catch (error) {
      // showErrorMessage("Oops! Technical glitch. Refresh, please! ");
    }
  };

  return (
    <>
      <div className={styles.control}>
        <button
          className={cn(styles.favorite, { [styles.active]: visibleFavorite })}
          onClick={() => setVisibleFavorite(!visibleFavorite)}
        >
          <span>
            <Icon name="heart" size="20" />
            <Icon name="heart-fill" size="20" />
          </span>
          Like
        </button>
        <button
          className={cn(styles.reply, { [styles.active]: visibleReply })}
          onClick={() => setVisibleReply(true)}
        >
          <span>
            <Icon name="repeat" size="20" />
          </span>
          Reply
        </button>
        <button
          className={cn(styles.favorite, { [styles.active]: visibleFavorite })}
          onClick={fetchComments}
        > 
          <span>
            <Icon name="message" size="20" />
          </span>
          Replies
        </button>
      </div>
      <div className={styles.list}>
          {allReplies.length > 0 &&
            allReplies.map((x, index) => (
              <Replies postId={postId} item={x} key={index} />
              ))}
        </div>
      {/* <Replies item={item}/> */}
      <Answer
        className={cn(styles.answer, { [styles.show]: visibleReply })}
        avatar="/images/content/avatar.jpg"
        postId={postId}
        parentId={parentId}
        onClose={() => setVisibleReply(false)}
        currentValue={valueAnswer}
        setCurrentValue={setValueAnswer}
      />
    </>
  );
};

export default Control;

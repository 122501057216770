import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAutheticated: false,
  isCreator: false,
  email: null,
  userDetails: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signInRedux: (state, action) => {
      state.isAutheticated = action.payload;
    },
    userDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setCreator: (state, action) => {
      state.isCreator = action.payload;
    },
    reduxLogout: (state) => {
      state.isAutheticated = false;
      state.isCreator = false;
      state.userDetails = {};
    },
    setUserEmail: (state, action) => {
      state.email = action.payload;
    },
    updateUsername: (state, action) => {
      state.userDetails.username = action.payload;
    },
    UpdateAvatar: (state, action) => {
      state.userDetails.avatar = action.payload;
    },
    updateBanner: (state, action) => {
      state.userDetails.banner = action.payload;
    },
  },
});

export const {
  signInRedux,
  setCreator,
  userDetails,
  reduxLogout,
  setUserEmail,
  updateUsername,
  updateBanner,
  UpdateAvatar,
} = authSlice.actions;

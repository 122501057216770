import { useState } from "react";
import cn from "classnames";
import styles from "./Page.module.sass";
import Sidebar from "../Sidebar";
import Header from "../Header";
// import { useAuth } from "../../AuthContext";
import { Outlet } from "react-router";

const 
Page = ({ wide, children, title }) => {
	const [visible, setVisible] = useState(false);


	// const { setIsCreator, setUserDetails, userDetails } = useAuth()
	return (
		<>
			<div className={styles.page}>
				<Sidebar
					className={cn(styles.sidebar, {
						[styles.visible]: visible,
					})}
					onClose={() => setVisible(false)}
				/>
				<Header onOpen={() => setVisible(true)} />
				<div className={styles.inner}>
					<div
						className={cn(styles.container, {
							[styles.wide]: wide,
						})}
					>
						{title && (
							<div className={cn("h3", styles.title)}>
								{title}
							</div>
						)}
						{children}
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
};

export default Page;

import  { useState } from 'react'
import cn from 'classnames'
import styles from './Overview.module.sass'
import ModalPreview from '../../../../ModalPreview'
import { useEffect } from 'react'
// import { UserContext } from '../../../../../UserContext'
import defaultImg from '../../../../../Default Images/banner.png'
import { useSelector } from 'react-redux'
import amplifyConfig from '../../../../../amplifyConfig'
import { generateClient } from 'aws-amplify/api'
import { Amplify } from 'aws-amplify'
import { updatePosts } from '../../../../../graphql/mutations'

const Overview = ({ item }) => {
  // const { userData, userAvatar } = useContext(UserContext)
  const userData = useSelector((state) => state.user.userData);
  const [editDescription,setEditdescription]= useState(false); 
  const [editTitle,setEditTitle]= useState(false); 
  const [newTitle,setNewTitle]= useState(item.title);
  const [loading,setLoading]=useState(false); 
  const [newDescription,setNewDescription]= useState(item.description); 
  const [visibleModalPreview, setVisibleModalPreview] = useState(false)
  const link = item.uploadContent
  const userVideoLink = item?.uploadContent
  Amplify.configure(amplifyConfig)
  const client = generateClient()
  const handleTitleChange=async()=>{
  try{
    setLoading(true);
    await client.graphql({
      query: updatePosts,
      variables: {
        input: {
          id:item.id,
          title:newTitle
        }
      }
    })	
    setLoading(false);
   window.location.reload(); 
  }catch(error){
   
  }

  
  }

  const handleDescriptionChange=async()=>{
  try{
    setLoading(true);
    await client.graphql({
      query: updatePosts,
      variables: {
        input: {
          id:item.id,
          description:newDescription
        }
      }
    })	
    setLoading(false);
    window.location.reload(); 
  }catch(error){
  
  }
  }

  return (
    <>
      <div className={styles.overview}>


        <div className={styles.gallery}>
          <div className={styles.preview} key={item.id}>
            {link && (
              <video controls className={cn(styles.video)}>
                <source src={`${process.env.REACT_APP_S3_PUBLIC}${userVideoLink}`} alt='Product' type='video/mp4' />
                Your browser does not support the video tag.
              </video>
            )}
          </div>

         
        </div>
       
        <div className={styles.edit_title} >
          {
              editTitle?
              <>
              <input placeholder={item.title} value={newTitle} onChange={(e)=>setNewTitle(e.target.value)}/>
              <div className={styles.content_edit_buttons}> 
              <button className={styles.content_edit} onClick={handleTitleChange}>{loading?<p>Saving Changes...</p>:<p>Save Changes</p>}</button>
                <button className={styles.content_cancel} onClick={()=>setEditTitle(false)}>Cancel</button>
            </div>
              </>:
              <>
              <p className={cn('h4', styles.title)}> {item.title}</p>
              <button className={styles.content_edit} onClick={()=>setEditTitle(true)}>Edit Title</button>
              </>
              
            }
          </div>
        
        <div className={styles.line}>
          <div className={styles.author}>
            <div className={styles.avatar}>
              <img src={`${process.env.REACT_APP_S3_PUBLIC}${userData[0].avatar}`} alt='Avatar' />
            </div>
            by <span>{userData[0].username}</span>
          </div>
         
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={cn('title-red', styles.subtitle)}>Description</div>
            <div className={styles.content}>
            {
              editDescription?
              <>
              <textarea placeholder={item.description} value={newDescription} onChange={(e)=>setNewDescription(e.target.value)}/>
              <div className={styles.content_edit_buttons}> 
              <button className={styles.content_edit} onClick={handleDescriptionChange}>{loading?<p>Saving Changes...</p>:<p>Save Changes</p>}</button>
                <button className={styles.content_cancel} onClick={()=>setEditdescription(false)}>Cancel</button>
            </div>
              </>:
              <>
              <p>{item.description}</p>
              <button className={styles.content_edit} onClick={()=>setEditdescription(true)}>Edit Description</button>
              </>
              
            }
            
            
            
            </div>
          </div>
         
        </div>
      </div>
      <ModalPreview
        visible={visibleModalPreview}
        onClose={() => setVisibleModalPreview(false)}
        title='Fleet - Travel shopping UI design kit'
        figcaption='Elegant product mockup for your next project'
        download
      />
    </>
  )
}

export default Overview

import { createContext, useContext, useState } from "react";

const fileContext = createContext();

export const FileProvider = ({ children }) => {
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailKey, setThumbnailKey] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [videoKey, setVideoKey] = useState(null);

  return (
    <fileContext.Provider
      value={{
        thumbnail,
        setThumbnail,
        thumbnailKey,
        setThumbnailKey,
        videoFile,
        setVideoFile,
        videoKey,
        setVideoKey,
      }}
    >
      {children}
    </fileContext.Provider>
  );
};

export const useFileContext = () => useContext(fileContext);

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getLikes = /* GraphQL */ `
  query GetLikes($id: ID!) {
    getLikes(id: $id) {
      id
      postID
      userID
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listLikes = /* GraphQL */ `
  query ListLikes(
    $filter: ModelLikesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        postID
        userID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const likesByPostIDAndUserID = /* GraphQL */ `
  query LikesByPostIDAndUserID(
    $postID: ID!
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLikesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likesByPostIDAndUserID(
      postID: $postID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        postID
        userID
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getComments = /* GraphQL */ `
  query GetComments($id: ID!) {
    getComments(id: $id) {
      id
      content
      userID
      postID
      parentCommentID
      replies {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        userID
        postID
        parentCommentID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const commentsByParentCommentID = /* GraphQL */ `
  query CommentsByParentCommentID(
    $parentCommentID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByParentCommentID(
      parentCommentID: $parentCommentID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        userID
        postID
        parentCommentID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCreatorSubscription = /* GraphQL */ `
  query GetCreatorSubscription($id: ID!) {
    getCreatorSubscription(id: $id) {
      id
      stripeSubscriptionId
      subscriber_id
      subscribed_to_id
      price
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCreatorSubscriptions = /* GraphQL */ `
  query ListCreatorSubscriptions(
    $filter: ModelCreatorSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCreatorSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        stripeSubscriptionId
        subscriber_id
        subscribed_to_id
        price
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCreatorDetails = /* GraphQL */ `
  query GetCreatorDetails($id: ID!) {
    getCreatorDetails(id: $id) {
      id
      stripeAccountID
      stripeSubscriptionID
      stripePriceID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCreatorDetails = /* GraphQL */ `
  query ListCreatorDetails(
    $filter: ModelCreatorDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCreatorDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        stripeAccountID
        stripeSubscriptionID
        stripePriceID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubscriberRequest = /* GraphQL */ `
  query GetSubscriberRequest($id: ID!) {
    getSubscriberRequest(id: $id) {
      id
      user_id
      creator_id
      title
      status
      content
      likes
      Creator {
        id
        email
        username
        banner
        tagline
        creator_price
        subscribers
        postsID
        theme
        requestsID
        recommended
        commentID
        avatar
        socials
        isCreator
        createdAt
        updatedAt
        __typename
      }
      donations {
        userId
        invoiceId
        amount
        __typename
      }
      createdAt
      updatedAt
      subscriberRequestCreatorId
      __typename
    }
  }
`;
export const listSubscriberRequests = /* GraphQL */ `
  query ListSubscriberRequests(
    $filter: ModelSubscriberRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriberRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        creator_id
        title
        status
        content
        likes
        createdAt
        updatedAt
        subscriberRequestCreatorId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      username
      banner
      tagline
      creator_price
      subscriptions {
        userId
        status
        expireDate
        stripeSubscriptionID
        __typename
      }
      subscribers
      postsID
      theme
      requestsID
      recommended
      commentID
      avatar
      Posts {
        nextToken
        __typename
      }
      socials
      isCreator
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        username
        banner
        tagline
        creator_price
        subscribers
        postsID
        theme
        requestsID
        recommended
        commentID
        avatar
        socials
        isCreator
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        username
        banner
        tagline
        creator_price
        subscribers
        postsID
        theme
        requestsID
        recommended
        commentID
        avatar
        socials
        isCreator
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userByUsername = /* GraphQL */ `
  query UserByUsername(
    $username: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        username
        banner
        tagline
        creator_price
        subscribers
        postsID
        theme
        requestsID
        recommended
        commentID
        avatar
        socials
        isCreator
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReportBug = /* GraphQL */ `
  query GetReportBug($id: ID!) {
    getReportBug(id: $id) {
      userEmail
      description
      image
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listReportBugs = /* GraphQL */ `
  query ListReportBugs(
    $filter: ModelReportBugFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportBugs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        userEmail
        description
        image
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPosts = /* GraphQL */ `
  query GetPosts($id: ID!) {
    getPosts(id: $id) {
      id
      title
      description
      uploadContent
      thumbnail
      tags
      likes
      user {
        id
        email
        username
        banner
        tagline
        creator_price
        subscribers
        postsID
        theme
        requestsID
        recommended
        commentID
        avatar
        socials
        isCreator
        createdAt
        updatedAt
        __typename
      }
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        uploadContent
        thumbnail
        tags
        likes
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const postsByUserID = /* GraphQL */ `
  query PostsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPostsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        uploadContent
        thumbnail
        tags
        likes
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

import styles from "./UpgradeToPro.module.sass";
import { Link } from "react-router-dom";
import Image from "../../components/Image";

const Terms = () => {
  return (
    <>
      <header className={styles.header}>
        <Link className={styles.logo} to="/">
          <Image
            className={styles.pic}
            src="/images/logo-light.svg"
            srcDark="/images/logo-dark.svg"
            alt="Core"
          />
        </Link>
      </header>
      <section className={styles.terms}>
        <div className={styles.terms_heading}>
          <h1 className={styles.terms_heading_title}>Terms of services</h1>
        </div>
        <div className={styles.terms_content}>
          <h2 className={styles.terms_heading_content_title}>Contents</h2>
          <ol>
            <li className={styles.terms_content_list}>
            Terms of Use for all Users
            </li>
            <li className={styles.terms_content_list}>
            Terms of Use for Fans
            </li>
            <li className={styles.terms_content_list}>
            Terms of Use for Creators
            </li>
            <li className={styles.terms_content_list}>
            Acceptable Use Policy
            </li>
          </ol>
          <h2 className={styles.terms_heading_content_title}>
            TERMS OF USE FOR ALL USERS
          </h2>
          <h3>
            BY USING OUR WEBSITE YOU AGREE TO THESE TERMS – PLEASE READ THEM
            CAREFULLY
          </h3>
          <ol>
            <li className={styles.terms_content_list}>
              <strong className={styles.terms_content_list_bold}>
                Introduction:
              </strong>
              These Terms of Use for all Users govern your use of OnlyGamers and
              your agreement with us.
            </li>
            <li className={styles.terms_content_list}>
              <strong className={styles.terms_content_list_bold}>
                Interpretation:
              </strong>
              In these Terms of Service:
              <ol>
                <li className={styles.terms_content_list}>
                  We refer to our website as "OnlyGamers", which is accessed via
                  the URL;
                </li>
                <li className={styles.terms_content_list}>
                  References to "we", "our", "us" are references to OnlyGamers
                  Inc, the operator of OnlyGamers;
                </li>
                <li className={styles.terms_content_list}>
                  "Content" means any material uploaded to OnlyGamers by any
                  User (whether a Creator or a Gamer), including any photos,
                  videos, audio (for example, music and other sounds),
                  livestream material, data, text (such as comments and tags),
                  metadata, images, interactive features, emojis, GIFs, memes,
                  game walkthroughs, tips, tricks, and any other material
                  whatsoever related to gaming;
                </li>
                <li className={styles.terms_content_list}>
                  "Creator" means a User who has set up their OnlyGamers account
                  as a Creator account to post Content on OnlyGamers to be
                  viewed by other Users;
                </li>
                <li className={styles.terms_content_list}>
                  "Gamer" means a User who follows a Creator and is able to view
                  the Creator's Content;
                </li>
                <li className={styles.terms_content_list}>
                  "Gamer Payment" means any and all payments made by a Gamer to
                  a Creator (i) in connection with a Gamer/Creator Transaction,
                  or (ii) by way of a tip for a Creator;
                </li>
                <li className={styles.terms_content_list}>
                  "Referring User" means a User who participates in the
                  OnlyGamers Referral Program;
                </li>
                <li className={styles.terms_content_list}>
                  "Standard Contract between Gamer and Creator" means the terms
                  which govern each Gamer/Creator Transaction, which can be
                  found here;
                </li>
                <li className={styles.terms_content_list}>
                  "Subscription" means a Gamer's subscription to a Creator's
                  account (whether paid or unpaid, and whether for one month or
                  as part of a bundle comprising a subscription for more than
                  one month);
                </li>
                <li className={styles.terms_content_list}>
                  "Terms of Service" (also called "your agreement with us")
                  means the legally binding agreement between you and us which
                  consists of: (i) these Terms of Use for all Users, (ii) Terms
                  of Use for Gamers, (iii) Terms of Use for Creators, (iv)
                  Privacy Policy, (v) Acceptable Use Policy, (vi) Referral
                  Program Terms, (vii) Complaints Policy, (viii) Platform to
                  Business Regulation Terms; and (ix) Community Guidelines;
                </li>
                <li className={styles.terms_content_list}>
                  "User" means any user of OnlyGamers, whether a Creator or a
                  Gamer or both (also referred to as "you" or "your").
                </li>
              </ol>
            </li>
            <li className={styles.terms_content_list}>
              <strong className={styles.terms_content_list_bold}>
                Who we are and how to contact us:
              </strong>
              OnlyGamers is operated by [Your Company Name]. We are a company in
              the process of registration. Until our formal registration is
              complete, we do not have a registered office address or company
              registration number.
              <br />
              To contact us with any questions about OnlyGamers, please email
              our support team at contact@only-gamers.com. Currently, email is
              the best and only way to reach us for inquiries, support, or
              feedback regarding our platform.
            </li>
            <li className={styles.terms_content_list}>
              <strong className={styles.terms_content_list_bold}>
                How we may change the Terms of Service:
              </strong>
              We may change any part of the Terms of Service without telling you
              beforehand in the following circumstances:
              <ol>
                <li className={styles.terms_content_list}>
                  To reflect changes in laws and regulatory requirements which
                  apply to OnlyGamers and the services, features, and programs
                  of OnlyGamers where such changes require OnlyGamers to change
                  its terms and conditions in a manner which does not allow us
                  to give reasonable notice to you; and
                </li>
                <li className={styles.terms_content_list}>
                  To address an unforeseen and imminent danger related to
                  defending OnlyGamers, Gamers, or Creators from fraud, malware,
                  spam, data breaches, or other cybersecurity risks.
                </li>
                We may also make other changes to any part of the Terms of
                Service, and we will give you reasonable notice of such changes
                by email or through OnlyGamers, and you may contact us to end
                your agreement with us before the changes take effect. Once any
                updated Terms of Service are in effect, you will be bound by
                them if you continue to use OnlyGamers.
              </ol>
            </li>
            <li className={styles.terms_content_list}>
              <strong className={styles.terms_content_list_bold}>
                Registering with OnlyGamers:
              </strong>
              To use OnlyGamers, you must first register and create a User
              account on OnlyGamers. You must provide a valid email address, a
              username, and a password, or authenticate using a valid social
              media account. Your password should be unique (meaning that it is
              different from those you use for other websites) and must comply
              with the technical requirements of the OnlyGamers site for the
              composition of passwords. To register as a User:
              <ol>
                <li className={styles.terms_content_list}>
                  You must be at least 13 years old, and you will be required to
                  confirm this;
                </li>
                <li className={styles.terms_content_list}>
                  If the laws of the country or State/province where you live
                  provide that you can only be legally bound by a contract with
                  us at an age which is higher than 18 years old, then you must
                  be old enough to be legally bound by a contract with us under
                  the laws of the country or State/province where you live;
                </li>
                <li className={styles.terms_content_list}>
                  You must be permitted by the laws of the country or
                  State/province where you are located to join OnlyGamers and to
                  view any Content available on it and to use any functionality
                  provided by it;
                </li>
                <li className={styles.terms_content_list}>
                  You must provide such other information or verification
                  records as we require.
                </li>
                If you do not meet the above requirements, you must not access
                or use OnlyGamers.
              </ol>
            </li>
            <li className={styles.terms_content_list}>
              <strong className={styles.terms_content_list_bold}>
                Your commitments to us:
              </strong>
              When you register with and use OnlyGamers, you make the following
              commitments to us:
              <ol>
                <li className={styles.terms_content_list}>
                  If you previously had an account with OnlyGamers, you confirm
                  that your old account was not terminated or suspended by us
                  because you violated any of our terms or policies.
                </li>
                <li className={styles.terms_content_list}>
                  You will make sure that all information which you submit to us
                  is truthful, accurate, and complete.
                </li>
                <li className={styles.terms_content_list}>
                  You will update promptly any of your information you have
                  submitted to us as and when it changes.
                </li>
                <li className={styles.terms_content_list}>
                  Adapting these commitments for OnlyGamers, you might consider
                  the following version: Your commitments to us: When you
                  register with and use OnlyGamers, you make the following
                  commitments to us: If you previously had an account with
                  OnlyGamers, you confirm that your old account was not
                  terminated or suspended by us because you violated any of our
                  terms or policies. You will make sure that all information
                  which you submit to us is truthful, accurate, and complete.
                  You will update promptly any of your information you have
                  submitted to us as and when it changes. You consent to
                  receiving communications from us electronically, including by
                  emails and messages posted to your OnlyGamers account, and to
                  the processing of your personal data as more fully detailed in
                  our Privacy Policy.
                </li>
                <li className={styles.terms_content_list}>
                  You will keep your account/login details confidential and
                  secure, including your user details, passwords, and any other
                  piece of information that forms part of our security
                  procedures, and you will not disclose these to anyone else.
                  You will contact contact@only-gamers.com promptly if you
                  believe someone has used or is using your account without your
                  permission or if your account has been subject to any other
                  breach of security. You also agree to ensure that you log out
                  of your account at the end of each session, and to be
                  particularly careful when accessing your account from a public
                  or shared computer so that others are not able to access,
                  view, or record your password or other personal information.
                </li>
                <li className={styles.terms_content_list}>
                  You are responsible for all activity on your account even if,
                  contrary to the Terms of Service, someone else uses your
                  account.
                </li>
                <li className={styles.terms_content_list}>
                  You will comply in full with these Terms of Use for all Users,
                  our Acceptable Use Policy, and all other parts of the Terms of
                  Service which apply to your use of OnlyGamers.
                </li>
              </ol>
            </li>
            <li className={styles.terms_content_list}>
              <strong className={styles.terms_content_list_bold}>
                We may make changes to or suspend or withdraw OnlyGamers:
              </strong>
              We may update and change OnlyGamers from time to time for any
              reason, including to reflect changes to our services, Users'
              needs, and our business practices, or to improve performance,
              enhance functionality, or address security issues. We will try to
              give you reasonable notice of any major changes. We do not
              guarantee that OnlyGamers, or any Content on it, will always be
              available or be accessible without interruption. We may suspend or
              withdraw or restrict the availability of all or any part of
              OnlyGamers for business or operational reasons. We will try to
              give you reasonable notice of any suspension or withdrawal if it
              affects you.
            </li>
            <li className={styles.terms_content_list}>
              <strong className={styles.terms_content_list_bold}>
                Rights we have, including to suspend or terminate your account:
              </strong>

              <ol>
                <li className={styles.terms_content_list}>
                  We can, but we are not obligated to, moderate or review any of
                  your Content to verify compliance with the Terms of Service
                  (including, in particular, our Acceptable Use Policy) and/or
                  any applicable law.
                </li>
                <li className={styles.terms_content_list}>
                  It is our policy to suspend access to any Content you post on
                  OnlyGamers which we become aware may not comply with the Terms
                  of Service (including, in particular, our Acceptable Use
                  Policy) and/or any applicable law while we investigate the
                  suspected non-compliance or unlawfulness of such Content. If
                  we suspend access to any of your Content, you may request a
                  review of our decision to suspend access to the relevant
                  Content by contacting us at contact@only-gamers.com. Following
                  our investigation of the suspected non-compliance or
                  unlawfulness of the relevant Content, we may take any action
                  we consider appropriate, including reinstating access to the
                  Content or permanently removing or disabling access to the
                  relevant Content without needing to obtain any consent from
                  you and without giving you prior notice. You agree that you
                  will, at your own cost, promptly provide us with all
                  reasonable assistance (including by providing us with copies
                  of any information which we request) in our investigation. We
                  will not be responsible for any loss suffered by you arising
                  from the suspension of access to your Content or any other
                  steps which we take in good faith to investigate any suspected
                  non-compliance or unlawfulness of your Content under this
                  section.
                </li>
                <li className={styles.terms_content_list}>
                  If we suspend access to or delete any of your Content, we will
                  notify you via email or electronic message to your OnlyGamers
                  account, but we are not obligated to give you prior notice of
                  such removal or suspension.
                </li>
                <li className={styles.terms_content_list}>
                  We reserve the right in our sole discretion to terminate your
                  agreement with us and your access to OnlyGamers for any reason
                  by giving you 30 days’ notice by email or electronic message
                  to your OnlyGamers account. We can also suspend access to your
                  User account or terminate your agreement with us and your
                  access to OnlyGamers immediately and without prior notice:
                  <ol>
                    <li className={styles.terms_content_list}>
                      if we think that you have or may have seriously or
                      repeatedly breached any part of the Terms of Service
                      (including, in particular, our Acceptable Use Policy), or
                      if you attempt or threaten to breach any part of the Terms
                      of Service in a way that has or could have serious
                      consequences for us or another User; or
                    </li>
                    <li className={styles.terms_content_list}>
                      if you take any action that, in our opinion, has caused or
                      is reasonably likely to cause us to suffer a loss or that
                      otherwise harms the reputation of OnlyGamers.
                    </li>
                    If you do not meet the above requirements, you must not
                    access or use OnlyGamers.
                  </ol>
                </li>
                If we suspend access to your User account or terminate your
                agreement with us and your access to OnlyGamers, we will let you
                know. During any period when access to your User account is
                suspended, any transactions or subscriptions which would
                otherwise have fallen due during the period of suspension will
                be suspended, and we may withhold all or any part of the
                earnings due to you but not yet paid out.
                <li className={styles.terms_content_list}>
                  Upon termination of your account, we may deal with your
                  Content in any appropriate manner in accordance with our
                  Privacy Policy (including by deleting it), and you will no
                  longer be entitled to access your Content. There is no
                  technical facility on OnlyGamers for you to be able to access
                  your Content following termination of your account.
                </li>
                <li className={styles.terms_content_list}>
                  We can investigate any suspected or alleged misuse, abuse, or
                  unlawful use of OnlyGamers and cooperate with law enforcement
                  agencies in such investigation.
                </li>
                <li className={styles.terms_content_list}>
                  We can disclose any information or records in our possession
                  or control about your use of OnlyGamers to law enforcement
                  agencies in connection with any law enforcement investigation
                  of any suspected or alleged illegal activity, to protect our
                  rights or legal interests, or in response to legal process.
                </li>
                <li className={styles.terms_content_list}>
                  We can change the third-party payment providers used to
                  process payments on OnlyGamers and if we do so, we will notify
                  you and store applicable details on your OnlyGamers account.
                </li>
                <li className={styles.terms_content_list}>
                  Other than Content (which is owned by or licensed to
                  Creators), all rights in and to OnlyGamers and its entire
                  contents, features, databases, source code, and functionality,
                  are owned by us and/or our licensors. Such material is
                  protected by copyright, and may be protected by trademark,
                  trade secret, and other intellectual property laws.
                </li>
                <li className={styles.terms_content_list}>
                  We are the sole and exclusive owners of any and all anonymised
                  data relating to your use of OnlyGamers and such anonymised
                  data can be used by us for any purpose, including for
                  commercial, development, and research purposes.
                </li>
              </ol>
            </li>
            <li className={styles.terms_content_list}>
              <strong className={styles.terms_content_list_bold}>
                What we are not responsible for:
              </strong>
              We will use reasonable care and skill in providing OnlyGamers to
              you, but there are certain things for which we are not
              responsible, as follows:
              <ol>
                <li className={styles.terms_content_list}>
                  We do not authorize or approve Content on OnlyGamers, and
                  views expressed by Creators or Gamers on OnlyGamers do not
                  necessarily represent our views.
                </li>
                <li className={styles.terms_content_list}>
                  We do not grant you any rights in relation to Content. Any
                  such rights may only be granted to you by Creators.
                </li>
                <li className={styles.terms_content_list}>
                  Your Content may be viewed by individuals that recognize your
                  identity. We will not in any way be responsible to you if you
                  are identified from your Content. While we may, from time to
                  time and in our sole discretion, offer certain geofencing or
                  geolocation technology on OnlyGamers, you understand and agree
                  that we do not guarantee the accuracy or effectiveness of such
                  technology, and you will have no claim against us arising from
                  your use of or reliance upon any geofencing or geolocation
                  technology on OnlyGamers.
                </li>
                <li className={styles.terms_content_list}>
                  All Content is created, selected, and provided by Users and
                  not by us. We are not responsible for reviewing or moderating
                  Content, and we do not select or modify the Content that is
                  stored or transmitted via OnlyGamers. We are under no
                  obligation to monitor Content or to detect breaches of the
                  Terms of Service (including the Acceptable Use Policy).
                </li>
                <li className={styles.terms_content_list}>
                  You agree that you have no obligation to follow any
                  suggestions, comments, reviews, or instructions received from
                  another User of OnlyGamers and that if you choose to do so,
                  you do so entirely at your own risk.
                </li>
                <li className={styles.terms_content_list}>
                  We make no promises or guarantees of any kind that Creators or
                  Referring Users will make a particular sum of money (or any
                  money) from their use of OnlyGamers.
                </li>
                <li className={styles.terms_content_list}>
                  The materials which we make accessible on OnlyGamers for Users
                  are for general information only. We make no promises or
                  guarantees about the accuracy or otherwise of such materials,
                  or that Users will achieve any particular result or outcome
                  from using such materials.
                </li>
                <li className={styles.terms_content_list}>
                  We do not promise that OnlyGamers is compatible with all
                  devices and operating systems. You are responsible for
                  configuring your information technology, device, and computer
                  programs to access OnlyGamers. You should use your own virus
                  protection software.
                </li>
                <li className={styles.terms_content_list}>
                  We are not responsible for the availability of the internet,
                  or any errors in your connections, device or other equipment,
                  or software that may occur in relation to your use of
                  OnlyGamers.
                </li>
                <li className={styles.terms_content_list}>
                  While we try to make sure that OnlyGamers is secure and free
                  from bugs and viruses, we cannot promise that it will be and
                  have no control over the Content that is supplied by Creators.
                </li>
                <li className={styles.terms_content_list}>
                  We are not responsible for any lost, stolen, or compromised
                  User accounts, passwords, email accounts, or any resulting
                  unauthorized activities or resulting unauthorized payments or
                  withdrawals of funds.
                </li>
                <li className={styles.terms_content_list}>
                  You acknowledge that once your Content is posted on
                  OnlyGamers, we cannot control and will not be responsible to
                  you for the use which other Users or third parties make of
                  such Content. You can delete your account at any time, but you
                  acknowledge that deleting your account will not of itself
                  prevent the circulation of any of your Content which may have
                  been recorded by other Users in breach of the Terms of Service
                  or by third parties prior to the deletion of your account.
                </li>
              </ol>
            </li>
            <li className={styles.terms_content_list}>
              <strong className={styles.terms_content_list_bold}>
                Intellectual property rights – ownership and licenses:
              </strong>
              <ol>
                <li className={styles.terms_content_list}>
                  You confirm that you own all intellectual property rights
                  (examples of which are copyright and trademarks) in your
                  Content or that you have obtained all necessary rights to your
                  Content which are required to grant licenses in respect of
                  your Content to us and to other Users. This includes any
                  rights required to engage in the acts covered by sub-section
                  10(b) below in any territory in which OnlyGamers is accessible
                  and, in particular, in the United States of America, the
                  United Kingdom and the European Union.
                </li>
                <li className={styles.terms_content_list}>
                  You agree to grant us a license under all your Content to
                  perform any act restricted by any intellectual property right
                  (including copyright) in such Content, for any purpose
                  reasonably related to the provision and operation of
                  OnlyGamers. Such acts include to reproduce, make available and
                  communicate to the public, display, perform, distribute,
                  translate, and create adaptations or derivative works of your
                  Content, and otherwise deal in your Content.
                </li>
                <li className={styles.terms_content_list}>
                  The license which you grant to us under sub-section 10(b)
                  above is perpetual, non-exclusive, worldwide, royalty-free,
                  sublicensable, assignable and transferable by us. This means
                  that the license will continue even after your agreement with
                  us ends and you stop using OnlyGamers, that we do not have to
                  pay you for the license, and that we can grant a sub-license
                  of your Content to someone else or assign or transfer the
                  license to someone else. This license will allow us, for
                  example, to add stickers, text, and watermarks to your
                  Content, to make your Content available to other Users of
                  OnlyGamers, as well as to use your Content for other normal
                  operations of OnlyGamers. We will never sell your Content to
                  other platforms, though we may sell or transfer any license
                  you grant to us in the Terms of Service in the event of a sale
                  of our company or its assets to a third party.
                </li>
                <li className={styles.terms_content_list}>
                  Whilst we do not own your Content, you grant us the limited
                  right to submit notifications of infringement (including of
                  copyright or trademark) on your behalf to any third-party
                  website or service that hosts or is otherwise dealing in
                  infringing copies of your Content without your permission.
                  Although we are not under any obligation to do so, we may at
                  any time submit or withdraw any such notification to any
                  third-party website or service where we consider it
                  appropriate to do so. However, we do not and are under no
                  obligation to police infringements of your Content. You agree
                  that if we request, you will provide us with all consents and
                  other information which we reasonably need to submit
                  notifications of infringement on your behalf. Please see our
                  Complaints Policy for how to make a complaint about
                  infringement of intellectual property rights.
                </li>
                <li className={styles.terms_content_list}>
                  You waive any moral rights which you may have under any
                  applicable law to object to derogatory treatment of any
                  Content posted by you on OnlyGamers. This waiver does not
                  affect in any way your ownership of any intellectual property
                  rights in your Content or the rights which you have to prevent
                  your Content from being copied without your permission. The
                  waiver is intended to allow us when dealing with your Content
                  (as permitted by the license which you give us in section
                  10(b) above) to add watermarks, stickers or text to your
                  Content.
                </li>
              </ol>
            </li>
            <li className={styles.terms_content_list}>
              <strong className={styles.terms_content_list_bold}>
                Social Media Sharing:
              </strong>
              Users have the facility to connect an active Twitter or Twitch
              account to their OnlyGamers account and to share certain Content
              in the form of OnlyGamers posts to Twitter or Twitch using the
              share feature. If you use this feature, you must fully comply with
              Twitter's and Twitch's terms of service, respectively, from time
              to time in respect of any Content shared in this way.
            </li>
            <li className={styles.terms_content_list}>
              <strong className={styles.terms_content_list_bold}>
                Linking to and from OnlyGamers:
              </strong>
              <ol>
                <li className={styles.terms_content_list}>
                  Links to OnlyGamers:
                  <ol>
                    <li className={styles.terms_content_list}>
                      You may link to the OnlyGamers homepage, provided you do
                      so in a way that is fair and legal and does not damage our
                      reputation or take advantage of it, but you must not
                      establish a link in such a way as to suggest any form of
                      association, approval, or endorsement on our part without
                      our explicit permission.
                    </li>
                    <li className={styles.terms_content_list}>
                      2If you are a Creator, when promoting your Creator
                      account, you must comply with our Terms of Service and the
                      terms of service of any other website where you place a
                      link to or otherwise promote your Creator account. When
                      promoting your Creator account, you must not impersonate
                      OnlyGamers or give the impression that your Creator
                      account is being promoted by us if this is not the case.
                      You must not promote your OnlyGamers account by using
                      Google Ads or any similar advertising platform or search
                      engine advertising service.
                    </li>
                  </ol>
                </li>
                <li className={styles.terms_content_list}>
                  Links from OnlyGamers: If OnlyGamers contains links to other
                  sites and resources provided by third parties, these links are
                  provided for your convenience only. Such links should not be
                  interpreted as approval by us of those linked websites or
                  information you may obtain from them. We have no control over
                  the contents of those sites or resources and accept no
                  responsibility for them or for any loss or damage that may
                  arise from your use of them. If you decide to access any of
                  the third-party websites linked to OnlyGamers, you do so
                  entirely at your own risk and subject to the terms and
                  conditions of use for such websites.
                </li>
                <li className={styles.terms_content_list}>
                  Domain Names: In some instances, OnlyGamers may allow Creators
                  to register or use domain names that contain the OnlyGamers
                  trademark or a confusingly similar term. However, you will not
                  register such a domain name, unless:
                </li>
                <ol>
                  <li className={styles.terms_content_list}>
                    The domain name is registered by the Creator.
                  </li>
                  <li className={styles.terms_content_list}>
                    The domain name redirects to the Creator’s OnlyGamers
                    profile. Domain names containing the OnlyGamers trademark or
                    a confusingly similar term must not direct to any other
                    website, including link aggregators.
                  </li>
                  <li className={styles.terms_content_list}>
                    The Creator obtains prior written permission from OnlyGamers
                    and signs a licensing agreement.
                  </li>
                </ol>
              </ol>
            </li>
            <li className={styles.terms_content_list}>
              <strong className={styles.terms_content_list_bold}>
                How do I delete my account?
              </strong>
              If you want to delete your OnlyGamers account, you may do so in
              the 'User Account' section of your OnlyGamers account.
              <ol>
                <li className={styles.terms_content_list}>
                  If you are a User, the deletion of your account will take
                  place within a reasonable time following your request.
                </li>
                <li className={styles.terms_content_list}>
                  If you are a Creator, then once you initiate the "delete
                  account" process, your account will remain open until the last
                  day of your Gamers' paid Subscription period, following which
                  you will receive your final payment and your account will be
                  deleted.
                </li>
                <li className={styles.terms_content_list}>
                  Once your account has been deleted, you won't be charged any
                  further amounts or have access to your former OnlyGamers
                  account or its Content, and any subscriptions will be deleted
                  and cannot be subsequently renewed. You will receive an email
                  confirmation upon the successful deletion of your account.
                  Once your account has been deleted, we may deal with your
                  Content in any appropriate manner in accordance with our
                  Privacy Policy (including by deleting it), and you will no
                  longer be entitled to access your Content. There is no
                  technical facility on OnlyGamers for you to be able to access
                  your Content following termination of your account.
                </li>
              </ol>
            </li>

            <li className={styles.terms_content_list}>
              <strong className={styles.terms_content_list_bold}>
                Who is responsible for any loss or damage suffered by you?
              </strong>
              <ol>
                <li className={styles.terms_content_list}>
                  Whether you are a consumer or business User: We do not exclude
                  or limit in any way our liability to you where it would be
                  unlawful to do so. This includes (i) liability for death or
                  personal injury caused by our negligence or the negligence of
                  our employees, agents, or subcontractors, and (ii) fraud or
                  fraudulent misrepresentation.
                </li>
                <li className={styles.terms_content_list}>
                  If you are a consumer User: If you are a consumer User, you
                  agree that:
                  <ol>
                    <li className={styles.terms_content_list}>
                      We and our subsidiary companies, employees, owners,
                      representatives, and agents will not be liable to you for
                      any loss of profit, loss of business or revenue, business
                      interruption, loss of business opportunity, or loss of
                      anticipated savings suffered by you arising from or in
                      connection with your use of OnlyGamers.
                    </li>
                    <li className={styles.terms_content_list}>
                      If you are a consumer User and reside in the United States
                      of America, our total liability to you for claims arising
                      out of or related to your agreement with us shall be
                      limited to USD 250 per claim.
                    </li>
                  </ol>
                </li>
                <li className={styles.terms_content_list}>
                  If you are a business User: If you are a business User, you
                  agree that:
                  <ol>
                    <li className={styles.terms_content_list}>
                      We and our subsidiary companies, employees, owners,
                      representatives, and agents:
                      <ol>
                        <li className={styles.terms_content_list}>
                          exclude (to the extent permitted by law) all implied
                          conditions, warranties, representations, or other
                          terms that may apply to OnlyGamers or any content on
                          it. This means that if the Terms of Service do not
                          expressly include a promise or commitment by us, then
                          one cannot be implied by law;
                        </li>
                        <li className={styles.terms_content_list}>
                          are not responsible to you for any loss or damage
                          suffered by you that is not a foreseeable result of
                          our breaching the Terms of Service or our failing to
                          use reasonable care and skill. Loss or damage is
                          foreseeable if either it is obvious that it will
                          happen or if, at the time you agreed to the Terms of
                          Service, both we and you knew it might happen;
                        </li>
                        <li className={styles.terms_content_list}>
                          won't be liable to you for any loss or damage, whether
                          in contract, tort (including negligence), breach of
                          statutory duty, or otherwise, even if foreseeable,
                          arising out of or in connection with:
                          <ol>
                            <li className={styles.terms_content_list}>
                              your inability to use OnlyGamers or any of its
                              services, features, or programs; or
                            </li>
                            <li className={styles.terms_content_list}>
                              your use of or reliance on any content (including
                              Content) stored on OnlyGamers;
                            </li>
                          </ol>
                        </li>
                        <li className={styles.terms_content_list}>
                          won't be liable to you for any:
                          <ol>
                            <li className={styles.terms_content_list}>
                              loss of profits;
                            </li>
                            <li className={styles.terms_content_list}>
                              loss of sales, business, or revenue;
                            </li>
                            <li className={styles.terms_content_list}>
                              business interruption;
                            </li>
                            <li className={styles.terms_content_list}>
                              loss of anticipated savings;
                            </li>
                            <li className={styles.terms_content_list}>
                              loss of business opportunity, goodwill, or
                              reputation;
                            </li>
                            <li className={styles.terms_content_list}>
                              loss of data or information, including any
                              Content; or
                            </li>
                            <li className={styles.terms_content_list}>
                              indirect or consequential loss or damage;
                            </li>
                          </ol>
                        </li>
                        <li className={styles.terms_content_list}>
                          won't be liable to you for any loss or damage caused
                          by a distributed denial-of-service attack, virus,
                          malware, ransomware, or other technologically harmful
                          material that may infect your computer equipment,
                          computer programs, data, or other proprietary material
                          due to your use of OnlyGamers or any of its services,
                          features, or programs, or due to your downloading of
                          any material posted on it, or on any website linked to
                          it;
                        </li>
                        <li className={styles.terms_content_list}>
                          won't be liable to you if your Content is copied,
                          distributed, reposted elsewhere or its copyright is
                          infringed by another User or any third party;
                        </li>
                        <li className={styles.terms_content_list}>
                          won't be liable to you for any disclosure of your
                          identity, or any disclosure or publication of your
                          personal information by other Users or third parties
                          without your consent (also known as "doxing");
                        </li>
                        <li className={styles.terms_content_list}>
                          won't be liable to you for any failure or delay by us
                          in complying with any part of the Terms of Service
                          arising from events outside our reasonable control. If
                          there is any failure or delay by us in complying with
                          any part of the Terms of Service arising from an event
                          outside our reasonable control, then we will contact
                          you as soon as possible to let you know, and we will
                          take steps to minimise the effect of the delay.
                        </li>
                      </ol>
                    </li>
                    <li className={styles.terms_content_list}>
                      Our total liability to you for any and all claims arising
                      out of or related to your agreement with us, whether in
                      contract, tort (including negligence), breach of statutory
                      duty, or otherwise, shall be limited to the greater of:2
                      <ol>
                        <li className={styles.terms_content_list}>
                          100% of the total fees paid by you to us in connection
                          with your use of OnlyGamers; and
                        </li>
                        <li className={styles.terms_content_list}>
                          USD 5,000.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li className={styles.terms_content_list}>
              <strong className={styles.terms_content_list_bold}>
                General: You agree that:
              </strong>
              <ol>
                <li className={styles.terms_content_list}>
                  If any aspect of your agreement with us is unenforceable, the
                  rest will remain in effect.
                </li>
                <li className={styles.terms_content_list}>
                  If we fail to enforce any aspect of your agreement with us, it
                  will not be a waiver;
                </li>
                <li className={styles.terms_content_list}>
                  We reserve all rights not expressly granted to you.
                </li>
                <li className={styles.terms_content_list}>
                  No implied licenses or other rights are granted to you in
                  relation to any part of OnlyGamers, save as expressly set out
                  in the Terms of Service.
                </li>
                <li className={styles.terms_content_list}>
                  Your agreement with us does not give rights to any third
                  parties, except that the exclusions and limitations of
                  liability in section relating to "Who is responsible for any
                  loss or damage suffered by you?" and the terms relating to
                  disputes may be enforced by our subsidiary companies,
                  employees, owners, representatives, and agents.
                </li>
                <li className={styles.terms_content_list}>
                  You cannot transfer your rights or obligations under your
                  agreement with us without our prior written consent.
                </li>
                <li className={styles.terms_content_list}>
                  Our rights and obligations under your agreement with us can be
                  assigned or transferred by us to others. For example, this
                  could occur if our ownership changes (as in a merger,
                  acquisition, or sale of assets) or by law. In addition, we may
                  choose to delegate the performance of any of our obligations
                  under your agreement with us to any third party, but we will
                  remain responsible to you for the performance of such
                  obligations.
                </li>
                <li className={styles.terms_content_list}>
                  The Terms of Service form the entire agreement between us and
                  you regarding your access to and use of OnlyGamers, and
                  supersede any and all prior oral or written understandings or
                  agreements between us and you.
                </li>
              </ol>
            </li>

            <li className={styles.terms_content_list}>
              <strong className={styles.terms_content_list_bold}>
                Terms relating to disputes – the law which applies to your
                agreement with us and where disputes and claims concerning your
                use of OnlyGamers (including those arising from or relating to
                your agreement with us) can be brought:
              </strong>
              <ol>
                <li className={styles.terms_content_list}>
                  For consumers (Gamers):
                  <ol>
                    <li className={styles.terms_content_list}>
                      Consumers - Law:
                      <ol>
                        <li className={styles.terms_content_list}>
                          If you are a consumer, your agreement with us is
                          governed by English law and English law will apply to
                          (i) any claim that you have arising out of or in
                          connection with your agreement with us or your use of
                          OnlyGamers, and (ii) any claim that we have against
                          you that arises out of or in connection with your
                          agreement with us or your use of OnlyGamers
                          (including, in both cases, non-contractual disputes or
                          claims). You will also be able to rely on mandatory
                          rules of the law of the country where you live.
                        </li>
                      </ol>
                    </li>
                    <li className={styles.terms_content_list}>
                      Consumers - where claims must be brought:
                      <ol>
                        <li className={styles.terms_content_list}>
                          If you are a consumer resident in the United Kingdom
                          or the European Union, any claim which you have or
                          which we have arising out of or in connection with
                          your agreement with us or your use of OnlyGamers
                          (including, in both cases, non-contractual disputes or
                          claims) may be brought in the courts of England and
                          Wales or the courts of the country where you live.
                        </li>
                        <li className={styles.terms_content_list}>
                          If you are a consumer resident outside of the United
                          Kingdom or the European Union, any claim which you
                          have or which we have arising out of or in connection
                          with your agreement with us or your use of OnlyGamers
                          (including, in both cases, non-contractual disputes or
                          claims) must be brought in the courts of England and
                          Wales.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li className={styles.terms_content_list}>
                  For business Users (Creators and Referring Users):
                  <ol>
                    <li className={styles.terms_content_list}>
                      Business Users – Law:
                      <ol>
                        <li className={styles.terms_content_list}>
                          If you are a business User, your agreement with us is
                          governed by English law and English law will apply to
                          (i) any claim that you have arising out of or in
                          connection with your agreement with us or your use of
                          OnlyGamers, and (ii) any claim that we have arising
                          out of or in connection with your agreement with us or
                          your use of OnlyGamers (including, in both cases
                          non-contractual disputes or claims), without regard to
                          conflict of law provisions.
                        </li>
                      </ol>
                    </li>
                    <li className={styles.terms_content_list}>
                      Business Users - where claims must be brought:
                      <ol>
                        <li className={styles.terms_content_list}>
                          If you are a business User, you and we agree that the
                          courts of England and Wales shall have exclusive
                          jurisdiction to resolve any dispute or claim
                          (including non-contractual disputes or claims) which
                          you have or which we have arising out of or in
                          connection with your agreement with us (including its
                          subject matter or formation) or your use of
                          OnlyGamers.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li className={styles.terms_content_list}>
                  Limitation period for bringing claims: Except where prohibited
                  by applicable law, any claim or cause of action which you have
                  concerning OnlyGamers (including those arising out of or
                  related to your agreement with us) must be filed within one
                  year after the date on which such claim or cause of action
                  arose or the date on which you learned of the facts giving
                  rise to the cause of action (whichever is the earlier), or be
                  forever barred.
                </li>
              </ol>
            </li>

            <li className={styles.terms_content_list}>
              <strong className={styles.terms_content_list_bold}>
                Other terms which form part of your agreement with us:
              </strong>
              These Terms of Use for all Users govern your agreement with us.
              Certain other terms or policies forming part of the Terms of
              Service will also apply to you and form part of your agreement
              with us, as follows:
              <ol>
                <li className={styles.terms_content_list}>
                  Terms of Use for Gamers – which contain additional terms that
                  apply if you use OnlyGamers as a Gamer;
                </li>
                <li className={styles.terms_content_list}>
                  Terms of Use for Creators – which contain additional terms
                  that apply if you use OnlyGamers as a Creator;
                </li>
                <li className={styles.terms_content_list}>
                  Privacy Policy – which applies to all Users and tells you how
                  we use your personal data and other information we collect
                  about you;
                </li>
                <li className={styles.terms_content_list}>
                  Acceptable Use Policy – which applies to all Users and tells
                  you what you can and can’t do on OnlyGamers;
                </li>
                <li className={styles.terms_content_list}>
                  Referral Program Terms – which outline the terms that apply if
                  you participate in the OnlyGamers Referral Program;
                </li>
                <li className={styles.terms_content_list}>
                  Complaints Policy - which sets out the procedure for making a
                  complaint about any aspect of OnlyGamers, and how we will deal
                  with that complaint;
                </li>
                <li className={styles.terms_content_list}>
                  Platform to Business Regulation Terms – which contain
                  additional terms that apply to Creators who are established or
                  resident in the European Union or the United Kingdom; and
                </li>
                <li className={styles.terms_content_list}>
                  Our Community Guidelines – which provide additional terms and
                  guidance regarding your interactions with OnlyGamers.
                </li>
              </ol>
              If there is any conflict between these Terms of Use for all Users
              and any of the terms or policies listed above, the Terms of Use
              for all Users will apply to the extent of the conflict.
            </li>
          </ol>
          Last updated: March 2024
          <h2 className={styles.terms_heading_content_title}>
            TERMS OF USE FOR FANS
          </h2>
          <h3>
            BY USING OUR WEBSITE AS A FAN YOU AGREE TO THESE TERMS – PLEASE READ
            THEM CAREFULLY
          </h3>
          <ol>
            <li className={styles.terms_content_list}>
              <strong className={styles.terms_content_list_bold}>
                Introduction:
              </strong>
              These Terms of Use for Gamers are additional terms that apply if
              you use OnlyGamers as a Gamer (also referred to as "you" and
              "your" in these Terms of Use for Gamers). These Terms of Use for
              Gamers form part of your agreement with us.
            </li>
            <li className={styles.terms_content_list}>
              <strong className={styles.terms_content_list_bold}>
                Interpretation:
              </strong>
              In these Terms of Use for Gamers, defined terms have the same
              meanings given to them in the Terms of Use for all Users. In
              addition:
              <ol>
                <li className={styles.terms_content_list}>
                  "VAT" means United Kingdom value added tax and any other tax
                  imposed in addition to or in substitution for it at the rate
                  from time to time imposed and any equivalent or similar
                  governmental, state, federal, provincial, or municipal
                  indirect tax, charge, duty, impost, or levy in any other
                  jurisdiction; and
                </li>
                <li className={styles.terms_content_list}>
                  "Tax" shall include all forms of tax and statutory,
                  governmental, state, federal, provincial, local government or
                  municipal charges, duties, imposts, contributions, levies,
                  withholdings, or liabilities wherever chargeable and whether
                  of the UK or any other jurisdiction.
                </li>
              </ol>
            </li>
            <li className={styles.terms_content_list}>
              <strong className={styles.terms_content_list_bold}>
                Other terms which will apply to your use of OnlyGamers:
              </strong>
              The following terms will also apply to your use of OnlyGamers and
              you agree to them:
              <ol>
                <li className={styles.terms_content_list}>
                  Our Terms of Use for all Users;
                </li>
                <li className={styles.terms_content_list}>
                  Our Privacy Policy – which tells you how we use your personal
                  data and other information we collect about you;
                </li>
                <li className={styles.terms_content_list}>
                  Our Acceptable Use Policy – which tells you what you can and
                  can't do on OnlyGamers;
                </li>
                <li className={styles.terms_content_list}>
                  Our Complaints Policy – which sets out the procedure for
                  making a complaint about any aspect of OnlyGamers, and how we
                  will deal with that complaint;
                </li>
                <li className={styles.terms_content_list}>
                  The Standard Contract between Gamer and Creator – which does
                  not form part of your agreement with us, but which governs and
                  sets out the terms applicable to each Gamer/Creator
                  Transaction you enter into on OnlyGamers; and
                </li>
                <li className={styles.terms_content_list}>
                  Our Community Guidelines – which provide additional terms and
                  guidance regarding your interactions with OnlyGamers.
                </li>
              </ol>
            </li>
            <li className={styles.terms_content_list}>
              <strong className={styles.terms_content_list_bold}>
                Other terms which will apply to your use of OnlyGamers:
              </strong>
              The following additional terms may apply to your use of
              OnlyGamers:
              <ol>
                <li className={styles.terms_content_list}>
                  If you are also a Creator, the Terms of Use for Creators will
                  apply to your use of OnlyGamers as a Creator;
                </li>
                <li className={styles.terms_content_list}>
                  If you are a Creator who is established or resident in the
                  European Union or the United Kingdom, then the Platform to
                  Business Regulation Terms will also apply to you; and
                </li>
                <li className={styles.terms_content_list}>
                  If you participate in the OnlyGamers referral program, the
                  Referral Program Terms will apply to your use of the
                  OnlyGamers Referral Program.
                </li>
              </ol>
            </li>
            <li className={styles.terms_content_list}>
              <strong className={styles.terms_content_list_bold}>
                Your commitments to us:
              </strong>
              You warrant (which means you make a legally enforceable promise)
              that:
              <ol>
                <li className={styles.terms_content_list}>
                  You are at least 13 years old;
                </li>
                <li className={styles.terms_content_list}>
                  If the laws of the country or State/province where you live
                  provide that you can only be legally bound by a contract at an
                  age which is higher than 18 years old, then you are old enough
                  to be legally bound by a contract under the laws of the
                  country or State/province where you live;
                </li>
                <li className={styles.terms_content_list}>
                  You will provide such other information or verification
                  records as we require.
                </li>
                <li className={styles.terms_content_list}>
                  You are permitted by the laws of the country or State/province
                  where you are located to join OnlyGamers and to view any
                  Content available on it and to use any functionality provided
                  by it; and
                </li>
                <li className={styles.terms_content_list}>
                  You are able and willing to make payment (where required) to
                  view Content available on OnlyGamers which you wish to view
                  and to use any functionality provided by OnlyGamers that you
                  wish to use.
                </li>
              </ol>
            </li>
            <li className={styles.terms_content_list}>
              <strong className={styles.terms_content_list_bold}>
                Content - general terms:
              </strong>
              In addition to the terms set out elsewhere in the Terms of Service
              (in particular in our Acceptable Use Policy), the following terms
              apply to the Content posted, displayed, uploaded, or published by
              you as a Gamer on OnlyGamers:
              <ol>
                <li className={styles.terms_content_list}>
                  You warrant (which means that you make a legally enforceable
                  promise to us) that for each item of Content which you post,
                  display, upload, or publish on OnlyGamers:
                  <ol>
                    <li className={styles.terms_content_list}>
                      The Content complies in full with the Terms of Service
                      (and in particular our Acceptable Use Policy);
                    </li>
                    <li className={styles.terms_content_list}>
                      You either own your Content (and all intellectual property
                      rights in it) or have a valid license to your Content; and
                    </li>
                    <li className={styles.terms_content_list}>
                      If your Content includes or uses any third-party material,
                      you have secured all rights, licenses, written consents,
                      and releases that are necessary for the use of such
                      third-party property in your Content and for the
                      subsequent use and exploitation of that Content on
                      OnlyGamers.
                    </li>
                  </ol>
                </li>
                <li className={styles.terms_content_list}>
                  You agree that you will be liable to us and indemnify us if
                  any of the warranties at section is untrue. This means you
                  will be responsible for any loss or damage we suffer as a
                  result of any warranty being untrue.
                </li>
                <li className={styles.terms_content_list}>
                  We are not responsible for and do not endorse any aspect of
                  any Content posted by you or any other User of OnlyGamers. We
                  do not have any obligation to monitor any Content and have no
                  direct control over what your Content may comprise.
                </li>
              </ol>
            </li>
            <li className={styles.terms_content_list}>
              <strong className={styles.terms_content_list_bold}>
                Adult material:
              </strong>
              You acknowledge that some of the Content on OnlyGamers may be
              aimed at mature audiences, including content that may be violent
              or contain mature themes, and you agree to take this into account
              when deciding where to access and view Content. We will not be
              responsible to you if you suffer any loss or damage as a result of
              your accessing or viewing Content in a way which places you in
              breach of any contract you have with a third party (for example,
              your employment contract) or in breach of any applicable law.
            </li>
            <li className={styles.terms_content_list}>
              <strong className={styles.terms_content_list_bold}>
                Subscriptions and Purchases by Gamers:
              </strong>
              This section describes the terms which apply to Gamer/Creator
              Transactions:
              <ol>
                <li className={styles.terms_content_list}>
                  All Gamer/Creator Transactions are contracts between Gamers
                  and Creators on the terms of the Standard Contract between
                  Gamer and Creator. Although we facilitate Gamer/Creator
                  Transactions by providing the OnlyGamers platform and storing
                  Content, we are not a party to the Standard Contract between
                  Gamer and Creator or any other contract that may exist between
                  a Gamer and Creator, and are not responsible for any
                  Gamer/Creator Transactions.
                </li>
                <li className={styles.terms_content_list}>
                  Creators are solely responsible for determining (within the
                  parameters for pricing on OnlyGamers) the pricing applicable
                  to Gamer/Creator Transactions and the Content to which you may
                  be given access. All prices appear in USD only.
                </li>
                <li className={styles.terms_content_list}>
                  Gamer Payments are exclusive of VAT, which shall be added at
                  the current rate as applicable to Gamer Payments.
                </li>
                <li className={styles.terms_content_list}>
                  To be able to enter into a Gamer/Creator Transaction with a
                  particular Creator, you must first add a payment card to your
                  account and then click the 'Subscribe' button on the relevant
                  Creator's profile.
                </li>
                <li className={styles.terms_content_list}>
                  You authorize us and our subsidiary companies to supply your
                  payment card details to a third-party payment provider for the
                  purpose of processing your Gamer Payment. All Gamer Payments
                  will be charged in USD. Your payment card provider may charge
                  you currency conversion fees. We do not have control over
                  currency exchange rates or charges imposed by your payment
                  card provider or bank and we and our subsidiary companies will
                  not be responsible for paying any charges or fees imposed by
                  your payment card provider or bank.
                </li>
                <li className={styles.terms_content_list}>
                  If you choose to provide details of two or more payment cards,
                  then if you try to make a Gamer Payment from the first card
                  and the card is rejected for any reason, then the other
                  payment card will be used to collect the full Gamer Payment.
                </li>
                <li className={styles.terms_content_list}>
                  The payment provider will take (i) periodic payments from your
                  payment card for Gamer Payments which are Subscriptions; and
                  (ii) immediate payments from your payment card for Gamer
                  Payments other than Subscriptions (including any tips paid by
                  you to a Creator). You authorize and consent to each of these
                  payments being debited using your supplied payment card
                  details.
                </li>
                <li className={styles.terms_content_list}>
                  Apart from free-trial Subscriptions, all Subscriptions to a
                  Creator's profile will automatically renew at the end of the
                  relevant subscription period, except if your payment card is
                  declined, the subscription price for the Subscription has
                  increased, or you have turned off the "Auto-Renew" switch
                  located on the relevant Creator's profile. This means that if
                  you want to stop subscribing to a Creator's profile and paying
                  continuing monthly subscription charges, you will need to turn
                  off the "Auto-Renew" switch located on the relevant Creator's
                  profile.
                </li>
                <li className={styles.terms_content_list}>
                  If you cancel a Subscription, you will continue to be
                  permitted to view the relevant Creator's Content until the end
                  of the subscription period in which you cancelled, after which
                  no further payments will be taken from your payment card in
                  respect of subscriptions to that Creator's profile (unless you
                  choose to pay for a new Subscription to that Creator’s
                  profile), and you will no longer be able to view the relevant
                  Creator's Content.
                </li>
                <li className={styles.terms_content_list}>
                  You agree that you will not make unjustified requests for a
                  refund in respect of any Gamer/Creator Transaction or tip to a
                  Creator, or unjustified chargeback requests of your payment
                  card provider in relation to any Gamer/Creator Transaction or
                  tip to a Creator. If we consider that any request for a refund
                  or chargeback request was made by you in bad faith, we have
                  the right to suspend or delete your User account.
                </li>
                <li className={styles.terms_content_list}>
                  Wallet Credits: You can prepay an amount on OnlyGamers (known
                  as "Wallet Credits") which you can later use to make Gamer
                  Payments. Purchases on OnlyGamers cannot be divided - if you
                  attempt a purchase that costs more than the total amount of
                  your remaining Wallet Credits, your payment card will be
                  charged the full amount for that purchase. Wallet Credits are
                  subject to a maximum amount as determined by us from time to
                  time. Interest will not accrue on Wallet Credits. Wallet
                  Credits are non-refundable, which means that you are not
                  entitled to a refund of any unused Wallet Credits.
                </li>
                <li className={styles.terms_content_list}>
                  Suspension of your User account: If we suspend your User
                  account in accordance with our rights under the Terms of
                  Service, then any Gamer Payments which would otherwise have
                  fallen due during the period of suspension of your User
                  account will be suspended during the period of the suspension
                  of your User account.
                </li>
              </ol>
            </li>
          </ol>
          Last Updated: March 2024
          <h2 className={styles.terms_heading_content_title}>
            TERMS OF USE FOR CREATORS
          </h2>
          <h3>
            BY USING OUR WEBSITE AS A CREATOR YOU AGREE TO THESE TERMS – PLEASE
            READ THEM CAREFULLY
          </h3>
          <li className={styles.terms_content_list}>
            <strong className={styles.terms_content_list_bold}>
              Introduction:
            </strong>
            These Terms of Use for Creators are additional terms that apply if
            you use OnlyGamers as a Creator (also referred to as "you" and
            "your" in these Terms of Use for Creators). These Terms of Use for
            Creators form part of your agreement with us.
          </li>
          <li className={styles.terms_content_list}>
            <strong className={styles.terms_content_list_bold}>
              Interpretation:
            </strong>
            In these Terms of Use for Creators, defined terms have the same
            meanings given to them in the Terms of Use for all Users. In
            addition:
            <ol>
              <li className={styles.terms_content_list}>
                "VAT" means United Kingdom value added tax and any other tax
                imposed in addition to or in substitution for it at the rate
                from time to time imposed and any equivalent or similar
                governmental, state, federal, provincial, or municipal indirect
                tax, charge, duty, impost, or levy in any other jurisdiction;
                and
              </li>
              <li className={styles.terms_content_list}>
                "Tax" shall include all forms of tax and statutory,
                governmental, state, federal, provincial, local government, or
                municipal charges, duties, imposts, contributions, levies,
                withholdings, or liabilities wherever chargeable and whether of
                the UK or any other jurisdiction.
              </li>
            </ol>
          </li>
          <li className={styles.terms_content_list}>
            <strong className={styles.terms_content_list_bold}>
              Other terms which will apply to your use of OnlyGamers:
            </strong>
            The following terms will also apply to your use of OnlyGamers, and
            you agree to them:
            <ol>
              <li className={styles.terms_content_list}>
                Our Terms of Use for all Users;
              </li>
              <li className={styles.terms_content_list}>
                Our Privacy Policy – which tells you how we use your personal
                data and other information we collect about you;
              </li>
              <li className={styles.terms_content_list}>
                Our Acceptable Use Policy – which tells you what you can and
                can’t do on OnlyGamers;
              </li>
              <li className={styles.terms_content_list}>
                Our Complaints Policy – which sets out the procedure for making
                a complaint about any aspect of OnlyGamers, and how we will deal
                with that complaint;
              </li>
              <li className={styles.terms_content_list}>
                The Standard Contract between Gamer and Creator – which does not
                form part of your agreement with us, but which governs and sets
                out the terms applicable to each Gamer/Creator Transaction you
                enter into on OnlyGamers; and
              </li>
              <li className={styles.terms_content_list}>
                Our Community Guidelines – which provide additional terms and
                guidance regarding your interactions with OnlyGamers.
              </li>
            </ol>
          </li>
          <li className={styles.terms_content_list}>
            <strong className={styles.terms_content_list_bold}>
              Other terms which may apply to your use of OnlyGamers:
            </strong>
            The following additional terms may apply to your use of OnlyGamers:
            <ol>
              <li className={styles.terms_content_list}>
                If you are established or resident in the European Union or the
                United Kingdom, then the Platform to Business Regulation Terms
                will also apply to you;
              </li>
              <li className={styles.terms_content_list}>
                If you are also a Gamer, the Terms of Use for Gamers will also
                apply to your use of OnlyGamers as a Gamer; and
              </li>
              <li className={styles.terms_content_list}>
                If you participate in the OnlyGamers Referral Program, the
                Referral Program Terms will apply to your use of the OnlyGamers
                Referral Program.
              </li>
            </ol>
          </li>
          <li className={styles.terms_content_list}>
            <strong className={styles.terms_content_list_bold}>
              What are the fees that we charge Creators for the use of
              OnlyGamers?
            </strong>
            We charge a fee to you of twenty per cent (20%) of all Gamer
            Payments made to you (exclusive of any VAT element of the Gamer
            Payment) (called "Our Fee"). The remaining eighty per cent (80%) of
            the Gamer Payment (exclusive of any VAT element of the Gamer
            Payment) is payable to you (called "Creator Earnings"). Our Fee
            includes the costs of providing, maintaining, and operating
            OnlyGamers and storing your Content. Our Fee is deducted from the
            Gamer Payment, and Creator Earnings are paid to you as described in
            the Payouts to Creators section below.
          </li>
          <li className={styles.terms_content_list}>
            <strong className={styles.terms_content_list_bold}>
              How to set up your account as a Creator account on OnlyGamers:
            </strong>
            <ol>
              <li className={styles.terms_content_list}>
                Connect a Stripe Account: You must have a valid Stripe account.
                Connect your Stripe account to our OnlyGamers Stripe account.
                This connection is essential for processing payments securely
                and efficiently.
              </li>
              <li className={styles.terms_content_list}>
                Agree to the Creator Contract: Once your Stripe account is
                connected, you will be presented with the Creator Contract.
                Please read this contract carefully, as it outlines your rights,
                responsibilities, and the terms of revenue sharing. You must
                agree to this contract to proceed.
              </li>
            </ol>
            By completing these steps, you affirm that you understand and
            consent to the terms under which you'll be operating as a Creator on
            OnlyGamers. This setup ensures a streamlined process for monetizing
            your content and engaging with your audience.
          </li>
          <li className={styles.terms_content_list}>
            <strong className={styles.terms_content_list_bold}>
              Personal Legal Responsibility of Creators:
            </strong>
            Only individuals can be Creators on OnlyGamers. Every Creator is
            bound personally by the Terms of Service. If you have an agent,
            agency, management company, or other third party which assists you
            with the operation of your Creator account (or operates it on your
            behalf), this does not affect your personal legal responsibility.
            Our relationship is with you, and not with any third party, and you
            will be legally responsible for ensuring that all Content posted and
            all use of your account complies with the Terms of Service.
          </li>
          <li className={styles.terms_content_list}>
            <strong className={styles.terms_content_list_bold}>
              Gamer/Creator Transactions:
            </strong>
            This section describes the terms which apply to Gamer/Creator
            Transactions:
            <ol>
              <li className={styles.terms_content_list}>
                All Gamer/Creator Transactions are contracts between Gamers and
                Creators on the terms of the Standard Contract between Gamer and
                Creator. Although we facilitate Gamer/Creator Transactions by
                providing the OnlyGamers platform and storing Content, we are
                not a party to the Standard Contract between Gamer and Creator
                or any other contract that may exist between a Gamer and
                Creator, and are not responsible for any Gamer/Creator
                Transaction.
              </li>
              <li className={styles.terms_content_list}>
                Gamer Payments are exclusive of VAT, which shall be added at the
                current rate as applicable to Gamer Payments.
              </li>
              <li className={styles.terms_content_list}>
                When you receive confirmation from OnlyGamers, either in the
                ‘Statements’ page of your User account or by email (or both),
                that the Gamer/Creator Transaction has been confirmed, you must
                perform your part of such Gamer/Creator Transaction (for
                example, by allowing the Gamer to view the Content on your
                Creator account and/or providing the customized Content paid for
                by the Gamer and/or allowing the Gamer to use the gamer
                interaction function paid for (as applicable)). You agree that
                you will indemnify us for any breach by you of this obligation
                (which means you will be responsible for any loss or damage
                (including loss of profit) we suffer as a result of you failing
                to comply with this obligation).
              </li>
            </ol>
          </li>
          <li className={styles.terms_content_list}>
            <strong className={styles.terms_content_list_bold}>
              Content – General Terms:
            </strong>
            In addition to the terms set out elsewhere in the Terms of Service
            (in particular in our Acceptable Use Policy), the following terms
            apply to the Content posted, displayed, uploaded, or published by
            you as a Creator on OnlyGamers:
            <ol>
              <li className={styles.terms_content_list}>
                Your Content is not confidential, and you authorize your Gamers
                to access and view your Content on OnlyGamers for their own
                lawful and personal use, and in accordance with any licenses
                that you grant to your Gamers.
              </li>
              <li className={styles.terms_content_list}>
                You warrant (which means that you make a legally enforceable
                promise to us) that for each item of Content which you post,
                display, upload, or publish on OnlyGamers:
                <ol>
                  <li className={styles.terms_content_list}>
                    the Content complies in full with the Terms of Service (and
                    in particular our Acceptable Use Policy);
                  </li>
                  <li className={styles.terms_content_list}>
                    you hold all rights necessary to license and deal in your
                    Content on OnlyGamers, including in each territory where you
                    have Gamers;
                  </li>
                  <li className={styles.terms_content_list}>
                    you either own your Content (and all intellectual property
                    rights in it) or have a valid license to offer and supply
                    your Content to your Gamers;
                  </li>
                  <li className={styles.terms_content_list}>
                    if your Content includes or uses any third-party material,
                    you have secured all rights, licenses, written consents, and
                    releases that are necessary for the use of such third-party
                    property in your Content and for the subsequent use and
                    exploitation of that Content on OnlyGamers; and
                  </li>
                  <li className={styles.terms_content_list}>
                    the Content is of satisfactory quality, taking account of
                    any description of the Content, the price, and all other
                    relevant circumstances including any statement or
                    representation which you make about the nature of the
                    Content on your account or in any advertising; reasonably
                    suitable for any purpose which the Gamer has made known to
                    you is the purpose for which the Gamer is using the Content;
                    and as described by you.
                    <ol>
                      <li className={styles.terms_content_list}>1</li>
                      <li className={styles.terms_content_list}>2</li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li className={styles.terms_content_list}>
                You agree that you will be liable to us and indemnify us if any
                of the warranties above is untrue. This means you will be
                responsible for any loss or damage (including loss of profit) we
                suffer as a result of any of the warranties being untrue.
              </li>
              <li className={styles.terms_content_list}>
                We are not responsible for and do not endorse any aspect of any
                Content posted by you or any other User of OnlyGamers. We do not
                have any obligation to monitor any Content and have no direct
                control over what your Content may comprise.
              </li>
              <li className={styles.terms_content_list}>
                You also agree to act as custodian of records for the Content
                that you upload to OnlyGamers.
              </li>
            </ol>
          </li>
          <li className={styles.terms_content_list}>
            <strong className={styles.terms_content_list_bold}>
              Advertising on OnlyGamers:
            </strong>
            <ol>
              <li className={styles.terms_content_list}>
                If you post or upload video Content to your Creator account that
                is designed to promote, directly or indirectly, third-party
                goods, services, or image in return for payment, other valuable
                consideration, or self-promotional purposes (including
                advertising, sponsorship, and product placement) ("Advertising
                Content"), then you must comply with the requirements set out
                below.
              </li>
              <li className={styles.terms_content_list}>
                Requirements – Advertising Content: You must ensure that any
                Advertising Content which you post to your Creator account:
                <ol>
                  <li className={styles.terms_content_list}>
                    does not:
                    <ol>
                      <li className={styles.terms_content_list}>
                        Prejudice respect for human dignity;
                      </li>
                      <li className={styles.terms_content_list}>
                        Include or promote discrimination based on sex, racial
                        or ethnic origin, nationality, religion or belief,
                        disability, age, or sexual orientation;
                      </li>
                      <li className={styles.terms_content_list}>
                        Encourage behavior prejudicial to health or safety;
                      </li>
                      <li className={styles.terms_content_list}>
                        Encourage behavior grossly prejudicial to the protection
                        of the environment;
                      </li>
                      <li className={styles.terms_content_list}>
                        Encourage behavior grossly prejudicial to the protection
                        of the environment;
                      </li>
                      <li className={styles.terms_content_list}>
                        Directly urge persons to purchase or rent goods or
                        services in a manner that exploits their inexperience or
                        credulity;
                      </li>
                      <li className={styles.terms_content_list}>
                        Directly encourage persons to persuade others to
                        purchase or rent goods or services;
                      </li>
                      <li className={styles.terms_content_list}>
                        Exploit the trust of persons in others; or
                      </li>
                      <li className={styles.terms_content_list}>
                        Unreasonably show persons in dangerous situations;
                      </li>
                    </ol>
                  </li>
                  <li className={styles.terms_content_list}>
                    Does not advertise cigarettes and other tobacco products,
                    electronic cigarettes and electronic cigarette refill
                    containers, illegal drugs, or any prescription-only
                    medicine;
                  </li>
                  <li className={styles.terms_content_list}>
                    Does not advertise, promote, or facilitate illegal gambling,
                    and
                  </li>
                  <li className={styles.terms_content_list}>
                    In respect of any Advertising Content for alcoholic drinks,
                    is not aimed at minors and does not encourage immoderate
                    consumption of alcohol.
                  </li>
                </ol>
              </li>

              <li className={styles.terms_content_list}>
                Transparency Requirement - Advertising Content: You must declare
                that any Advertising Content which you post or upload to
                OnlyGamers contains advertising by including the signifier #ad
                in the caption to the Advertising Content before posting or
                uploading.
              </li>
            </ol>
          </li>
          <li className={styles.terms_content_list}>
            <strong className={styles.terms_content_list_bold}>
              Co-authored Content:
            </strong>
            <ol>
              <li className={styles.terms_content_list}>
                If you upload Content to your Creator account that features
                anyone else besides yourself (even if that person cannot be
                identified from the Content) ("Co-Authored Content"), you
                warrant (which means you make a legally enforceable promise to
                us) that each individual shown in any Co-Authored Content
                uploaded to your account is either i) a Creator on OnlyGamers;
                or ii) a consenting adult, and that you have verified the
                identity and age of each such individual and will provide
                supporting documents as we may request in our discretion.
              </li>
              <li className={styles.terms_content_list}>
                You further warrant that you have obtained and keep on record
                written consent from each individual shown in your Co-Authored
                Content that such individual:
                <ol>
                  <li className={styles.terms_content_list}>
                    Has given his or her express, prior, and fully informed
                    consent to his or her appearance in the Co-Authored Content;
                    and
                  </li>
                  <li className={styles.terms_content_list}>
                    Has consented to the Co-Authored Content in which he or she
                    appears being posted on OnlyGamers.
                  </li>
                </ol>
              </li>
              <li className={styles.terms_content_list}>
                In addition to the confirmations above, you agree that if you
                upload Co-Authored Content where the other person or people
                appearing in the Content maintain a Creator account on
                OnlyGamers, you will tag the OnlyGamers account(s) of any person
                or people appearing in the Co-Authored Content who can be
                identified from it.
              </li>
              <li className={styles.terms_content_list}>
                If any Co-Authored Content is a work of joint authorship, you
                are solely responsible for obtaining any required licenses or
                consents from any other joint authors of the Content which are
                sufficient to permit such Content to be uploaded to and made
                available on OnlyGamers.
              </li>
              <li className={styles.terms_content_list}>
                You agree that we will only arrange for Creator Earnings to be
                paid to the account of the Creator to which the Co-Authored
                Content is uploaded. The Creator who uploaded the Co-Authored
                Content is solely responsible for dividing and distributing any
                revenue generated from the Co-Authored Content between the
                individuals shown in such Co-Authored Content. Any such
                revenue-sharing agreement shall be an independent, private
                agreement between you and such individual(s), and we are not
                responsible for providing or enforcing any such agreements. You
                understand and agree that you are not entitled to any Creator
                Earnings earned on any Co-Authored Content in which you appear
                but which is posted on another Creator’s account. If you post
                Co-Authored Content on your account, we may require you to
                provide valid and complete legal information for all individuals
                who appear in the Co-Authored Content. If you fail to provide
                any information requested by us upon our request, we may delete
                the Co-Authored Content, restrict your rights and permissions to
                post as a Creator, terminate your account, and/or withhold all
                or any portion of Creator Earnings earned but not yet paid out
                to you.
              </li>
              <li className={styles.terms_content_list}>
                You agree to release us from and not to make any claims against
                us arising from Co-Authored Content. You agree that all claims
                arising from Co-Authored Content shall be made against the
                Creator(s) who posted Co-Authored Content or the individual(s)
                who appeared in the Co-Authored Content (as applicable).
              </li>
            </ol>
          </li>
          <li className={styles.terms_content_list}>
            <strong className={styles.terms_content_list_bold}>
              Payouts to Creators:
            </strong>
            <ol>
              <li className={styles.terms_content_list}>
                All Gamer Payments will be processed by a third-party payment
                provider approved by us.
              </li>
              <li className={styles.terms_content_list}>
                If you have chosen the Stripe Payout Option, Stripe will handle
                the Gamer Payment and transfer the Creator Earnings directly to
                your bank account.
              </li>
              <li className={styles.terms_content_list}>
                If Stripe is not the Payout Option chosen by you, Our Fee will
                be deducted from the Gamer Payment received, and your Creator
                Earnings will be held by us or one of our subsidiary companies
                on your behalf.
              </li>
              <li className={styles.terms_content_list}>
                Your OnlyGamers account will be updated within a reasonable time
                with your Creator Earnings. Your Creator Earnings will become
                available for withdrawal by you from your OnlyGamers account
                once such Creator Earnings appear in your OnlyGamers account.
              </li>
              <li className={styles.terms_content_list}>
                To withdraw Creator Earnings from your OnlyGamers account, you
                must have at least the minimum payout amount in your OnlyGamers
                account. Please refer to the Banking page on your account to see
                what the minimum payout amount is for your country of residence
                and chosen Payout Option.
              </li>
              <li className={styles.terms_content_list}>
                The amount displayed in your ‘current balance’ in your
                OnlyGamers account represents your Creator Earnings at that
                time. All Gamer Payments and Creator Earnings are transacted in
                USD only. If you have selected the "Stripe" Payout Option, then
                the Gamer Payments and Creator Earnings figures will be
                converted into your local currency, using an exchange rate
                controlled by Stripe. Your bank or e-wallet company may impose
                currency conversion or transfer fees to access the money. We do
                not control currency exchange rates or charges by your bank or
                e-wallet company, and we and our subsidiary companies will not
                be responsible for any such charges.
              </li>
              <li className={styles.terms_content_list}>
                If a Gamer successfully secures a refund or chargeback from
                their credit card provider regarding a Gamer Payment made to
                you, we may conduct an investigation and might decide to deduct
                from your account an amount equal to the Creator Earnings earned
                by you on the charged-back or refunded amount.
              </li>
              <li className={styles.terms_content_list}>
                Except for Payout Options involving direct bank transfer, we do
                not retain any data you disclose when you register your Payout
                Options with a third-party payment provider.
              </li>
            </ol>
          </li>
          <li className={styles.terms_content_list}>
            <strong className={styles.terms_content_list_bold}>
              Circumstances in Which We May Withhold Creator Earnings:
            </strong>
            <ol>
              <li className={styles.terms_content_list}>
                We may withhold all or any part of the Creator Earnings due to
                you but not yet paid out under the following conditions:
                <ol>
                  <li className={styles.terms_content_list}>
                    If we believe that you have seriously or repeatedly breached
                    any part of the Terms of Service;
                  </li>
                  <li className={styles.terms_content_list}>
                    If you attempt or threaten to breach any part of the Terms
                    of Service in a way that we believe has or could have
                    serious consequences for us or another User (including
                    actual or possible loss caused to us or another User);
                  </li>
                  <li className={styles.terms_content_list}>
                    If we suspect that all or any part of the Creator Earnings
                    resulted from unlawful or fraudulent activity, either by you
                    or by the Gamer who made the payment resulting in the
                    Creator Earnings,
                  </li>
                  for as long as necessary to investigate the actual,
                  threatened, or suspected breach by you or the suspected
                  unlawful activity. If after our investigation, we conclude
                  that (i) you have seriously or repeatedly breached any part of
                  the Terms of Service; (ii) you have attempted or threatened to
                  breach any part of the Terms of Service in a way that has or
                  could have serious consequences for us or another User, and/or
                  (iii) the Creator Earnings resulted from unlawful or
                  fraudulent activity, we may notify you that you have forfeited
                  your Creator Earnings.
                </ol>
              </li>
              <li className={styles.terms_content_list}>
                Additionally, we may withhold all or any part of the Creator
                Earnings due to you but not yet paid out if we receive notice
                that you have secured, encumbered, pledged, assigned, or
                otherwise allowed a lien to be placed on Creator Earnings. We
                have no obligation to pay Creator Earnings to third-party
                lienholders and may withhold payment of Creator Earnings until
                the lien has been removed.
              </li>
              <li className={styles.terms_content_list}>
                We shall not bear any responsibility to you if we withhold or
                forfeit any of your Creator Earnings where we have a right to do
                so under these Terms of Use for Creators.
              </li>
              <li className={styles.terms_content_list}>
                If we are withholding any part of the Creator Earnings due to
                you and determine that part of the Creator Earnings withheld by
                us is unrelated to breaches by you of the Terms of Service or
                suspected unlawful or fraudulent activity, then we may arrange
                for you to be paid the part of the Creator Earnings which we
                determine to be unrelated to such breaches or suspected
                activity. However, you agree that if we consider that your
                breach(es) of the Terms of Service has or may cause us loss, we
                may withhold all Creator Earnings due to you but not yet paid
                out and may set off such amounts against any losses suffered by
                us.
              </li>
              <li className={styles.terms_content_list}>
                Should we conclude after our investigation that Creator Earnings
                are forfeited, we will (unless prohibited by law) make efforts
                to ensure that any payments which resulted in forfeited Creator
                Earnings are returned to the relevant Gamers who made such
                payments.
              </li>
            </ol>
          </li>
          <li className={styles.terms_content_list}>
            <strong className={styles.terms_content_list_bold}>
              Promoting Tax Compliance and VAT:
            </strong>
            <ol>
              <li className={styles.terms_content_list}>
                General:
                <ol>
                  <li className={styles.terms_content_list}>
                    We recommend that all Creators seek professional advice to
                    ensure you are compliant with your local Tax and VAT rules,
                    based on your individual circumstances.
                  </li>
                  <li className={styles.terms_content_list}>
                    By using OnlyGamers as a Creator, you warrant (which means
                    you make a legally enforceable promise) that you have
                    reported and will report in the future the receipt of all
                    payments made to you in connection with your use of
                    OnlyGamers to the relevant Tax authority in your
                    jurisdiction, as required by law.
                  </li>
                  <li className={styles.terms_content_list}>
                    By using OnlyGamers as a Creator, you warrant that you will
                    at all times comply with all laws and regulations relating
                    to Tax which apply to you. If any Tax non-compliance occurs
                    in relation to you (including a failure by you to report
                    earnings or the imposition on you of any penalty or interest
                    relating to Tax) or if any litigation, inquiry, or
                    investigation is commenced against you in connection with
                    any occurrence of Tax non-compliance, you agree to:
                    <ol>
                      <li className={styles.terms_content_list}>
                        Notify us by email in writing within 7 days of the
                        occurrence of the Tax non-compliance or the commencement
                        of the litigation, inquiry, or investigation;
                      </li>
                      <li className={styles.terms_content_list}>
                        Promptly provide us with details of the steps you are
                        taking to address the Tax non-compliance and to prevent
                        the same from happening again, together with any
                        mitigating factors that you consider relevant;
                      </li>
                      <li className={styles.terms_content_list}>
                        Provide any other information in relation to the Tax
                        non-compliance as we may reasonably require.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li className={styles.terms_content_list}>
                UK VAT and UK Established Creators:
                <ol>
                  <li className={styles.terms_content_list}>
                    For UK VAT purposes, Creators are treated as providing their
                    services to OnlyGamers, rather than to Gamers directly.
                  </li>
                  <li className={styles.terms_content_list}>
                    If you are a Creator registered for UK VAT, you will be
                    treated, for VAT purposes, as charging OnlyGamers your
                    Creator Earnings, together with UK VAT at the prevailing
                    rate. OnlyGamers agrees that you can add UK VAT to the
                    amount of any referral payments payable to you under the
                    Referral Program Terms.
                    <ol>
                      <li className={styles.terms_content_list}>
                        You agree to use a designated VAT invoice generator tool
                        to generate monthly VAT invoices, addressed to our
                        company, in respect of your Creator Earnings for the
                        relevant period together with the VAT on such earnings.
                      </li>
                      <li className={styles.terms_content_list}>
                        The VAT Amount will be paid to you by way of a separate
                        payment, provided you have submitted to us your VAT
                        registration number, a valid VAT invoice using the
                        designated generator tool, and a VAT return made to HM
                        Revenue & Customs.
                      </li>
                      <li className={styles.terms_content_list}>
                        You shall pay the VAT Amount which is paid to you
                        directly to HM Revenue & Customs.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          Last Updated: March 2024
          <h2 className={styles.terms_heading_content_title}>
            ACCEPTABLE USE POLICY
          </h2>
          <h3>
            BY USING OUR WEBSITE YOU AGREE TO THIS POLICY – PLEASE READ IT
            CAREFULLY
          </h3>
          This Policy applies to your use of OnlyGamers and all Content on
          OnlyGamers and forms part of your agreement with us. This Policy sets
          out what is and is not permitted on OnlyGamers.
          <br />
          In this Policy, defined terms have the same meanings as in our Terms
          of Use for all Users.
          <ol>
            <li className={styles.terms_content_list}>
              Do not use OnlyGamers except for your own personal use. Do not
              sell, rent, transfer, or share your account or any Content
              obtained from your use of OnlyGamers with anyone else.
            </li>
            <li className={styles.terms_content_list}>
              Only use OnlyGamers in a manner and for a purpose that is lawful.
            </li>
            <li className={styles.terms_content_list}>
              Do not upload, post, display, or publish Content on OnlyGamers
              that is illegal, fraudulent, defamatory, hateful, discriminatory,
              threatening, or harassing, or which encourages or promotes
              violence or any illegal activity.
            </li>
            <li className={styles.terms_content_list}>
              Do not use OnlyGamers in any way that may exploit, harm, or
              attempt to exploit or harm individuals under 18 years old, for
              example, by exposing them to inappropriate Content.
            </li>
            <li className={styles.terms_content_list}>
              Do not upload, post, display, or publish Content on OnlyGamers
              that:
              <ol>
                <li className={styles.terms_content_list}>
                  shows, includes, or refers to any individual under 18 years
                  old, or
                </li>
                <li className={styles.terms_content_list}>
                  shows, promotes, advertises, or refers to:
                  <ol>
                    <li className={styles.terms_content_list}>
                      firearms, weapons, or any goods whose sale, possession, or
                      use is restricted;
                    </li>
                    <li className={styles.terms_content_list}>
                      drugs or drug paraphernalia;
                    </li>
                    <li className={styles.terms_content_list}>
                      self-harm or suicide;
                    </li>
                    <li className={styles.terms_content_list}>
                      violence, rape, lack of consent, or any form of sexual
                      assault;
                    </li>
                    <li className={styles.terms_content_list}>
                      hate speech or content that vilifies or dehumanizes
                      individuals or groups based on any protected
                      characteristic;
                    </li>
                    <li className={styles.terms_content_list}>
                      personal data or private information without express
                      written consent;
                    </li>
                    <li className={styles.terms_content_list}>
                      activities that violate intellectual property rights or
                      privacy rights.
                    </li>
                  </ol>
                </li>
              </ol>
              <li className={styles.terms_content_list}>
                You must comply with the requirements set out in our Community
                Guidelines.
              </li>
              <li className={styles.terms_content_list}>
                Do not use OnlyGamers to stalk, bully, abuse, harass, threaten,
                or intimidate anyone.
              </li>
              <li className={styles.terms_content_list}>
                Respect the intellectual property rights of Creators, including
                by not recording, reproducing, sharing, or distributing their
                Content without authorization.
              </li>
              <li className={styles.terms_content_list}>
                Do not impersonate us, our employees, another User, or any
                person or company, or falsely suggest any affiliation or
                endorsement.
              </li>
              <li className={styles.terms_content_list}>
                Do not post spam, artificially manipulate interactions, or
                transmit malicious software.
              </li>
              <li className={styles.terms_content_list}>
                Do not interfere with the operation of OnlyGamers or its
                security, or use automated means to access or scrape Content.
              </li>
              <li className={styles.terms_content_list}>
                Use OnlyGamers' name, logo, or related marks only as expressly
                permitted in the Terms of Service or with our prior written
                agreement.
              </li>
            </li>
          </ol>
          Last updated: March 2024
        </div>
      </section>
    </>
  );
};

export default Terms;

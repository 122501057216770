import React, { useState, useRef } from 'react';
import cn from 'classnames';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop from 'react-image-crop';
import { centerCrop, convertToPixelCrop, makeAspectCrop } from 'react-image-crop';
import  setCanvasPreview  from '../../../screens/CreatePost/UploadContent_Component/ImagePreview_Component/setCanvasPreview';

const ASPECT_RATIO = 1;
const MIN_DIMENSION = 80;

const AvatarPreview = ({ imgSrc, onClose, handleImgSrc, updatedImgSrc }) => {
  const imageRef = useRef();
  const canvasRef = useRef();
  const [crop, setCrop] = useState();

  const onImageLoad = (e) => {
    const { width, height, naturalWidth, naturalHeight } = e.currentTarget;

    // Image size validation
    if (naturalHeight < MIN_DIMENSION || naturalWidth < MIN_DIMENSION) {
      handleImgSrc(); // Clear/reject image if too small
      return;
    }

    // Automatic crop generation based on aspect ratio
    const cropObj = makeAspectCrop(
      { unit: 'px', width: 80, height:80 }, // Square crop area
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(cropObj, width, height);
    setCrop(centeredCrop);
  };

  const handleSave = async () => {
    setCanvasPreview(
        imageRef.current, canvasRef.current, convertToPixelCrop(crop, imageRef.current.width, imageRef.current.height)
      )
    const imageData = await new Promise((resolve) => {
      canvasRef.current.toBlob(resolve, 'image/jpeg', /* quality */ 0.8); // Adjust format and quality as needed
    });
    updatedImgSrc(imageData);
    onClose();
  };

  return (
    <div className='fixed z-50 px-6 top-0 left-0 right-0 bottom-0 h-screen w-full bg-[rgba(0,0,0,0.5)] flex justify-center items-center'>
  <svg onClick={onClose} focusable="false" className='fixed top-10 right-10 w-10 fill-white cursor-pointer' aria-hidden="true" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
      {/* Close button */}
      </svg>
        <div className='flex flex-col gap-4'>
        <ReactCrop
          src={imgSrc}
          crop={crop}
          keepSelection
          minWidth={80}
          minHeight={80}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          aspect={ASPECT_RATIO}
          circularCrop={true} // Circular crop
        >
          <img
            alt='preview'
            ref={imageRef}
            className='max-w-[400px!important] w-full'
            src={imgSrc}
            onLoad={onImageLoad}
          />
        </ReactCrop>
        <button
          onClick={handleSave}
          title='Save the image'
          className='bg-[#2A85FF] py-2 px-10 rounded-xl'
        >
          <span className='text-white'>Save</span>
        </button>
      </div>
      {crop && (
        <canvas
          ref={canvasRef}
          className='mt-4 hidden'
          width={crop.width}
          height={crop.height}
          style={{
            objectFit: 'contain',
            width: 1280,
            height: 300,
          }}
        />
      )}
    </div>
  );
};

export default AvatarPreview;

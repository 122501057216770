import { useState, useEffect } from 'react'
import cn from 'classnames'
import styles from './Details.module.sass'
import Icon from '../../../Icon'
import Overview from './Overview'
// import { useContext } from "react";
// import { UserContext } from "../../../../UserContext";
import { generateClient } from "aws-amplify/api";

import {
  createLikes,
  deleteLikes,
  updatePosts
} from '../../../../graphql/mutations'
import { likesByPostIDAndUserID, getPosts } from '../../../../graphql/queries'
import { Amplify } from 'aws-amplify'
import amplifyConfig from '../../../../amplifyConfig'
import { useSelector } from 'react-redux'
import awsExports from "../../../../aws-exports";
import { is } from 'date-fns/locale'
const client = generateClient();
const navigation = ['Post']
Amplify.configure(awsExports);

const Details = ({
  item,
  likes,
  className,
  setValue,
  activeIndex,
  setActiveIndex,
}) => {
  const [isLiked, setIsLiked] = useState(false)
  const [like, setLike] = useState(likes)

  // const { userData } = useContext(UserContext);
  const userData = useSelector(state => state.user.userData)
  Amplify.configure(amplifyConfig)
  const client = generateClient()

  const handleClick = index => {
    setActiveIndex(index)
    index === 0 && setValue(false)
    index === 1 && setValue(true)
  }

 

return (
  <div className={cn(styles.details, className)}>
    <div className={styles.head}>
      <div className={styles.nav}>
        {navigation.map((x, index) => (
          <button
            className={cn(styles.link, { [styles.active]: index === activeIndex })}
            onClick={() => handleClick(index)}
            key={index}
          >
            {x}
          </button>
        ))}
      </div>
      <div className={styles.btns}>
        {/* <button
          onClick={handleLikeClick}
          className={cn('button-stroke', styles.favorite)}
          
        >
          <Icon name={isLiked ? 'heart-fill' : 'heart'} size='24' />
          <span>{like}</span>
        </button> */}
      </div>
    </div>
    <Overview item={item} />
  </div>
);
};

export default Details

import { useState } from 'react'
import cn from 'classnames'
import Modal from '../../components/Modal'
import styles from './MyProfilePage.module.sass'
import Card from '../../components/Card'
import Dropdown from '../../components/Dropdown'
import Product from '../../components/Product'
import Follower from './Follower'
import { listSubscriberRequests } from '../../graphql/queries'
import { getUrl } from 'aws-amplify/storage'
import { useEffect } from 'react'
import { Amplify } from 'aws-amplify'
import { postsByUserID } from '../../graphql/queries'
import { userByUsername } from '../../graphql/queries'
import { generateClient } from 'aws-amplify/api'
import resultImage from './noresult.png'
import { useRef } from 'react'
import NoResult from './NoResult'
import RequestModal from '../../components/Page/AddRequestModal/RequestModal'
import amplifyConfig from '../../amplifyConfig'
import { useDispatch, useSelector } from 'react-redux'
import { setUserData, setPosts, setPostImage } from '../../slices/userSlice'
import BannerModal from './BannerModal/BannerModal'
import Icon from '../../components/Icon'
import MyProfile from './MyProfile'
const navigation = ['Videos', 'Subscriber Requests'] // Tabs for profile sections
const intervals = ['Most recent', 'Most new', 'Most popular'] // Sorting options

const MyProfilePage = () => {
  const dispatch = useDispatch()
  const [activeIndex, setActiveIndex] = useState(0)
  const [sorting, setSorting] = useState(intervals[0])
  const [visibleModal, setVisibleModal] = useState(false)
  // const [BannervisibleModal, setBannerVisibleModal] = useState(false)
  const userDetails = useSelector(state => state?.auth.userDetails)
  const [error, setError] = useState(null)
  const [filteredUser, setFilteredUser] = useState(null)
  const [filteredPost, setFilteredPost] = useState(null)
  const [listSubscriberRequest, setListSubscriberRequest] = useState(null)
  const myRef = useRef([]) // Ref to hold fetched user data
  const myRef2 = useRef([]) // Ref to hold fetched posts
  const myRef3 = useRef([]) // Ref to hold fetched subscriber requests
  // const [isLoading, setIsLoading] = useState(true)
  // const profileBanner = useSelector(
  //   state => state.user?.userDetails && state.user.userDetails?.banner
  // )
  const profileBanner = useSelector((state) => state?.auth?.userDetails?.banner)
  const thumbnailImages = useSelector(state => state.user?.posts)
  const videos = useSelector(state => state.user?.posts)
  useEffect(() => {
    const testGraphQL = async () => {
      Amplify.configure(amplifyConfig)
      const client = generateClient()

      try {
        // Fetch user data by username
        const User = await client.graphql({
          query: userByUsername,
          variables: {
            username: userDetails.username
          }
        })

        myRef.current = User.data.userByUsername.items
        const user = myRef.current

        if (user) {
          // If the user exists...
          dispatch(setUserData(user))
          setFilteredUser(user)

          const ListPost = await client.graphql({
            query: postsByUserID,
            variables: {
              userID: user[0].id
            }
          })

          myRef2.current = ListPost.data.postsByUserID.items
          const post = myRef2.current
          if (post) {
            dispatch(setPosts(post))
            setFilteredPost(post)
            post.forEach(async post => {
              if (post.thumbnail) {
                try {
                  const url = await getUrl({ key: post.thumbnail })
                  dispatch(setPostImage(post.id, url.url.href))
                } catch (error) {
                  console.error('Error fetching post image:', error)
                }
              }
            })
          }

          if (user) {
            const listSubscriber_Request = await client.graphql({
              query: listSubscriberRequests,
              variables: {
                filter: {
                  creator_id: { eq: user[0].id }
                }
              }
            })
            myRef3.current = listSubscriber_Request
            setListSubscriberRequest(
              myRef3.current.data.listSubscriberRequests.items
            )
          }
        } else {
          setError('User not found')
        }
      } catch (error) {
        setError('Error fetching user data')
        console.error('Error testing GraphQL operations:', error)
        // setIsLoading(false)
      }
      // setIsLoading(false)
    }

    testGraphQL()
  }, [userDetails.username]) // Run useEffect when username changes

  if (error) {
    return <p>{error}</p>
  }

  // if (!filteredUser || !filteredPost || !listSubscriberRequest || isLoading) {
  //   return <p>Loading...</p>
  // }

  return (
    <>
      <div className={styles.shop}>
        {/* <div className={`${styles.background} profile-bg relative z-[1!important]`}>
          <img
            className={userDetails.username ? styles.backgroundImage : ''}
            onClick={() => setBannerVisibleModal(true)}
            src={`${process.env.REACT_APP_S3_PUBLIC}${profileBanner}`}
            alt='Background'
          />
          <span className='absolute banner-change'>CHANGE BANNER</span>
        </div> */}
        <Card className={styles.card}>
          <MyProfile myProfile={userDetails.username} />
          <div className={styles.control}>
            <div className={styles.outer}>
              <Modal
                outerClassName={styles.outer}
                visible={visibleModal}
                onClose={() => setVisibleModal(false)}
              >
                <RequestModal ModalVisible={() => setVisibleModal(false)} />
              </Modal>

              {/* <Modal
                outerClassName={styles.outer}
                visible={BannervisibleModal}
                onClose={() => setBannerVisibleModal(false)}
              >
                <BannerModal
                  ModalVisible={() => setBannerVisibleModal(false)}
                />
              </Modal> */}
            </div>
          </div>
        </Card>
      </div>
    </>
  )
}

export default MyProfilePage

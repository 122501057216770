import { useState } from "react";
import cn from "classnames";
// import Modal from '../../components/Modal'
import styles from "./ProfilePage.module.sass";
import stylesSkeleton from "./ProfileSkeletonLoader/ProfileSkeleton.module.sass";
import Profile from "./Profile";
import Card from "../../components/Card";
import Dropdown from "../../components/Dropdown";
import Product from "../../components/Product";
import Follower from "./Follower";
import MyProfilePage from "../MyProfilePage";
import { listSubscriberRequests } from "../../graphql/queries";
import { getUrl } from "aws-amplify/storage";
import { useParams } from "react-router";
import { useEffect } from "react";
import { Amplify } from "aws-amplify";
import { postsByUserID } from "../../graphql/queries";
import { userByUsername } from "../../graphql/queries";
import { generateClient } from "aws-amplify/api";
import resultImage from "./noresult.png";
import { AddRequest as RequestCard } from "./AddRequest";
import { useRef } from "react";
import NoResult from "./NoResult";
// import RequestModal from '../../components/Page/AddRequestModal/RequestModal'
import amplifyConfig from "../../amplifyConfig";
import { useDispatch, useSelector } from "react-redux";
import { setUserData, setPosts, setPostImage } from "../../slices/userSlice";
import ProfileEdit from "../ProfileEdit";
import Modal from "../../components/Modal";
import BannerModal from "../MyProfilePage/BannerModal/BannerModal";
const navigation = ["Videos", "Subscriber Requests"]; // Tabs for profile sections
const intervals = ["Most recent", "Most new", "Most popular"]; // Sorting options
const ProfilePage = ({ ProfileUsername }) => {
  const dispatch = useDispatch();
  // State variables for tab selection, sorting, and UI elements
  const [activeIndex, setActiveIndex] = useState(0);
  const [sorting, setSorting] = useState(intervals[0]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [BannervisibleModal, setBannerVisibleModal] = useState(false);
  const { username } = useParams(); // Get username from URL parameter
  const userName = useSelector((state) => state.auth.userDetails?.username);
  const [error, setError] = useState(null);
  const [filteredUser, setFilteredUser] = useState(null);
  const [filteredPost, setFilteredPost] = useState(null);
  const [listSubscriberRequest, setListSubscriberRequest] = useState(null);
  const myRef = useRef([]); // Ref to hold fetched user data
  const myRef2 = useRef([]); // Ref to hold fetched posts
  const myRef3 = useRef([]); // Ref to hold fetched subscriber requests
  const [isLoading, setIsLoading] = useState(true);
  const profileBanner = useSelector(
    (state) => state.user?.userData && state.user.userData[0]?.banner
  );
  const thumbnailImages = useSelector((state) => state.user?.posts);
  const selfId = useSelector((state) => state.auth?.userDetails.id);
  const [refetchTrigger, setRefetchTrigger] = useState(0);
  const videos = useSelector((state) => state.user?.posts);
  const [currentUser, setCurrentUser] = useState([]);
  const handleBanner = () => {
    if (username === userName) {
      setBannerVisibleModal(true);
    }
  };
  useEffect(() => {
    const testGraphQL = async () => {
      Amplify.configure(amplifyConfig);
      const client = generateClient();

      try {
        // Fetch user data by username
        const User = await client.graphql({
          query: userByUsername,
          variables: {
            username: username,
          },
        });

        myRef.current = User.data.userByUsername.items;
        const user = myRef.current;
        setCurrentUser(user);

        if (user) {
          // If the user exists...

          dispatch(setUserData(user));
          setFilteredUser(user);

          // Fetch and set user's banner image

          const ListPost = await client.graphql({
            query: postsByUserID,
            variables: {
              userID: user[0].id,
            },
          });

          myRef2.current = ListPost.data.postsByUserID.items;
          const post = myRef2.current;
          if (post) {
            dispatch(setPosts(post));
            setFilteredPost(post);
            post.forEach(async (post) => {
              if (post.thumbnail) {
                try {
                  const url = await getUrl({ key: post.thumbnail });
                  dispatch(setPostImage(post.id, url.url.href));
                } catch (error) {
                  console.error("Error fetching post image:", error);
                }
              }
            });
          }

          if (user) {
            const listSubscriber_Request = await client.graphql({
              query: listSubscriberRequests,
              variables: {
                filter: {
                  creator_id: { eq: user[0]?.id },
                },
              },
            });
            myRef3.current = listSubscriber_Request;
            setListSubscriberRequest(
              myRef3.current.data.listSubscriberRequests.items
            );
          }
        } else {
          setError("User not found");
        }
      } catch (error) {
        setError("Error fetching user data");
        console.error("Error testing GraphQL operations:", error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };
    setActiveIndex(0); // when user page changes go back to videos
    setVisibleModal(false); // when user page changes close request
    testGraphQL();
  }, [refetchTrigger, username]); // Run useEffect when username changes

  if (error) {
    return <p>{error}</p>;
  }

  if (!filteredUser || !filteredPost || !listSubscriberRequest || isLoading) {
    return (
      <div className={stylesSkeleton.skeletonContainer}>
        <div className={stylesSkeleton.skeletonBannerSection}>
          <div className={stylesSkeleton.skeletonBanner}>
            <div className={stylesSkeleton.skeletonBannerImage}></div>
            <div className={stylesSkeleton.skeletonProductDetails}>
              {/* <div className={stylesSkeleton.skeletonProductTitle}></div> */}
              {/* <div className={stylesSkeleton.skeletonProductStats}>
                <svg fill="#6f767e" width="24" height="24" viewBox="0 0 16 16">
                  <path d="M7.867 3.475L8 3.611l.133-.136c1.495-1.523 3.923-1.523 5.418 0a3.93 3.93 0 0 1 0 5.485l-4.123 4.202a2 2 0 0 1-2.855 0L2.449 8.961a3.93 3.93 0 0 1 0-5.485c1.495-1.523 3.923-1.523 5.418 0zM3.4 4.409c-.979.998-.979 2.62 0 3.618l4.123 4.202c.261.266.69.266.952 0l4.123-4.202c.979-.998.979-2.62 0-3.618a2.45 2.45 0 0 0-3.514 0l-.609.621c-.125.128-.297.2-.476.2s-.35-.072-.476-.2l-.609-.621a2.45 2.45 0 0 0-3.514 0z"></path>
                </svg>
                <span className={stylesSkeleton.skeletonSpan}></span>
              </div> */}
            </div>
          </div>
        </div>
        <div className={stylesSkeleton.skeletonProfileDetailsContainer}>
          <div className={stylesSkeleton.skeletonLeftContainer}>
            <div className={stylesSkeleton.skeletonAvatar}></div>
            <div className={stylesSkeleton.skeletonProfileContainer}>
              <h2
                content="skeleton"
                className={stylesSkeleton.skeletonUserName}
              ></h2>
              <p className={stylesSkeleton.skeletonPostCount}></p>
            </div>
          </div>
          <div className={stylesSkeleton.skeletonButtonContainer}>
            <div className={stylesSkeleton.skeletonButton}></div>
            <div className={stylesSkeleton.skeletonButton2}></div>
          </div>
        </div>
        <div className={stylesSkeleton.skeletonVideos}>
          <div className={stylesSkeleton.skeletonVideo}>
            <div className={stylesSkeleton.skeletonProductImage}></div>
            <div className={stylesSkeleton.skeletonProductDetails}>
              <div className={stylesSkeleton.skeletonProductTitle}></div>
              <div className={stylesSkeleton.skeletonProductStats}>
                <svg fill="#6f767e" width="24" height="24" viewBox="0 0 16 16">
                  <path d="M7.867 3.475L8 3.611l.133-.136c1.495-1.523 3.923-1.523 5.418 0a3.93 3.93 0 0 1 0 5.485l-4.123 4.202a2 2 0 0 1-2.855 0L2.449 8.961a3.93 3.93 0 0 1 0-5.485c1.495-1.523 3.923-1.523 5.418 0zM3.4 4.409c-.979.998-.979 2.62 0 3.618l4.123 4.202c.261.266.69.266.952 0l4.123-4.202c.979-.998.979-2.62 0-3.618a2.45 2.45 0 0 0-3.514 0l-.609.621c-.125.128-.297.2-.476.2s-.35-.072-.476-.2l-.609-.621a2.45 2.45 0 0 0-3.514 0z"></path>
                </svg>
                <span className={stylesSkeleton.skeletonSpan}></span>
              </div>
            </div>
          </div>
          <div className={stylesSkeleton.skeletonVideo}>
            <div className={stylesSkeleton.skeletonProductImage}></div>
            <div className={stylesSkeleton.skeletonProductDetails}>
              <div className={stylesSkeleton.skeletonProductTitle}></div>
              <div className={stylesSkeleton.skeletonProductStats}>
                <svg fill="#6f767e" width="24" height="24" viewBox="0 0 16 16">
                  <path d="M7.867 3.475L8 3.611l.133-.136c1.495-1.523 3.923-1.523 5.418 0a3.93 3.93 0 0 1 0 5.485l-4.123 4.202a2 2 0 0 1-2.855 0L2.449 8.961a3.93 3.93 0 0 1 0-5.485c1.495-1.523 3.923-1.523 5.418 0zM3.4 4.409c-.979.998-.979 2.62 0 3.618l4.123 4.202c.261.266.69.266.952 0l4.123-4.202c.979-.998.979-2.62 0-3.618a2.45 2.45 0 0 0-3.514 0l-.609.621c-.125.128-.297.2-.476.2s-.35-.072-.476-.2l-.609-.621a2.45 2.45 0 0 0-3.514 0z"></path>
                </svg>
                <span className={stylesSkeleton.skeletonSpan}></span>
              </div>
            </div>
          </div>
          <div className={stylesSkeleton.skeletonVideo}>
            <div className={stylesSkeleton.skeletonProductImage}></div>
            <div className={stylesSkeleton.skeletonProductDetails}>
              <div className={stylesSkeleton.skeletonProductTitle}></div>
              <div className={stylesSkeleton.skeletonProductStats}>
                <svg fill="#6f767e" width="24" height="24" viewBox="0 0 16 16">
                  <path d="M7.867 3.475L8 3.611l.133-.136c1.495-1.523 3.923-1.523 5.418 0a3.93 3.93 0 0 1 0 5.485l-4.123 4.202a2 2 0 0 1-2.855 0L2.449 8.961a3.93 3.93 0 0 1 0-5.485c1.495-1.523 3.923-1.523 5.418 0zM3.4 4.409c-.979.998-.979 2.62 0 3.618l4.123 4.202c.261.266.69.266.952 0l4.123-4.202c.979-.998.979-2.62 0-3.618a2.45 2.45 0 0 0-3.514 0l-.609.621c-.125.128-.297.2-.476.2s-.35-.072-.476-.2l-.609-.621a2.45 2.45 0 0 0-3.514 0z"></path>
                </svg>
                <span className={stylesSkeleton.skeletonSpan}></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.shop}>
        <div
          className={`${styles.background} profile-bg relative z-[1!important]`}
        >
          {profileBanner && (
            <>
              <img
                onClick={handleBanner}
                className={username === userName ? styles.backgroundImage : ""}
                src={`${process.env.REACT_APP_S3_PUBLIC}${profileBanner}`}
                alt="banner"
              />
              {username === userName && (
                <span className="absolute banner-change">CHANGE BANNER</span>
              )}
            </>
          )}
          {!profileBanner && (
            <div
              onClick={handleBanner}
              className="h-full bg-[#2B2E30] rounded-lg"
            >
              {username === userName && (
                <>
                  <span className="absolute banner-change">CHANGE BANNER</span>
                </>
              )}
            </div>
          )}
          {username === userName && (
            <Modal
              outerClassName={styles.outer}
              visible={BannervisibleModal}
              onClose={() => setBannerVisibleModal(false)}
            >
              <BannerModal ModalVisible={() => setBannerVisibleModal(true)} />
            </Modal>
          )}
        </div>

        <Card className={styles.card}>
          {currentUser[0].id === selfId && <ProfileEdit />}
          {currentUser[0].id !== selfId && <Profile user={filteredUser} />}
          <div className={styles.control}>
            <div className={styles.nav}>
              {navigation.map((x, index) => (
                <button
                  className={cn(styles.link, {
                    [styles.active]: index === activeIndex,
                  })}
                  onClick={() => {
                    if (index === 0) {
                      setVisibleModal(false);
                    }
                    setActiveIndex(index);
                  }}
                  key={index}
                >
                  {x}
                </button>
              ))}
            </div>
            <div className={styles.outer}>
              {/* <Modal
              outerClassName={styles.outer}
              visible={visibleModal}
              onClose={() => setVisibleModal(false)}
            >
              <RequestModal ModalVisible={() => setVisibleModal(false)} />
            </Modal> */}
            </div>
            {username !== userName && (
              <div className={styles.Requst_btn_container}>
                <button
                  className={styles.Request_btn}
                  onClick={() => {
                    setVisibleModal((prev) => !prev);
                    setActiveIndex(1);
                  }}
                >
                  Add Request
                </button>
              </div>
            )}
            <div className={styles.dropdownBox}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={sorting}
                setValue={setSorting}
                options={intervals}
                small
              />
            </div>
          </div>
          <div className={styles.wrap}>
            {activeIndex === 0 && videos.length > 0 && (
              <>
                <div className={styles.products}>
                  {filteredPost.map((x, index) => (
                    <Product
                      user={filteredUser}
                      className={styles.product}
                      item={x}
                      key={index}
                      withoutСheckbox
                      thumbnailUrls={thumbnailImages[index]?.thumbnail}
                    />
                  ))}
                </div>
                {videos.length > 6 && (
                  <div className={styles.foot}>
                    <button
                      className={cn(
                        "button-stroke button-small",
                        styles.button
                      )}
                    >
                      Load more
                    </button>
                  </div>
                )}
              </>
            )}
            <RequestCard
              triggerParent={() => setRefetchTrigger((prev) => prev + 1)}
              isVisible={visibleModal}
              onClose={() => setVisibleModal(false)}
            />
            {activeIndex === 0 && videos.length < 1 && (
              <NoResult
                img={resultImage}
                resultHeading={"No Videos Found"}
                resultSubText={
                  "Sadly no vidoes found. Try to search for something else."
                }
              />
            )}
            {activeIndex === 1 && listSubscriberRequest.length > 0 && (
              <>
                <div className={styles.followers}>
                  {listSubscriberRequest.reverse().map((x, index) => (
                    <Follower
                      className={styles.follower}
                      item={x}
                      key={index}
                      followerId={x.user_id}
                    />
                  ))}
                </div>
                <div className={styles.foot}>
                  {listSubscriberRequest.length > 6 && (
                    <button
                      className={cn(
                        "button-stroke button-small",
                        styles.button
                      )}
                    >
                      Load more
                    </button>
                  )}
                </div>
              </>
            )}
            {activeIndex === 1 && listSubscriberRequest.length < 1 && (
              <NoResult
                img={resultImage}
                resultHeading={"No subscription requests found"}
                resultSubText={"There is no subscription request at time."}
              />
            )}
            {activeIndex === 2 && (
              <>
                <div className={styles.followers}>
                  {listSubscriberRequest.map((x, index) => (
                    <Follower
                      className={styles.follower}
                      item={x}
                      key={index}
                    />
                  ))}
                </div>
                <div className={styles.foot}>
                  <button
                    className={cn("button-stroke button-small", styles.button)}
                  >
                    Load more
                  </button>
                </div>
              </>
            )}
          </div>
        </Card>
      </div>
    </>
  );
};

export default ProfilePage;

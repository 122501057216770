import cn from "classnames";
import styles from "./Thumbnail.module.sass";
import Card from "../../../components/Card";
import File from "../../../components/File";
import { useFileContext } from "../FileContext";
import { useState } from "react";
import { useDispatch } from 'react-redux';
import { setThumbnailImage } from "../../../slices/postSlice";
import { useEffect } from "react";
const Thumbnail = ({ className }) => {
	const [fileName, setFileName] = useState("Click or drop image");
	// const { setThumbnailImage } = usePost();
	const { setThumbnail, setThumbnailKey } = useFileContext();
	const [thumbnailPreview, setThumbnailPreview] = useState('');
	const [showError, setShowError] = useState(false);
    const [errorMsg, seterrorMsg] = useState("");// validation check for correct extension of image
	const dispatch = useDispatch();
	// to disappear error after 3.5ms
	useEffect(() => {
		let timeoutId; // Automatically hide error messages after 2 seconds if there is an error
		if (showError) {
		  timeoutId = setTimeout(() => setShowError(false), 3500);
		} else {
		  setShowError(false); // Hide error messages immediately if there is no error
		}
		return () => {
		  clearTimeout(timeoutId); //Clear the timeout when the component unmounts or when showError changes
		};
	  }, [showError]);
	const handleFileChange = (event) => {
		const allowedFormats = ["jpg", "jpeg", "png", "gif", "apng","webp"];
		let file = event.target.files[0];
		if(file){
			const fileExtension = file.name
		.split(".")
		.pop()
		.toLowerCase();
		
		if (!allowedFormats.includes(fileExtension)) {
			setShowError(true);
			seterrorMsg(
			  "Invalid file format! Please select an image file with one of the following extensions: JPG, JPEG, PNG, GIF, APNG."
			);
			setThumbnailPreview("");
			setFileName("Click or drop image");
		  }
		  else{
		setFileName(file.name);
		setThumbnailKey(file.name);
		dispatch(setThumbnailImage(file.name));
		setThumbnail(file);
	
		// Read the file and set the preview
		const reader = new FileReader();
		reader.onloadend = () => {
		  setThumbnailPreview(reader.result);
		};
		reader.readAsDataURL(file);
		  }
		
		}
		
	  };
	return (
		<Card
			className={cn(styles.card, className)}
			title="Thumbnail"
			classTitle="title-red"
		>
			<div className={styles.files}>
				<File
					className={styles.field}
					title={fileName}
					label="Thumbnail image"
					tooltip="Maximum 100 characters. No HTML or emoji allowed"
					onChange={handleFileChange}
				/>
			{thumbnailPreview && (
          	<div className={styles.imagePreview}>
            <img src={thumbnailPreview} alt="Thumbnail Preview" />
          	</div>
        )}
		 {showError && (
                <span className={styles.error}>
                  <br />

                  {errorMsg}
                </span>
              )}
			</div> 
		</Card>
	);
};

export default Thumbnail;
import cn from "classnames";
import styles from "./Theme.module.sass";
import useDarkMode from "use-dark-mode";
import Icon from "../Icon";
import { useEffect, useState } from "react";

const Theme = ({ className, visibleSidebar }) => {
  const darkMode = useDarkMode(true);
  const [themeSwitchSmall, setThemeSwitchSmall] = useState(false);
  useEffect(() => {
    let windowWidth = window.innerWidth;
    if (windowWidth < 1259 && !visibleSidebar && windowWidth > 767) {
      setThemeSwitchSmall(true);
    } else {
      setThemeSwitchSmall(false);
    }
    window.addEventListener("resize", updateWindowWidth);
    function updateWindowWidth() {
      windowWidth = window.innerWidth;
      if (windowWidth < 1259 && !visibleSidebar && windowWidth > 767) {
        setThemeSwitchSmall(true);
      } else {
        setThemeSwitchSmall(false);
      }
    }
    return () => window.removeEventListener("resize", updateWindowWidth);
  }, [visibleSidebar]);

  return (
    <>
      {themeSwitchSmall && (
        <div className={styles.themeSwitchContainerSmall}>
          {!darkMode.value && (
            <button
              onClick={() => darkMode.toggle()}
              className={styles.themeButtonDark}
            >
              <Icon name="sun" size="24" />
            </button>
          )}
          {darkMode.value && (
            <button
              onClick={() => darkMode.toggle()}
              className={styles.themeButtonLight}
            >
              <Icon name="moon" size="24" />
            </button>
          )}
        </div>
      )}
      {!themeSwitchSmall && (
        <div
          className={cn(styles.themeSwitchContainer, {
            [styles.lightMode]: !darkMode.value,
          })}
        >
          {!themeSwitchSmall && (
            <>
              <button
                onClick={() => darkMode.disable()}
                className={styles.themeButtonLight}
              >
                <Icon name="sun" size="24" />
                <span>Light</span>
              </button>
              <button
                onClick={() => {
                  darkMode.enable();
                }}
                className={styles.themeButtonDark}
              >
                <Icon name="moon" size="24" />
                <span>Dark</span>
              </button>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Theme;


import  { useCallback, useEffect, useRef } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { createPortal } from "react-dom";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import styles from "./ModalProduct.module.sass";
import Icon from "../Icon";
import Product from "./Product";

const ModalProduct = ({ item,likes, visible, onClose }) => {
  const escFunction = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );
  const controlRef = useRef();

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    if (visible) {
      const target = document.querySelector("#modal-product");
      disableBodyScroll(target);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [visible]);

  return createPortal(
    visible && (
      <div id="modal-product" onClick={(e) => {
        if(controlRef.current && e.target.contains(controlRef.current)){
          e.stopPropagation();
          onClose()
        }
      }} className={styles.modal}>
        <div ref={controlRef} className={styles.control}>
          <button className={styles.close} onClick={() => {
            onClose()
          }}>
            <Icon name="close" size="20" />
          </button>
        </div>
        <div className={styles.outer}>
          <Product item={item} likes={likes}/>
        </div>
      </div>
    ),
    document.body
  );
};

export default ModalProduct;

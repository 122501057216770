import setCanvasPreview from './setCanvasPreview';
import { useState, useRef } from 'react'
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from "react-image-crop"
const ASPECT_RATIO = 3/ 1; // Adjust the aspect ratio for squeezing the image into 250px height
const MIN_DIMENSION = 150;

const ImagePreview = ({ imgSrc, onClose, handleImgSrc, updatedImgSrc, imagePreview }) => {
  const imageRef = useRef();
  const canvasRef = useRef();
  const [crop, setCrop] = useState()

  const onImageLoad = (e) => {
    const { width, height, naturalWidth, naturalHeight } = e.currentTarget
    
    // Image size validation
    if (naturalHeight < MIN_DIMENSION || naturalWidth < MIN_DIMENSION) {
      handleImgSrc(); // Clear/reject image if too small
      return;
    }

    // Automatic crop generation
    const crop = makeAspectCrop(
      {unit: "px",width: 965, height: 199 }, // 25% of container width// Use natural width and height for the full image
      ASPECT_RATIO, 
      width, 
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop)
  }

  const handleSaveImage = async () => {
    setCanvasPreview(
      imageRef.current,
      canvasRef.current,
      convertToPixelCrop(crop, imageRef.current.width, imageRef.current.height)
    );
      
    const imageData = await new Promise((resolve) => {
      canvasRef.current.toBlob(resolve, "image/jpeg", 0.8); // Adjust format and quality as needed
    });
    updatedImgSrc(imageData);
    imagePreview(canvasRef.current.toDataURL())
    onClose();
  };

  return (
    <div className='fixed z-50 px-6 top-0 left-0 right-0 bottom-0 h-screen w-full bg-[rgba(0,0,0,0.5)] flex justify-center items-center'> {/* Modal/overlay styling */}
      <svg onClick={onClose} focusable="false" className='fixed top-10 right-10 w-10 fill-white cursor-pointer' aria-hidden="true" viewBox="0 0 24 24">
        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
      </svg>
      <div className='flex flex-col gap-4'>
        <ReactCrop
          crop={crop}
          keepSelection
          minWidth={965}
          minHeight={199}// Set the minimum height of the crop area
          onChange={(_, percentCrop) => {
            setCrop(percentCrop)
          }}
          aspect={ASPECT_RATIO}
        >
        
          <img alt="preview" ref={imageRef} className='max-w-[500px!important] w-full' src={imgSrc} onLoad={onImageLoad} style={{ maxWidth: '100%', maxHeight: '100%' }} />
     
        </ReactCrop>
        <button onClick={handleSaveImage} title='Save the image' className='bg-[#2A85FF] py-2 px-10 rounded-xl'>
          <span className='text-white'>Save</span>
        </button>
      </div>
      
      {crop &&
              
        <canvas ref={canvasRef} className='mt-4 hidden' style={{ objectFit: "contain", width: 1600, height: 800 }} />
    
      }
    </div>
  )
}

export default ImagePreview;

import cn from "classnames";
import styles from "./Item.module.sass";

const Item = ({ className, classTitle, title, children }) => {
  return (
    <div className={cn(styles.item, className)}> {/* Dynamically apply CSS classes */}
      <div className={cn(classTitle, styles.title)}>{title}</div>  {/* Render the title */}
      {children} {/* Render any nested content */}
    </div>
  );
};

export default Item;

import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./NameAndDescription.module.sass";
import Card from "../../../components/Card";
import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import Editor from "../../../components/Editor";
import { useDispatch } from "react-redux";
import { setProductTitle } from "../../../slices/postSlice";
// import { usePost } from "../PostContext";

const NameAndDescription = ({ className }) => {
	const dispatch = useDispatch();
	// State to manage the rich text editor content



	return (
		<Card
			className={cn(styles.card, className)}
			title="Title & description"
			classTitle="title-green"
			head={
				<Link
					className={cn("button-stroke button-small", styles.button)}
					to="/"
				>
					<Icon name="arrow-left" size="24" />
					<span>Back</span>
				</Link>
			}
		>
			<div className={styles.description}>
				<TextInput
					className={styles.field}
					label="Post title"
					name="title"
					type="text"
					tooltip="Maximum 100 characters. No HTML or emoji allowed"
					onChange={(event) => dispatch(setProductTitle(event.target.value))}
					required
				/>
				<Editor
					// onChange={handleEditorChange}
					classEditor={styles.editor}
					label="Description"
					tooltip="Description"
				/>
			</div>
		</Card>
	);
};

export default NameAndDescription;

import { useState, useEffect } from 'react'
import cn from 'classnames'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import styles from './Product.module.sass'
import Control from './Control'
import Icon from '../Icon'
import Checkbox from '../Checkbox'
import Lock_img from './images/lock.svg'
import ModalProduct from '../ModalProduct'
import { useSelector } from 'react-redux'
import { Amplify } from 'aws-amplify'
import awsExports from "../../aws-exports";
import amplifyConfig from '../../amplifyConfig'
import { generateClient } from 'aws-amplify/api'
const Product = ({
  user,
  className,
  item,
  value,
  onChange,
  released,
  withoutСheckbox,
  thumbnailUrls
}) => {
  const [visible, setVisible] = useState(false)

  const subscriptions = useSelector(state => state.userSubs.userSubscriptions)
  const [visibleModalProduct, setVisibleModalProduct] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const userDetails = useSelector(state => state.auth.userDetails)
  Amplify.configure(amplifyConfig)
  Amplify.configure(awsExports)
  const [like, setLike] = useState(item.likes)
  const client = generateClient();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const subscription = await client.graphql({
          query: `
            subscription OnPostUpdate($postId: ID!) {
              onUpdatePosts(filter: { id: { eq: $postId } }) {
                id
                likes
              }  
            }
          `,
          variables: { postId: item.id },
        }).subscribe({
          next: ({ data }) => {
            const { onUpdatePosts } = data;
            const newLikes = onUpdatePosts.likes;
            setLike(newLikes);
          },
          error: (error) => {
            console.warn('Subscription error:', error);
          }
        });

        return () => subscription.unsubscribe();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [item.id]);


  const handleClick = () => {
    onChange()
    setVisible(prev => !prev)
  }
  const handleModal = () => {
    if (subscriptions.find(x => x.userId === user[0].id)?.status || user[0].id === userDetails.id) {
      setVisibleModalProduct(true)
    }
  }


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsLoading(prev => !prev)
    }, 4000)

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId)
  }, [])

  return (
    <div
      className={cn(styles.product, className, {
        [styles.active]: visible
      })}
    >
      <ToastContainer position='top-center' theme='dark' />
      <div
        className={`${styles.preview} cursor-pointer relative`}
      >
        <div onClick={(e) => {
          e.stopPropagation()
          if (subscriptions.length > 0 && (subscriptions.find(x => x?.userId === user[0].id)?.status || user[0].id === userDetails.id)) {
            setVisibleModalProduct(true)
          }
        }} className='outer-overlay absolute z-[98] w-full h-full'></div>
        <ModalProduct
          item={item}
          likes={like}
          visible={visibleModalProduct}
          onClose={() => setVisibleModalProduct(false)}
        />
        {!withoutСheckbox && (
          <Checkbox
            className={styles.checkbox}
            classCheckboxTick={styles.checkboxTick}
            value={value}
            onChange={() => handleClick()}
          />
        )}
        {(subscriptions.find(x => x?.userId === user[0].id)?.status || user[0].id === userDetails.id) && (
          <Control item={item} className={styles.control} />
        )}
        {!subscriptions.find(x => x?.userId === user[0].id)?.status && !(user[0].id === userDetails.id) && (
          <div className={styles.control}>
            <button>
              {' '}
              <img
                src={Lock_img}
                style={{ width: '40px', height: '40px', position: 'relative' }}
                alt=''
              />
            </button>
          </div>
        )}
        <div>
          <img
            src={`${process.env.REACT_APP_S3_PUBLIC}${thumbnailUrls}`}
            alt='Product'
            className={`${isLoading ? styles.loading : styles.loading.loaded}`}
          />
        </div>
      </div>

      <div
        onClick={() => {
          if (subscriptions.length > 0 && (subscriptions?.find(x => x?.userId === user[0].id)?.status || user[0].id === userDetails.id)) {
            handleModal()
          }
        }}
        className={styles.title}
      >
        {item.title}
      </div>

      {released ? (
        <div className={styles.date}>
          <Icon name='clock' size='24' /> {item.date}
        </div>
      ) : like ? (
        <div className={styles.rating}>
          <Icon name='heart-fill' size='24' />
          {like}
        </div>
      ) : (
        <div className={cn(styles.rating, styles.ratingEmpty)}>
          <Icon name='heart' size='24' />
          No Likes
        </div>
      )}
    </div>
  )
}

export default Product

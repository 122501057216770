import { useState } from 'react'
import cn from 'classnames'
import styles from './ProfileEdit.module.sass'
import TooltipGlodal from '../../components/TooltipGlodal'
import File from '../../components/File'
import Card from '../../components/Card'
import TextInput from '../../components/TextInput'

// import { useSetting } from "./SettingsContext";
import { updateUser } from '../../graphql/mutations'
// import { useAuth } from "../../AuthContext";
import { Amplify } from 'aws-amplify'
import awsExports from '../../aws-exports'
import { generateClient } from 'aws-amplify/api'
import { uploadData } from 'aws-amplify/storage'
import { useSelector, useDispatch } from 'react-redux'
import { userDetails as setUserDetails } from '../../slices/authSlice'
import ProfilePage from './../ProfilePage/index'
import MyProfilePage from './../MyProfilePage/index'
import amplifyConfig from '../../amplifyConfig'
const ProfileEdit = () => {
  const dispatch = useDispatch()
  // const { userDetails, setUserDetails } = useAuth();
  const userDetails = useSelector(state => state?.auth.userDetails)
  // const { username } = useSetting();
  Amplify.configure(awsExports)
  Amplify.configure(amplifyConfig)
  const client = generateClient()

  const [tagLine, setTagLine] = useState('')
  const [bannerFileName, setBannerFileName] = useState('Click or drop file')
  const [bannerFileContent, setBannerFileContent] = useState(null)
  const [avatarFileName, setAvatarFileName] = useState('Click or drop file')
  const [avatarFileContent, setAvatarFileContent] = useState(null)
  const [socials, setSocials] = useState({
    Twitch: '',
    Youtube: '',
    Twitter: ''
  })
  const [bannerFileKey, setBannerFileKey] = useState('')
  const [avatarFileKey, setAvatarFileKey] = useState('')

  async function saveSettings () {
    try {
      if (bannerFileContent) {
        await uploadData({
          key: bannerFileKey,
          data: bannerFileContent,
          options: {
            accessLevel: 'public' // defaults to `guest` but can be 'private' | 'protected' | 'guest'
          }
        }).result
      }

      if (avatarFileContent) {
        await uploadData({
          key: avatarFileKey,
          data: avatarFileContent,
          options: {
            accessLevel: 'public' // defaults to `guest` but can be 'private' | 'protected' | 'guest'
          }
        }).result
      
      }
    } catch (error) {
      console.error('Error : ', error)
    } finally {
      const updateResult = await client.graphql({
        query: updateUser,
        variables: {
          input: {
            id: userDetails.id,
            tagline:
              userDetails.tagLine === '' && tagLine
                ? tagLine
                : userDetails.tagLine !== '' &&
                  tagLine &&
                  tagLine !== userDetails.tagLine
                ? tagLine
                : userDetails.tagLine,
            banner:
              !userDetails.banner && bannerFileKey
                ? bannerFileKey
                : userDetails.banner && !bannerFileKey
                ? userDetails.banner
                : userDetails.banner && bannerFileKey
                ? bannerFileKey
                : null,
            avatar:
              !userDetails.avatar && avatarFileKey
                ? avatarFileKey
                : userDetails.avatar && !avatarFileKey
                ? userDetails.avatar
                : userDetails.avatar && avatarFileKey
                ? avatarFileKey
                : null,
            socials:
              !userDetails.socials && socials
                ? JSON.stringify(socials)
                : JSON.stringify(socials) ===
                  JSON.stringify(userDetails.socials)
                ? JSON.stringify(userDetails.socials)
                : null
          }
        }
      })
      dispatch(setUserDetails(updateResult.data.updateUser))
    }
  }

  return (
    <>
      <MyProfilePage />
      <TooltipGlodal />
    </>
  )
}

export default ProfileEdit

import { useState, useEffect } from 'react'
import cn from 'classnames'
import styles from './Details.module.sass'
import Icon from '../../../Icon'
import Overview from './Overview'
// import { useContext } from "react";
// import { UserContext } from "../../../../UserContext";
import { generateClient } from "aws-amplify/api";

import {
  createLikes,
  deleteLikes,
  updatePosts
} from '../../../../graphql/mutations'
import { likesByPostIDAndUserID, getPosts } from '../../../../graphql/queries'
import { Amplify } from 'aws-amplify'
import amplifyConfig from '../../../../amplifyConfig'
import { useSelector } from 'react-redux'
import awsExports from "../../../../aws-exports";
import { is } from 'date-fns/locale'
const client = generateClient();
const navigation = ['Post', 'Comments']
Amplify.configure(awsExports);

const Details = ({
  item,
  likes,
  className,
  setValue,
  activeIndex,
  setActiveIndex,
}) => {
  const [isLiked, setIsLiked] = useState(false)
  const [like, setLike] = useState(likes)

  // const { userData } = useContext(UserContext);
  const userData = useSelector(state => state.user.userData)
  Amplify.configure(amplifyConfig)
  const client = generateClient()

  const handleClick = index => {
    setActiveIndex(index)
    index === 0 && setValue(false)
    index === 1 && setValue(true)
  }



 const handleLikeClick = async () => {
    try {
      const likesResponse = await client.graphql({
        query: likesByPostIDAndUserID,
        variables: {
          postID: item.id,
          userID: {
            eq: userData[0].id
          }
        }
      })

      if (likesResponse.data.likesByPostIDAndUserID.items.length === 0) {
        setIsLiked(true) 
        await client.graphql({
          query: createLikes, // Your mutation definition
          variables: {
            input: {
              postID: item.id,
              userID: userData[0].id
            }
          }
        })

        await client.graphql({
          query: updatePosts,
          variables: {
            input: {
              id: item.id,
              likes: like + 1,
              userID: userData[0].id,
              description: item.description
            }
          }
        })	


      } else {
        setIsLiked(false)
        if (like > 0) {
          await client.graphql({
            query: updatePosts,
            variables: {
              input: {
                id: item.id,
                likes: like - 1,
                userID: userData[0].id,
                description: item.description
              }
            }
          })

        }
        await client.graphql({
          query: deleteLikes,
          variables: {
            input: {
              id: likesResponse.data.likesByPostIDAndUserID.items[0].id
            }
          }
        })
       
      }
    } catch (error) {
      console.error('Error updating likes:', error)
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const subscription = await client.graphql({
          query: `
            subscription OnPostUpdate($postId: ID!) {
              onUpdatePosts(filter: { id: { eq: $postId } }) {
                id
                likes
              }  
            }
          `,
          variables: { postId: item.id },
        }).subscribe({
          next: ({ data }) => {
            const { onUpdatePosts } = data;
            const newLikes = onUpdatePosts.likes;
            setLike(newLikes);
          },
          error: (error) => {
            console.warn('Subscription error:', error);
          }
        });
  
        return () => subscription.unsubscribe();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [item.id]);
  

return (
  <div className={cn(styles.details, className)}>
    <div className={styles.head}>
      <div className={styles.nav}>
        {navigation.map((x, index) => (
          <button
            className={cn(styles.link, { [styles.active]: index === activeIndex })}
            onClick={() => handleClick(index)}
            key={index}
          >
            {x}
          </button>
        ))}
      </div>
      <div className={styles.btns}>
        <button
          onClick={handleLikeClick}
          className={cn('button-stroke', styles.favorite)}
          
        >
          <Icon name={isLiked ? 'heart-fill' : 'heart'} size='24' />
          <span>{like}</span>
        </button>
      </div>
    </div>
    <Overview item={item} />
  </div>
);
};

export default Details

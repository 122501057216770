import styles from "./Editor.module.sass";
import cn from "classnames";
import { Editor as ReactEditor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Tooltip from "../Tooltip";
import { useState } from "react";
// import { UserContext } from "../../UserContext";
// import { useContext } from "react";
import { useDispatch } from "react-redux";
import { setProductDescription } from "../../slices/postSlice";
const CommentsEditor = ({
    item,
    classEditor,
    label,
    classLabel,
    tooltip,
    place,
    onChange,
    button,
}) => {
    const dispatch = useDispatch();
    const [editorState, setEditorState] = useState(null);
    // const { userData } = useContext(UserContext);
    const handleEditorChange = (editorState) => {
        setEditorState(editorState);
        onChange(editorState.getCurrentContent().getPlainText()) 
		// dispatch(setProductDescription(editorState.getCurrentContent().getPlainText()))
    };


    return (
        <div className={cn(styles.editor, { [styles.editorButton]: button }, classEditor)}>
            {label && (
                <div className={cn(classLabel, styles.label)}>
                    {label}{" "}
                    {tooltip && (
                        <Tooltip
                            className={styles.tooltip}
                            title={tooltip}
                            icon="info"
                            place={place ? place : "right"}
                        />
                    )}
                </div>
            )}
            <ReactEditor
                editorState={editorState}
                toolbarClassName={styles.editorToolbar}
                wrapperClassName={styles.editorWrapper}
                editorClassName={styles.editorMain}
                onEditorStateChange={handleEditorChange}
                toolbar={{
                    options: ["inline", "emoji", "link", "list", "textAlign", "history"],
                    inline: { options: ["bold", "italic", "underline"] },
                    link: { options: ["link"] },
                    list: { options: ["unordered"] },
                    textAlign: { options: ["center"] },
                }}
            />
            {/* {button && (
                <button onClick={getCommentClick} className={cn("button-small", styles.button)}>
                    {button}
                </button>
            )} */}
        </div>
    );
};

export default CommentsEditor;

import { useState } from "react";
import cn from "classnames";
import styles from "./ProfileInformation.module.sass";
import Item from "../Item";
import Icon from "../../../components/Icon";
import TextInput from "../../../components/TextInput";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useSetting } from "../SettingsContext";
import {  getUrl } from "aws-amplify/storage";


const ProfileInformation = ({ className}) => {
  // Access settings context to update username and manage avatar
  const { setUsername, setAvatar } = useSetting()


  // State management for avatar upload and preview 
  const [loading, setLoading] = useState(false);
	const [avatarFileContent, setAvatarFileContent] = useState(null);
  const [avatarFileKey, setAvatarFileKey] = useState("");
  const userDetails = useSelector((state) => state.auth.userDetails);
  const [imgSrc, setImgSrc] = useState(userDetails.avatar ? `${process.env.REACT_APP_S3_PUBLIC}${userDetails.avatar}` : null)
  // Fetch auth data 
  // const {userDetails} = useAuth()

  // Placeholder component displayed while loading the user's avatar
  const AvatarSkeleton = () => (
    <div style={{
      width: '100px',
      height: '100px',
      backgroundColor: '#ccc',
      borderRadius: '50%',
      display: 'inline-block',
      opacity: 0.1,
    }}></div>
  );

  // Handles file selection for avatar upload
  const handleFileChange = (event) => {
    // ... Logic to get file, set preview, and generate upload key
    let file = event.target.files[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setImgSrc(fileUrl);
      setAvatarFileKey(`${userDetails.id}/${[
        "avatar",
        file.name.slice(
          file.name.lastIndexOf(".") +
            1
        ),
      ].join(".")}`
    );
      setAvatarFileContent(file);
    }
  };

  // Resets avatar to default  
  const handleRemove = async () => { 
    setImgSrc(null);
    setAvatarFileKey("");
    setAvatarFileContent(null); 
    setAvatar({ key: 'default', data: null }); 
    setLoading(false);
};

  // Fetch the user's existing avatar on component load // Run when userDetails changes 

  // Update avatar in SettingsContext when a new file is selected



  return (
      <Item
          className={cn(styles.card, className)}
          title="Profile information"
          classTitle="title-green"
      >
        <div className={styles.profile}>
          <div className={styles.avatar}>
            {loading ? <AvatarSkeleton /> : (
              <>
                {imgSrc && <img src={imgSrc} alt="Avatar" />}
                {!imgSrc && 	<div style={{ backgroundColor: `${userDetails?.theme}` }} className={`profile-img text-[28px] h-full rounded-full flex items-center justify-center`}>{userDetails?.username?.[0].toUpperCase()}</div>}
              </>
            ) }
            <button className={styles.remove} onClick={handleRemove}>
              <Icon name="close" />
            </button>
          </div>
          <div className={`${styles.file} transition-all bg-[#2A85FF] hover:bg-[#0069f6] rounded-xl cursor-pointer`}>
            <input type="file" className="cursor-pointer" onChange={handleFileChange} />
            <button className={cn("button", styles.button)} type="button">
              <Icon name="add" size="24" />
              <span>Upload new picture</span>
            </button>
          </div>
          <button className={cn("button-stroke", styles.button)} onClick={handleRemove}>Remove</button>
        </div>
        <div className={styles.fieldset}>
          <TextInput
              className={styles.field}
              label="Display name"
              name="display-name"
              type="text"
              tooltip="Maximum 100 characters. No HTML or emoji allowed"
              placeholder={userDetails?.username}

              onChange={(event) => setUsername(event.target.value)}
          />
          <TextInput
              className={styles.field}
              label="Email"
              name="email"
              type="email"
              value={userDetails?.email}
              tooltip="Maximum 100 characters. No HTML or emoji allowed"
              disabled
              // required
          />
    
        </div>
      </Item>
  );
};

export default ProfileInformation;

import { useState } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import Search from "./Search";
import PuffLoader from "react-spinners/PuffLoader";
import User from "./User";
// import { useAuth } from "../../AuthContext";
// import { generateClient } from "aws-amplify/api";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import CreatorModalDetail from "../Page/CreatorModal/CreatorModalDetail";
import Modal from "../Modal";
import { useSelector } from "react-redux";

const override = {
  marginRight: "24px",
  marginBottom: "-2px",
}; // PuffLoader style

const Header = ({ onOpen }) => {

  // const {
  // 	isAuthenticated,
  // 	isCreator,
  // } = useAuth();

  const userDetails = useSelector((state) => state.auth.userDetails);
  const isAuthenticated = useSelector((state) => state.auth.isAutheticated); // corrected typo in 'isAuthenticated'
  const isCreator = userDetails ? userDetails.isCreator : false;

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (userDetails) setIsLoading(false);
  }, [userDetails]);

  const [visible, setVisible] = useState(false);

  const handleClick = () => {
    onOpen();
    setVisible(false);
  };

  const handleButtonClick = () => {
    // addToGroup(); // Call addToGroup function
    setVisibleModal(true); // Set modal visibility to true
  };

  const [visibleModal, setVisibleModal] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const urlEndsWithStripe = location.pathname.endsWith('my/stripe');
    setVisibleModal(urlEndsWithStripe);
  }, [location]); // Update when the URL changes 

  return (
    <header className={styles.header}>
      <div className={styles.outer}>
        <Modal
          outerClassName={styles.outer}
          visible={visibleModal}
          onClose={() => setVisibleModal(false)}
        >
          <CreatorModalDetail ModalVisible={() => setVisibleModal(false)} />
        </Modal>
      </div>

      <button className={styles.burger} onClick={() => handleClick()}></button>
      <Search className={cn(styles.search, { [styles.visible]: visible })} />
      <button
        className={cn(styles.buttonSearch, {
          [styles.active]: visible,
        })}
        onClick={() => setVisible(!visible)}
      >
        <Icon name="search" size="24" />
      </button>
      {isCreator && isAuthenticated && (
        <div className={styles.control} onClick={() => setVisible(false)}>
          <PuffLoader
            color="#2A85FF"
            loading={isLoading}
            cssOverride={override}
            size={50}
            aria-label="Loading Spinner"
            speedMultiplier={1}
          />
          <Link
            className={`${cn("button", styles.button)}`}
            to="/my/create/post"
          >
            <div
              style={{
                border: "2px solid white",
                borderRadius: "50%",
                height: 35,
                display: "grid",
                placeItems: "center",
                marginRight: 8,
              }}
            >
              <svg
                width="15"
                height="15"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 1V9"
                  stroke="#EFEFEF"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M1 5L9 5"
                  stroke="#EFEFEF"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <span>Create a post</span>
          </Link>
          <User isLoaded={isLoading} />
        </div>
      )}
      {!isCreator && isAuthenticated && (
        <div className={styles.control} onClick={() => setVisible(false)}>
          <button
            className={`${cn("button_creator", styles.button)} ${isLoading ? styles.loading : styles.loading.loaded
              }`}
            onClick={handleButtonClick}
          // onClick={() =>
          // 	setIsBecomeCreatorVisible((prev) => !prev)
          // }
          >
            <Icon name="lightning" size="24" />
            <span>Become a Creator</span>
          </button>
          <User />
        </div>
      )}
      {!isAuthenticated && (
        <div className={styles.btns}>
          <Link className={styles.link} to="/my/sign-in">
            Sign in
          </Link>
          <Link className={cn("button", styles.button)} to="/my/sign-up">
            Sign up
          </Link>
        </div>
      )}
    </header>
  );
};

export default Header;

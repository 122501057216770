import  { useState } from 'react'
import cn from 'classnames'
import styles from './Overview.module.sass'
import ModalPreview from '../../../../ModalPreview'
import { useEffect } from 'react'
// import { UserContext } from '../../../../../UserContext'
import defaultImg from '../../../../../Default Images/banner.png'
import { useSelector } from 'react-redux'

const Overview = ({ item }) => {
  // const { userData, userAvatar } = useContext(UserContext)
  const userData = useSelector((state) => state.user.userData);


  const [visibleModalPreview, setVisibleModalPreview] = useState(false)
  const link = item.uploadContent
  const userVideoLink = item?.uploadContent
  return (
    <>
      <div className={styles.overview}>


        <div className={styles.gallery}>
          <div className={styles.preview} key={item.id}>
            {link && (
              <video controls controlsList="nodownload" className={cn(styles.video)}>
                <source src={`${process.env.REACT_APP_S3_PUBLIC}${userVideoLink}`} alt='Product' type='video/mp4' />
                Your browser does not support the video tag.
              </video>
            )}
          </div>

         
        </div>
       
        <div className={cn('h4', styles.title)}>{item.title}</div>
        
        <div className={styles.line}>
          <div className={styles.author}>
            <div className={styles.avatar}>
              <img src={`${process.env.REACT_APP_S3_PUBLIC}${userData[0].avatar}`} alt='Avatar' />
            </div>
            by <span>{userData[0].username}</span>
          </div>
         
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={cn('title-red', styles.subtitle)}>Description</div>
            <div className={styles.content}>
              <p>{item.description}</p>
             
            </div>
          </div>
         
        </div>
      </div>
      <ModalPreview
        visible={visibleModalPreview}
        onClose={() => setVisibleModalPreview(false)}
        title='Fleet - Travel shopping UI design kit'
        figcaption='Elegant product mockup for your next project'
        download
      />
    </>
  )
}

export default Overview

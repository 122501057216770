import { useEffect, useState, useRef } from "react";
import cn from "classnames";
import styles from "./Search.module.sass";
import Icon from "../../Icon";
import Item from "./Item";

import { generateClient } from "aws-amplify/api";

import { listUsers } from "../../../graphql/queries";




const Search = ({ className }) => {
	const searchBarRef = useRef(null);
	const [visible, setVisible] = useState(false);
	const [inputValue, setInputValue] = useState("")
	// const [visibleModalProduct, setVisibleModalProduct] = useState(false);
	const [searchKey, setSearchKey] = useState("");
	const [users, setUsers] = useState(null);
	const client = generateClient();
	useEffect(() => {
		(async () => {
			const ListUser = await client.graphql({
				query: listUsers,
			});
			setUsers(ListUser.data.listUsers.items);
		})();
	}, []);
	const handleOutsideClick = (event) => {  // when user click outside of the search box, it will close the search box
		if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
			setVisible(false);
			setInputValue("");
		}
	}
	
	useEffect(() => {
		document.addEventListener('click', (e) => handleOutsideClick(e));
		return () => {
			document.removeEventListener('click', (e) => handleOutsideClick(e));
		}
	}, [])

	return (
		<>
			<div
				ref={searchBarRef}
				className={cn(styles.search, className, {
					[styles.active]: visible,
				})}
			>
				<div className={styles.head}>
					<button className={styles.start}>
						<Icon name="search" size="24" />
					</button>
					<button className={styles.direction}>
						<Icon name="arrow-left" size="24" />
					</button>
					<input
						className={styles.input}
						type="text"
						placeholder="Search a user"
						value={inputValue}
						onChange={(event) => {
							setInputValue(event.target.value)
							setSearchKey(event.target.value);
							event.target.value !== "" ? setVisible(true) : setVisible(false);
						}}
					/>
					<button
						className={styles.close}
						onClick={(e) => {
							setVisible(false)
							setInputValue("")
						}}
					>
						<Icon name="close-circle" size="24" />
					</button>
				</div>
				<div className={styles.body}>
					<div className={styles.box}>
						<div className={styles.category}>Creators</div>
						<div className={styles.list}>
							{users
								?.filter(
									(user) =>
										user.username
											.toLowerCase()
											.includes(
												searchKey.toLowerCase()
											) && user.isCreator
								)
								?.map((x, index) => (
									<Item
										className={styles.item}
										item={x}
										key={index}
										onClick={() =>
											setVisible(false)
										}
										clearInputValue={() => setInputValue("")}

									/>
								))}
						</div>
					</div>
					<div className={styles.box}>
						<div className={styles.category}>Users</div>
						<div className={styles.list}>
							{users
								?.filter(
									(user) =>
										user.username
											.toLowerCase()
											.includes(
												searchKey.toLowerCase()
											) && !user.isCreator
								)
								?.map((x, index) => (
									<Item
										className={styles.item}
										item={x}
										key={index}
										onClick={() =>
											setVisible(false)
										}
										clearInputValue={() => setInputValue("")}
									/>
								))}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Search;

import { useState } from "react";
import styles from "./Control.module.sass";
import cn from "classnames";
import Icon from "../../Icon";
import ModalProduct from "../../ModalProduct";
import ModalProductEditPost from "../../ModalProductEditPost";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import DeletePosts from "./DeletePost/DeletePosts";
const Control = ({ item,className }) => {
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);
  const [visibleEditPosts, setVisibleEditPosts] = useState(false);
  const { pathname } = useLocation();
  const [showDeletePosts, setShowDeletePosts] = useState(false)
  const currentUser = useSelector((state) => state.auth.userDetails.username);
  let actions;

  if(currentUser === pathname.slice(1)){
    actions = [
      {
        icon: "edit", 
        action: () => setVisibleEditPosts(true),
      },
      {
        icon: "trash",
        action:()=> setShowDeletePosts(true)
      },
      {
        icon: "arrow-right",
        action: () => setVisibleModalProduct(true),
      },
    ]
  }else{
    actions = [
      {
        icon: "arrow-right",
        action: () => setVisibleModalProduct(true),
      },
    ]
  }

  return (
    <>
      <div className={cn(styles.control, className)}>
        {actions.map((x, index) => (
          <button className={styles.button} key={index} onClick={x.action && x.action}>
            <Icon name={x.icon} size="20" />
          </button>
        
     
        ))}
      </div>
      {showDeletePosts && <DeletePosts id={item.id} title={item.title} setShowDeletePosts={setShowDeletePosts}/>}
     { visibleEditPosts &&  <ModalProductEditPost
        item={item}
        visible={visibleEditPosts}
        onClose={() => setVisibleEditPosts(false)}
      />

     }
      
      <ModalProduct
        item={item}
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  );
};

export default Control;

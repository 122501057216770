import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // Default: local storage
import { authSlice } from "./slices/authSlice";
import { userSlice } from "./slices/userSlice";
import { postSlice } from "./slices/postSlice";
import { shopSlice } from "./slices/shopSlice";
import userSubSlice  from "./slices/userSubsSlice";
import { combineReducers } from "redux";

// Create your root reducer
const rootReducer = combineReducers({
  auth: authSlice.reducer,
  user: userSlice.reducer,
  post: postSlice.reducer,
  shop: shopSlice.reducer,
  userSubs: userSubSlice,
});

// Configure Redux Persist
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "user", "post", "shop"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the Redux store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Create the persistor
export const persistor = persistStore(store);

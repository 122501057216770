import React, { useEffect } from "react";
import cn from "classnames";
import styles from "./BannerModal.module.sass";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Amplify } from "aws-amplify";
import awsmobile from "../../../aws-exports";
import { generateClient } from "aws-amplify/api";
import File from "../../../components/File";
import { updateUser } from "../../../graphql/mutations";
import { uploadData } from "aws-amplify/storage";
import { updateBanner } from "../../../slices/authSlice";
import stylesSkeleton from "../../ProfilePage/ProfileSkeletonLoader/ProfileSkeleton.module.sass";
import { setUserBanner } from "../../../slices/userSlice";
import ReactCrop, { makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import ImagePreview from "../../CreatePost/UploadContent_Component/ImagePreview_Component";
const BannerModal = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state?.auth?.userDetails);
  Amplify.configure(awsmobile);
  const [crop, setCrop] = useState();
  const ASPECT_RATIO = 1;
  const MIN_DIMENSION = 200;

  const client = generateClient();
  // State management for banner file upload
  const [bannerFileName, setBannerFileName] = useState("Click or drop file");
  const [bannerFileContent, setBannerFileContent] = useState(null);
  const [bannerFileKey, setBannerFileKey] = useState("");
  const [imagePreview, setImagePreview] = useState();
  const [isSaving, setIsSaving] = useState(false); // State to track button disable
  const [showError, setShowError] = useState(false);
  const [errorMsg, seterrorMsg] = useState(""); // validation check for correct extension of image
  const [showImagePrev, setShowIMagePrev] = useState(true);
  const allowedFormats = ["jpg", "jpeg", "png", "gif", "apng", "webp"];
  // to disappear error after 3.5ms
  useEffect(() => {
    let timeoutId; // Automatically hide error messages after 2 seconds if there is an error
    if (showError) {
      timeoutId = setTimeout(() => setShowError(false), 3500);
    } else {
      setShowError(false); // Hide error messages immediately if there is no error
    }
    return () => {
      clearTimeout(timeoutId); //Clear the timeout when the component unmounts or when showError changes
    };
  }, [showError]);

  // Function to handle banner file selection
  async function saveSettings() {
    // Set loading state

    // Convert the loaded image to a Blob object

    try {
      // Upload banner image if selected
      if (bannerFileContent) {
        const fileExtension = bannerFileContent.name
          .split(".")
          .pop()
          .toLowerCase();
        if (!allowedFormats.includes(fileExtension)) {
          setShowError(true);
          seterrorMsg(
            "Invalid file format! Please select an image file with one of the following extensions: JPG, JPEG, PNG, GIF, APNG."
          );
        } else {
          setIsSaving(true);
          await uploadData({
            key: bannerFileKey,
            data: crop,
            options: {
              accessLevel: "public", // defaults to `guest` but can be 'private' | 'protected' | 'guest'
            },
          }).result;

          const updateResult = await client.graphql({
            query: updateUser,
            variables: {
              input: {
                id: userDetails.id,
                banner:
                  !userDetails.banner && bannerFileKey
                    ? bannerFileKey
                    : userDetails.banner && !bannerFileKey
                    ? userDetails.banner
                    : userDetails.banner && bannerFileKey
                    ? bannerFileKey
                    : null,
              },
            },
          });

          dispatch({
            type: "UPDATE_USER",
            payload: updateResult.data.updateUser,
          }); // Dispatch action to update user
          // window.location.reload()
          dispatch(updateBanner(updateResult.data.updateUser.banner)); // this will update in real time without reloading
          dispatch(setUserBanner(updateResult.data.updateUser.banner));
          window.location.reload();
          onclose();
          setIsSaving(false);
        }
      }

      // Upload avatar image if selected
    } catch (error) {}
  }
  // useEffect(()=>{
  //   setShowIMagePrev(true);
  // },[bannerFileContent])
  // console.log("cropdata", crop,showImagePrev)
  // ,"image")
  return (
    <>
      <div className={styles.details}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={cn("title-green", styles.title)}>Add Banner</div>
            <div className={styles.files}>
              {bannerFileContent && showImagePrev ? (
                <ImagePreview
                  imgSrc={URL.createObjectURL(bannerFileContent)}
                  updatedImgSrc={setCrop}
                  onClose={() => setShowIMagePrev(false)}
                  imagePreview={setImagePreview}
                />
              ) : (
                <File
                  className={styles.field}
                  title={bannerFileName}
                  label="Upload Banner image"
                  tooltip="Only image filetypes are allowed!"
                  onChange={(event) => {
                    let file = event.target.files[0];
                    if (file) {
                      const fileExtension = file.name
                        .split(".")
                        .pop()
                        .toLowerCase();
                      if (!allowedFormats.includes(fileExtension)) {
                        setShowError(true);
                        seterrorMsg(
                          "Invalid file format! Please select an image file with one of the following extensions: JPG, JPEG, PNG, GIF, APNG."
                        );
                        setShowIMagePrev(false);
                      } else {
                        setShowIMagePrev(true);
                      }
                      setBannerFileName(file.name);
                      let nameSplit = file.name.split(".");
                      nameSplit[0] = "banner";
                      setBannerFileKey(
                        `${userDetails.id}/${[
                          "banner",
                          file.name.slice(file.name.lastIndexOf(".") + 1),
                        ].join(".")}`
                      );
                      setBannerFileContent(file);
                    }
                  }}
                />
              )}
              {imagePreview && (
                <>
                  <p className="mt-4 mb-4">Preview</p>
                  <div className="">
                    <img src={imagePreview} alt="new" />

                    
                  </div>
                </>
              )}
            </div>
            <div className={styles.btns}>
              {showError && (
                <span className={styles.error}>
                  <br />

                  {errorMsg}
                </span>
              )}
              <button
                className={cn("button", styles.button)}
                onClick={saveSettings}
                disabled={isSaving}
              >
                {isSaving ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerModal;

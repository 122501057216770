import { useState } from "react";
import cn from "classnames";
import styles from "./Tags.module.sass";
import Card from "../../../components/Card";
import Tooltip from "../../../components/Tooltip"; 
import TagsInput from "./TagsInput.js";

const Tags = ({ className }) => {


  const [tagsCount, setTagsCount] = useState(0);  // Tracks added tag count
  return (
    <Card
      className={cn(styles.card, className)}
      title="Tags"
      classTitle="title-purple"
    >
      <div className={styles.images}>
        <div className={styles.head}>
          <div className={styles.label}>
            Tags{" "}
            <Tooltip
              className={styles.tooltip}
              title="Maximum 100 characters. No HTML or emoji allowed"
              icon="info"
              place="right"
            />
          </div>
          <div className={styles.counter}>
            <span>{tagsCount}</span>/12 tags {/* Displays tag count */}
          </div>
        </div>
        <div className={styles.tags}>
          <TagsInput onResetTagCount={() => setTagsCount(0)} // Resets after form submission?
            onTagsCountChange={(e) => {
              if (e) {
                setTagsCount(prevCount => prevCount + 1)
              } else {
                return; // dont do anything if tags limit is passed
              }
          }} />
        </div>
      </div>
    </Card>
  );
};

export default Tags;

import { useState, useEffect } from "react";
import cn from "classnames";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "../Product/Product.module.sass";
import Icon from "../Icon";

import Checkbox from "../Checkbox";
import { getUrl } from "aws-amplify/storage";

import Lock_img from "../Product/images/lock.svg";

import { useSelector } from "react-redux";
const ALLUserProduct = ({
  className,
  item,
  value,
  onChange,
  released,
  withoutСheckbox,
}) => {
  const [visible, setVisible] = useState(false);
  const subscriptions = useSelector((state) => state.userSubs.userSubscriptions);
  const [isLoading, setIsLoading] = useState(true);
  const handleClick = () => {
    onChange();
    setVisible(prev => !prev);
  };

  

  return (
    <div
      className={cn(styles.product, className, {
        [styles.active]: visible,
      })}
    >
      <ToastContainer position="top-center" theme="dark" />
      <div
        className={`${styles.preview} 
				`}
      >
        {!withoutСheckbox && (
          <Checkbox
            className={styles.checkbox}
            classCheckboxTick={styles.checkboxTick}
            value={value}
            onChange={() => handleClick()}
          />
        )}
        {!subscriptions.find(x => x.userId === item?.userId) && (
          <div className={styles.control}>
            <button>
              <img
                src={Lock_img}
                style={{ width: "40px", height: "40px", position: "relative" }}
                alt=""
              />
            </button>
          </div>
        )}
        <img
          src={`${process.env.REACT_APP_S3_PUBLIC}${item?.thumbnail}`}
          alt="Product"
          className={`${isLoading ? styles.loading : styles.loading.loaded}`}
        />
      </div>

      <div className={styles.title}>{item.title}</div>

      {released ? (
        <div className={styles.date}>
          <Icon name="clock" size="24" /> {item.date}
        </div>
      ) : item.likes ? (
        <div className={styles.rating}>
          <Icon name="heart-fill" size="24" />
          {item.likes}
        </div>
      ) : (
        <div className={cn(styles.rating, styles.ratingEmpty)}>
          <Icon name="heart" size="24" />
          No Likes
        </div>
      )}
    </div>
  );
};

export default ALLUserProduct;

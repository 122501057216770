import tagsStyles from "./Tags.module.sass";
import { useState, useRef } from "react";
const TagsInput = ({ onTagsCountChange, onResetTagCount }) => {
  const [tagInput, setTagInput] = useState("");  // state for holding the input value
  const [tags, setTags] = useState([]); // state for holding the tags
  const handleTagInput = (e) => {
    setTagInput(e.target.value);
  } // function to handle the input value
  const handleClearTags = () => {
    setTagInput("");
    setTags([]);
    onResetTagCount(); // call the onResetTagCount function to reset the tags count
  } // function to clear the tags and input value 
  const handleAddition = (tag) => {
    if (!tags.includes(tag) && tags.length !== 12) { // check if the tag already exists and we are not passing tag limit
      setTags([...tags, tag]);
      onTagsCountChange(tags.length < 12); // call the onTagsCountChange function to update the tags count
    } else if (tags.length === 12) { // if tags limit is passed show an alert message
      alert("The tags limit is 12")
    } else {
      alert("Tag already exists") // Alert the user if the tag already exists
    }
  } // function to add tags
  const handleInputFocus = () => {
    inputRef.current.focus();
  } // function to focus the input
  const handleDelete = (id) => {
    const newTags = tags.filter((_, i) => i !== id);
    setTags(newTags);
    onTagsCountChange(); // call the onTagsCountChange function to update the tags count
  }
  const inputRef = useRef(null);
  return (
    <div onClick={handleInputFocus} className={tagsStyles.tagsInput}>
      {tags.map((tag, i) => (
        <div key={i} className={tagsStyles.tagItem}>
          <span>{tag}</span>
          <button onClick={() => handleDelete(i)} className={tagsStyles.tagDeleteButton}>
            <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" title="HighlightOffSharp"><path d="M14.59 8 12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2m0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8"></path></svg>
          </button>
        </div>
      ))}
      <input
        ref={inputRef}
        value={tagInput}
        onChange={(e) => handleTagInput(e)} // call the handleTagInput function when the input value changes
        onKeyDown={(e) => {
          if (e.key === 'Enter' && tagInput.trim() !== '') {
            handleAddition(tagInput);
            setTagInput(''); // Clear the input after adding the tag
          } else if (e.key === "Enter" && tagInput.trim() === "") {
            alert("Please enter a tag") // Alert the user if the input is empty
          }
        }}
        placeholder="Enter tags to describe your item"
        className={tagsStyles.input}
      />
      {(tagInput.length > 0 || tags.length > 0) && (
        <button onClick={handleClearTags} className={tagsStyles.tagsClearAllButton}>
          <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" ><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg>
        </button> // if the input has a value or there are tags, show the clear button
      )}
    </div>
  )
}

export default TagsInput
import { useState } from "react";
import cn from "classnames";
import styles from "./SignUp.module.sass";
import { use100vh } from "react-div-100vh";
import { Link } from "react-router-dom";
import Entry from "./Entry";
import Code from "./Code";
import Image from "../../components/Image";

const items = [
  "Exclusive Content",
  "Pro Tips",
  "Fan-Creator Interactions",
  "Gaming Community",
];

const SignUp = () => {
  // State variables for managing the signup process
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [visible, setVisible] = useState(true); // Controls visibility of Entry/Code components

  // Calculate viewport height for dynamic container sizing
  const heightWindow = use100vh();

  return (
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.wrap}>
            <div className={cn("h4", styles.subtitle)}>OnlyGamers</div>
            <ul className={styles.list}>
              {items.map((x, index) => (
                  <li key={index}>{x}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className={styles.col} style={{ minHeight: heightWindow }}>
          <div className={styles.head}>
            <Link className={styles.logo} to="/">
              <Image
                  className={styles.pic}
                  src="/images/logo-light.svg"
                  srcDark="/images/logo-dark.svg"
                  alt="Core"
              />
            </Link>
            <div className={styles.info}>
              Already a member?{" "}
              <Link className={styles.link} to="/my/sign-in">
                Sign in
              </Link>
            </div>
          </div>
          <div className={styles.wrapper}>
            <div className={cn("h2", styles.title)}>Sign up</div>
            {/* Conditional rendering of Entry or Code component based on signup stage */}  
            {visible ? <Entry onConfirm={() => setVisible(false)} setEmail={setEmail} setUsername={setUsername} username={username} email={email}/> : <Code email={email} username={username} />}
          </div>
        </div>
      </div>
  );
};

export default SignUp;

import { useState } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { updateUser } from "../../../graphql/mutations.js";
import { generateClient } from 'aws-amplify/api';
import { setCreator, userDetails } from "../../../slices/authSlice";
import styles from './CreatorModal.module.sass'
export default function SelectPrice({ onPagination }) {
    const dispatch = useDispatch()
    const [value, setValue] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const userDetailsValue = useSelector(state => state.auth.userDetails);
    const client = generateClient();
    const handleChange = (e) => {
        const inputValue = e.target.value;
    
        const validInputRegex = /^[0-9]*\.?[0-9]*$/; 
        if (validInputRegex.test(inputValue) || inputValue === '') { // Accept empty input
            setError(null);
            if(inputValue.length === 1 && inputValue === '.') {
                setError('Please enter numbers and decimals only.');
                return;
            }
            setValue(inputValue);
            if (inputValue !== '') { 
                const number = Number(inputValue); 
                if (number < 4.99) {
                    setError("Minimum price is $4.99");
                } else if (number > 99) {
                    setError("Maximum price is $99");
                }
            }
        } else {
            setError("Please enter numbers and decimals only."); 
        }
    }; 
    
    
    const setPrice = async () => {
        try {
            setLoading(true)
            const response = await fetch(process.env.REACT_APP_ADD_PRODUCT, {
                method: 'POST',
                body: JSON.stringify({ userId: userDetailsValue?.id, name: userDetailsValue?.username, price: Number(value), description: "Default desc" }),
                headers: { "Content-Type": "application/json" },
            }
            )
            const data = await response.json()
           
        } catch (error) {
            console.error('Error creating Product:', error);
            console.error('Original Error Message:', error.message); // More details
            if (error.code) { 
                console.error('Error Code:', error.code);        // Network error code, timeout etc.
            }
            throw new Error(`Error creating Product`, error); 
        } finally {
            setLoading(false);
            async function addToGroup() {
        
                let options = {
                    method: "POST",
                    body: JSON.stringify({
                        userId: userDetailsValue.id
                    }),
        
                };
        
                fetch(
                    "https://ktguq45gwn4rzznxdwwicochh40tlwlx.lambda-url.eu-north-1.on.aws/",
                    options
                )
                    .then(async (response) => {
                        const updateResult = await client.graphql({
                            query: updateUser,
                            variables: {
                                input: {
                                    id: userDetailsValue.id,
                                    isCreator: true,
                                    email: userDetailsValue.email,
                                    creator_price: value,
                                },
                            },
                        });
                        dispatch(userDetails(updateResult.data.updateUser));
                    })
                    .then(() => {
                        dispatch(setCreator(true));
                    })
            }
            addToGroup();
        }
    }
    return (
        <div className='modalBody__container flex flex-col gap-6'>
            <header className="modalBody__header flex items-center">
                <div className="left flex items-center gap-4">
                    <span className='box block w-4 h-8 rounded bg-[#CABDFF]'></span>
                    <p className={`font-semibold ${styles.subText} text-xl`}>Set your monthly price</p>
                </div>

            </header>
            <div className={`p-6 ${styles.body} rounded-lg flex flex-col gap-8`}>
                <header className="flex items-center gap-4">
                    <span className="box block w-4 h-8 rounded bg-[#B5E4CA]"></span>
                    <p className={`${styles.subText} font-semibold font-xl`}>Select price</p>
                </header>
                <div className="flex flex-col items-center gap-3">
                    <div className="price-details w-full">
                        <div className="details-1 text-[#6F767E] text-sm flex items-center justify-between">
                            <p className="flex items-center gap-1">Select your sub price <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6673 6.99992C13.6673 10.6818 10.6825 13.6666 7.00065 13.6666C3.31875 13.6666 0.333984 10.6818 0.333984 6.99992C0.333984 3.31802 3.31875 0.333252 7.00065 0.333252C10.6825 0.333252 13.6673 3.31802 13.6673 6.99992ZM7.00065 6.33325C7.36884 6.33325 7.66732 6.63173 7.66732 6.99992V10.3338C7.66732 10.702 7.36884 11.0005 7.00065 11.0005C6.63246 11.0005 6.33398 10.702 6.33398 10.3338V6.99992C6.33398 6.63173 6.63246 6.33325 7.00065 6.33325ZM7.00065 4.99992C7.36884 4.99992 7.66732 4.70144 7.66732 4.33325C7.66732 3.96506 7.36884 3.66659 7.00065 3.66659C6.63246 3.66659 6.33398 3.96506 6.33398 4.33325C6.33398 4.70144 6.63246 4.99992 7.00065 4.99992Z" fill="#9A9FA5" />
                            </svg>
                            </p>
                            <span>$4.99-$99</span>
                        </div>
                        <div className={`details-2 p-1 rounded-xl flex items-center gap-2 ${styles.pricetag} `}>
                            <div className={`box  w-10 h-10 rounded-lg flex justify-center items-center  ${styles.subText} ${styles.dollarBack}`}>
                                $
                            </div>
                            <input value={value} onChange={handleChange} required type="text" className="h-full rounded-lg  w-full bg-transparent" />
                        </div>
                        {error && (
                            <p className="text-red-500">{error}</p>
                        )}
                    </div>
                    <button disabled={value.length === 0 || loading || error} className="w-full h-12 rounded-xl bg-[#2A85FF]" onClick={() => {
                        onPagination()
                        setPrice()
                    }} >{loading ? "Confirming..." : "Confirm"}</button>

                </div>
            </div>
        </div>
    )
}

import { useEffect } from 'react'
import cn from 'classnames'
import styles from './Entry.module.sass'
import TextInput from '../../../components/TextInput'
import Image from '../../../components/Image'
import { signUp } from 'aws-amplify/auth'
import { useState } from 'react'
import { generateClient } from 'aws-amplify/api'
import { userByUsername, userByEmail } from '../../../graphql/queries'
import awsExports from '../../../aws-exports'
import { Amplify } from 'aws-amplify'
import { signInWithRedirect } from 'aws-amplify/auth'

const Entry = ({ onConfirm, setEmail, email, setUsername, username }) => {
  // State variables for form fields and error handling
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [attribDetail, setAttribDetail] = useState('')
  const [showError, setShowError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  Amplify.configure(awsExports)
  // GraphQL client setup for user checks
  const client = generateClient()
  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  function handleSignInClick(customState) {
    signInWithRedirect({
      provider: "Google",
      customState
    });
  }
  async function handleSignUp(event) {
    event?.preventDefault(); // Prevent default form submission
    setIsLoading(true);
  
    // Client-side input validation
    if (confirmPassword !== password) {
      setAttribDetail('Please make sure the passwords match!');
      setShowError(true);
      setIsLoading(false); // Reset loading state if validation fails
      return; // Exit the function if validation fails
    }
  
    // Check for empty username
    if (username.trim() === '') {
      setAttribDetail('Username is required to signUp');
      setShowError(true);
      setIsLoading(false); // Reset loading state if validation fails
      return; // Exit the function if validation fails
    }
  
    // Attempt signup with AWS Amplify if input is valid
    try {
      setShowError(false);
      await signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            email,
          },
          autoSignIn: false,
        },
      });
      onConfirm(); // Move to the code verification stage
    } catch (error) {
      console.error(error); // Handle potential signup errors
      setAttribDetail(error.message);
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className={styles.entry}>
      <div className={styles.head}>
        <div className={styles.info}>Sign up with Open account</div>
        <div className={styles.btns}>
          <button onClick={()=>handleSignInClick()} className={cn('button-stroke', styles.button)}>
            <img src='/images/content/google.svg' alt='Google' />
            Google
          </button>
          {/* <button className={cn('button-stroke', styles.button)}>
            <Image
              className={styles.pic}
              src='/images/content/apple-dark.svg'
              srcDark='/images/content/apple-light.svg'
              alt='Apple'
            />
            Apple ID
          </button> */}
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.info}>
          Or continue with email address
          {showError && (
            <span className={styles.error}>
              <br />
              {attribDetail}
            </span>
          )}
        </div>
        <form onSubmit={handleSignUp}>
          <TextInput
            className={styles.field}
            name='email'
            type='email'
            placeholder='Your email'
            required
            icon='mail'
            onChange={event => setEmail(event.target.value)}
          />
          <TextInput
            className={styles.field}
            name='username'
            type='string'
            placeholder='Username'
            required
            icon='username'
            onChange={event => setUsername(event.target.value)}
          />
          <TextInput
            className={styles.field}
            name='password'
            type={showPassword ? 'text' : 'password'} 
            placeholder='Your password'
            required
            icon='lock'
            onChange={event => setPassword(event.target.value)}
          />
          <TextInput
            className={styles.field}
            name='confirmPassword'
            type={showPassword ? 'text' : 'password'} 
            placeholder='Confirm password'
            required
            icon='lock'
            onChange={event => setConfirmPassword(event.target.value)}
          />
          <div class="flex items-center gap-2 mb-4 mt-4 ">
              <input className={`${styles.showPassword} w-4 h-4 focus:ring-0 rounded focus:outline-none`} type="checkbox" value={showPassword} onClick={togglePasswordVisibility}/>
              <label  >Show Password</label>
          </div>

          <button disabled={isLoading} className={cn('button', styles.button)}>
            {isLoading ? "processing.." : "Continue"}
          </button>
        </form>
        <div className={styles.note}>
          This site is protected by reCAPTCHA and the Google Privacy Policy.
        </div>
      </div>
    </div>
  )
}

export default Entry

import { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./User.module.sass";
import Icon from "../../Icon";
import { signOut } from "aws-amplify/auth";
// import { useAuth } from "../../../AuthContext";
import { reduxLogout } from "../../../slices/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { Amplify } from "aws-amplify";
import awsExports from "../../../aws-exports";
import storage from "redux-persist/lib/storage";
const items = [
  {
    menu: [
      {
        title: "My Profile",
        url: "/shop",
      },
    ],
  },
  {
    menu: [
      {
        title: "Account Settings",
        url: "/my/settings",
      },
      {
        title: "Log Out",
      },
    ],
  },
];

const User = ({ className }) => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state?.auth?.userDetails);
  const [visible, setVisible] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  Amplify.configure(awsExports);
  //   const { setIsAuthenticated, userDetails } = useAuth()
  const userAvatar = useSelector((state) => state?.auth?.userDetails?.avatar)

  const handleSignOut = async () => {
    try {
      await signOut();
      dispatch(reduxLogout())
      storage.removeItem('persist:root')
    } catch (error) {
    } finally {
      setVisible(false);
      navigate("/")

    }
  };




  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(styles.user, className, {
          [styles.active]: visible,
        })}
      >
        <button
          className={`${styles.head}`}
          onClick={() => setVisible(!visible)}
        >
          {userAvatar ? (
            <img src={`${process.env.REACT_APP_S3_PUBLIC}${userAvatar}`} alt="Avatar" />
          ) : (
            <div style={{ backgroundColor: `${userDetails?.theme}` }} className={`profile-img text-[18px] h-full rounded-full flex items-center justify-center`}>{userDetails?.username?.[0]?.toUpperCase()}</div>
          )}
        </button>

        <div className={styles.body}>
          {items.map((item, index) => (
            <div className={styles.menu} key={index}>
              {item.menu.map((x, index) =>
                x.url ? (
                  <NavLink
                    className={cn(styles.item, {
                      [styles.color]: x.color,
                      [styles.active]: pathname === x.url,
                    })}
                    to={
                      x.title === "My Profile"
                        ? `/${userDetails?.username}`
                        : x.url
                    }
                    onClick={() => setVisible(false)}
                    key={index}
                  >
                    {x.icon && <Icon name={x.icon} size="24" />}
                    {x.title}
                  </NavLink>
                ) : (
                  <button
                    className={styles.item}
                    onClick={handleSignOut}
                    key={index}
                  >
                    {x.title}
                  </button>
                )
              )}
            </div>
          ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default User;

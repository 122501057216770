import styles from "./BugReport.module.sass";
import cn from "classnames";
import Card from "../../components/Card";
import { Link, useNavigate } from "react-router-dom";
import Icon from "../../components/Icon";
import TextInput from "../../components/TextInput";
import Editor from "../../components/Editor";
import File from "../../components/File";
import { useState } from "react";
import awsExports from "../../aws-exports";
import CommentsEditor from "../../components/CommentsEditor";
import { createReportBug, updateReportBug } from "../../graphql/mutations";
import amplifyConfig from "../../amplifyConfig";
import { Amplify } from "aws-amplify";
import { generateClient } from "aws-amplify/api";
import { uploadData } from "aws-amplify/storage";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
function BugReport() {
  Amplify.configure(amplifyConfig);
  const navigate = useNavigate();
  Amplify.configure(awsExports);
  const client = generateClient();
  const [email, setEmail] = useState();
  const [description, setDescription] = useState();
  const [isLoading, setIsloading] = useState(false);
  const [thumbnailPreview, setThumbnailPreview] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [fileName, setFileName] = useState("Click or drop image");
  const [thumbnail, setThumbnail] = useState();
  const handleFileChange = (event) => {
    const allowedFormats = ["jpg", "jpeg", "png", "gif", "apng", "webp"];
    let file = event.target.files[0];
    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();

      if (!allowedFormats.includes(fileExtension)) {
        setShowError(true);
        seterrorMsg(
          "Invalid file format! Please select an image file with one of the following extensions: JPG, JPEG, PNG, GIF, APNG."
        );
        setThumbnailPreview("");
        setFileName("Click or drop image");
      } else {
        setFileName(file.name);

        setThumbnail(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setThumbnailPreview(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleSubmit = async () => {
    setIsloading(true);
    const createResult = await client.graphql({
        query: createReportBug,
        variables: {
          input: {
            userEmail: email,
            description: description,
          },
        },
      });

    try {
     
      const vid = await uploadData({
        key: `${createResult.data.createReportBug.id}/${fileName}`,
        data: thumbnail,

        options: {
          accessLevel: "guest", // defaults to `guest` but can be 'private' | 'protected' | 'guest'
        },
      }).result;
    //  console.log(vid,"vid data")
    } catch (error) {
      seterrorMsg(error.message);
    }finally {
        // Update the post record with the storage keys
        await client.graphql({
            query: updateReportBug,
            variables: {
                input: {
                    id: createResult.data.createReportBug.id,
                    userEmail:email,
                    description:description,
                    image: `${createResult.data.createReportBug.id}/${fileName}`,

                },
            },
        });
        setIsloading(false);
        navigate(`/`)  // Redirect to the user's profile page
    }
  };
  return (
    <>
      <Card
        className={cn(styles.card)}
        title="Report Bug "
        classTitle="title-green"
        head={
          <Link
            className={cn("button-stroke button-small", styles.button)}
            to="/"
          >
            <Icon name="arrow-left" size="24" />
            <span>Back</span>
          </Link>
        }
      >
        <div className="flex flex-col gap-8">
          <TextInput
            className={styles.field}
            label="Email"
            name="email"
            type="email"
            onChange={(event) => setEmail(event.target.value)}
            required
          />
          <CommentsEditor
            onChange={setDescription}
            classEditor={styles.editor}
            label="Description"
            tooltip="Description"
            name="description"
          />
          <File
            className={styles.field}
            title={fileName}
            label="Thumbnail image"
            tooltip="Maximum 100 characters. No HTML or emoji allowed"
            onChange={handleFileChange}
          />
          {thumbnailPreview && (
            <div className={styles.imagePreview}>
              <img src={thumbnailPreview} alt="Thumbnail Preview" />
            </div>
          )}{" "}
          {showError && (
            <span className={styles.error}>
              <br />

              {errorMsg}
            </span>
          )}
        </div>
      </Card>
      <div className={cn("panel", styles.panel)}>
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <div className={styles.btns}>
            <button
              className={cn("button", styles.button)}
              onClick={handleSubmit}
            >
              Report now
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default BugReport;

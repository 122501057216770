import React, { useEffect, useState } from "react";
import styles from "./UsernameModal.module.sass";
import cn from "classnames";
import { TextInput } from "flowbite-react";
import { updateUser } from "../../../graphql/mutations";
import { generateClient } from "aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import { Amplify } from "aws-amplify";
import awsmobile from "../../../aws-exports";
import { useNavigate } from "react-router";
import { userByUsername } from "../../../graphql/queries";
import { updateUsername } from "../../../slices/authSlice";
const UsernameModal = () => {
  const [username, setUsername] = useState("");
  const [proceed, setProceed] = useState(false);
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const client = generateClient();
  const [error, setError] = useState(null);
  Amplify.configure(awsmobile);
  const userDetails = useSelector((state) => state?.auth?.userDetails);
  useEffect(() => {
    let timeoutId; // Automatically hide error messages after 2 seconds if there is an error
    if (showError) {
      timeoutId = setTimeout(() => setShowError(false), 3500);
    } else {
      setShowError(false); // Hide error messages immediately if there is no error
    }
    return () => {
      clearTimeout(timeoutId); //Clear the timeout when the component unmounts or when showError changes
    };
  }, [showError]);
  useEffect(() => {
    if (proceed) {
       window.location.href = "/";
    }
  }, [proceed]);
  async function saveSettings() {
    setIsSaving(true);
    try {
      const exists = await checkUsernameExists(username);
     setError("Username already exist . Please select different username")
      if (exists) {
        setProceed(false);
        setShowError(true);
        // setError("Username already exists. Please choose another username.");
      } else {
        const updateResult = await client.graphql({
          query: updateUser,
          variables: {
            input: {
              id: userDetails.id,
              username:
                userDetails.username === "" && username
                  ? username
                  : userDetails.username !== "" &&
                    username &&
                    username !== userDetails.username
                  ? username
                  : userDetails.username,
            },
          },
        });
        // navigate("/");
         
        dispatch(updateUsername(updateResult.data.updateUser.username));
        setProceed(true); // to proceed to home page after correct username is selected
      }

      // navigate("/");
    } catch (error) {
    
    } finally {
      setIsSaving(false);
    }
  }
  async function checkUsernameExists(Username) {
    try {
      const usernameCheck = await client.graphql({
        query: userByUsername,
        variables: {
          username: Username,
        },
      });
      const usernameExists =
        usernameCheck.data.userByUsername.items.length === 0;
    
      return !usernameExists; // Return true if the username doesn't exist
    } catch (err) {
    
      return false;
    }
  }
  return (
    <div className={styles.details}>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={cn("title-blue", styles.title)}>
            Select Your Username
          </div>
          <div className={styles.files}>
            <TextInput
              className={styles.field}
              label="Set Tag line"
              name="title"
              type="text"
              tooltip="Maximum 100 characters. No HTML or emoji allowed"
              onChange={(event) => setUsername(event.target.value)}
              required
            />

            {showError && (
              <span className={styles.error}>
                <br />
                {error}
              </span>
            )}
          </div>
          <div className={styles.btns}>
            {" "}
            <button
              className={cn("button", styles.button)}
              onClick={saveSettings}
              disabled={isSaving}
            >
              {isSaving ? (
                <>
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline w-4 h-4 me-3 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                  Loading...
                </>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsernameModal;

import "./styles/app.sass";
import { useEffect } from "react";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { generateClient } from "aws-amplify/api";
import { userByEmail } from "./graphql/queries";
import { RouterProvider } from "react-router";
import { reduxLogout, userDetails as setUserDetails } from "./slices/authSlice";
import "./global.css";
import { useDispatch, useSelector } from "react-redux";
import router from "./router";
import { getCurrentUser } from "aws-amplify/auth";
import { fetchUserAttributes } from "aws-amplify/auth";
import { updateUser } from "./graphql/mutations";
import { fetchUserData } from "./slices/userSubsSlice";
import RandomColor from "./frontendFunctions/RandomColor";
import storage from "redux-persist/lib/storage";
function App() {
  const dispatch = useDispatch();
  const client = generateClient();
  Amplify.configure(awsExports);
  const isAuthenticated = useSelector((state) => state.auth.isAutheticated);
  const userDetails = useSelector((state) => state.auth.userDetails);


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (isAuthenticated) {
            const authUser = await getCurrentUser();

          
          // If the user signed in using Google, the email cannot be directly fetched from getCurrentUser(),
          // so we use fetchUserAttributes() to get the Google email.
          const googleEmail = await fetchUserAttributes();
          const { signInDetails } = authUser;
          try {
            const Useremail =
              signInDetails && signInDetails.loginId
                ? signInDetails.loginId
                : googleEmail.email;
            const listResult = await client.graphql({
              query: userByEmail,
              variables: { email: Useremail },
            });
            dispatch(setUserDetails(listResult.data.userByEmail.items[0]));
          } catch (err) {
           
            console.error(err);
          }
        }
      } catch (error) {
        if(error.message.includes("User needs to be authenticated"))
        {  dispatch(reduxLogout())
          storage.removeItem('persist:root')
        }
        console.error(error.message,"errors");
      }
    };
    // Call fetchUserData inside useEffect
    fetchUserData();
  }, [isAuthenticated, dispatch]);
  useEffect(() => {
    if (isAuthenticated) dispatch(fetchUserData())
  }, [isAuthenticated])
  useEffect(() => {
    if (isAuthenticated && !userDetails?.theme) {
      const color = RandomColor();
      const updateUserTheme = async () => {
        try {
          const updateResult = await client.graphql({
            query: updateUser,
            variables: {
              input: {
                id: userDetails.id,
                theme: color
              },
            },
          });
          dispatch(setUserDetails(updateResult.data.updateUser));
        } catch (err) {
          console.error(err);
        }
      }
      updateUserTheme();
    }
  }, [isAuthenticated, userDetails.id]);


  return (
    <RouterProvider router={router} />
  );
}

export default App;

import React from "react";
import cn from "classnames";
import styles from "../BannerModal/BannerModal.module.sass";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Amplify } from "aws-amplify";
import awsmobile from "../../../aws-exports";
import { generateClient } from "aws-amplify/api";
import File from "../../../components/File";
import { updateUser } from "../../../graphql/mutations";
import { uploadData } from "aws-amplify/storage";
import { UpdateAvatar } from "../../../slices/authSlice";
import { useEffect } from "react";
import AvatarPreview from "./AvatarPreview";
const AvatarModal = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state?.auth?.userDetails);
  Amplify.configure(awsmobile);
  const client = generateClient();
  // State management for banner file upload
  const [avatarFileName, setAvatarFileName] = useState("Click or drop file");
  const [avatarFileContent, setAvatarFileContent] = useState(null);
  const [avatarFileKey, setAvatarFileKey] = useState("");
  const [isSaving, setIsSaving] = useState(false); // State to track button disable
  const [showError, setShowError] = useState(false);
  const [errorMsg, seterrorMsg] = useState(""); // validatiob check for correct extension of image
  const [showImagePrev,setShowIMagePrev] = useState(true);
  const allowedFormats = ["jpg", "jpeg", "png", "gif", "apng", "webp"];
  const [crop, setCrop] = useState()
  useEffect(() => {
    let timeoutId; // Automatically hide error messages after 2 seconds if there is an error
    if (showError) {
      timeoutId = setTimeout(() => setShowError(false), 3500);
    } else {
      setShowError(false); // Hide error messages immediately if there is no error
    }
    return () => {
      clearTimeout(timeoutId); //Clear the timeout when the component unmounts or when showError changes
    };
  }, [showError]);

  // Function to handle banner file selection
  async function saveSettings() {
    
    try {
      // Upload banner image if selected
      if (avatarFileContent) {
        const fileExtension = avatarFileContent.name
          .split(".")
          .pop()
          .toLowerCase();
        if (!allowedFormats.includes(fileExtension)) {
          setShowError(true);
          seterrorMsg(
            "Invalid file format! Please select an image file with one of the following extensions: JPG, JPEG, PNG, GIF, APNG."
          );
        } else {
          setIsSaving(true);
          await uploadData({
            key: avatarFileKey,
            data: crop,
            options: {
              accessLevel: "public", // defaults to `guest` but can be 'private' | 'protected' | 'guest'
            },
          }).result;
         
          const updateResult = await client.graphql({
            query: updateUser,
            variables: {
              input: {
                id: userDetails.id,
                avatar:
                  !userDetails.avatar && avatarFileKey
                    ? avatarFileKey
                    : userDetails.avatar && !avatarFileKey
                    ? userDetails.avatar
                    : userDetails.avatar && avatarFileKey
                    ? avatarFileKey
                    : null,
              },
            },
          });
          
          dispatch({
            type: "UPDATE_USER",
            payload: updateResult.data.updateUser,
          }); // Dispatch action to update user
          dispatch(UpdateAvatar(updateResult.data.updateUser.avatar)); // this will update in real time without reloading
          // window.location.reload() // Reload the window
          window.location.reload();
          onclose()
          setIsSaving(false);
        }
      }

      // Upload avatar image if selected
    } catch (error) {
     
    } 
  }

  return (
    <>
      <div className={styles.details}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={cn("title-green", styles.title)}>Add Avatar</div>
            <div className={styles.files}>
            {avatarFileContent&&showImagePrev?(
            <AvatarPreview imgSrc={URL.createObjectURL(avatarFileContent)} updatedImgSrc={setCrop} onClose={()=>setShowIMagePrev(false)}/>
            ):
              <File
                className={styles.field}
                title={avatarFileName}
                label="Upload Avatar image"
                tooltip="Only image filetypes are allowed!"
                onChange={(event) => {
                  let file = event.target.files[0];
                  if (file) {
                    const fileExtension = file.name
                    .split(".")
                    .pop()
                    .toLowerCase();
                    if (!allowedFormats.includes(fileExtension)) {
                      setShowError(true);
                      seterrorMsg(
                        "Invalid file format! Please select an image file with one of the following extensions: JPG, JPEG, PNG, GIF, APNG."
                      );
                      setShowIMagePrev(false)
                    }
                    else{
                      setShowIMagePrev(true)
                    }
                    setAvatarFileName(file.name);
                    setAvatarFileKey(
                      `${userDetails.id}/${[
                        "avatar",
                        file.name.slice(file.name.lastIndexOf(".") + 1),
                      ].join(".")}`
                    );
                    setAvatarFileContent(file);
                  }
                }}
              />}
            </div>
            <div className={styles.btns}>
              {showError && (
                <span className={styles.error}>
                  <br />

                  {errorMsg}
                </span>
              )}
              <button
                className={cn("button", styles.button)}
                onClick={saveSettings}
                disabled={isSaving} // Disable the button when isSaving is true
              >
                {isSaving ? "Saving..." : "Save"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AvatarModal;

import { useEffect, useState } from 'react';
import { confirmSignUp } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { generateClient } from 'aws-amplify/api';
import { createUser } from '../../../graphql/mutations';
import TextInput from '../../../components/TextInput';
import { signInRedux, setUserEmail } from '../../../slices/authSlice';
import cn from 'classnames';
import styles from './Code.module.sass';
import Loader from '../../../components/Loader';

const Code = ({ email, username }) => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [confirmationCode, setConfirmationCode] = useState('');
	const [error, setError] = useState(null);
	const navigate = useNavigate();
	const client = generateClient();


	

	async function handleSignUpConfirmation(event) {
		event.preventDefault();

		try {
			setIsLoading(true);
			await confirmSignUp({
				username: email,
				confirmationCode,
			});

			await client.graphql({
				query: createUser,
				variables: { input: { email: email, username: username } },
			});

			navigate('/my/sign-in');
		} catch (error) {
			setError(error);
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<div className={styles.code}>
			<div className={styles.body}>
				<div className={styles.info}>
					We just sent you a verification code. Check your inbox to get it.
				</div>
				<form onSubmit={handleSignUpConfirmation}>
					<div className={styles.fieldset}>
						<TextInput
							className={styles.field}
							name="code"
							type="code"
							placeholder="Verification Code"
							required
							icon="lock"
							onChange={(event) => {
								if (error && event.target.value.trim().length > 0) {
									setError(null);
								}
								setConfirmationCode(event.target.value.trim());
							}}
						/>
					</div>
					{error && confirmationCode.trim().length > 0 && <div className={styles.errorNote}>
						The code you entered is incorrect.
					</div>}
					<button disabled={isLoading} className={cn('button', styles.button)}>
						<Loader className={styles.loader} white />
						<span>{isLoading ? 'Loading...' : 'Continue'}</span>
					</button>
				</form>
				<div className={styles.note}>
					This site is protected by reCAPTCHA and the Google Privacy Policy.
				</div>
			</div>
		</div>
	);
};

export default Code;

import { useState,useEffect } from 'react'
import styles from './Home.module.sass'
import Dropdown from '../../components/Dropdown'
import Card from '../../components/Card'
import cn from 'classnames'
import shopstyles from '../ProfilePage/ProfilePage.module.sass'
import { listUsers } from '../../graphql/queries'
import { generateClient } from 'aws-amplify/api'
import UserDisplay from '../../components/UserDisplay/index'
const Home = () => {
  // State for navigation, sorting, and fetched creators
  const intervals = ['Most recent', 'Trending', 'Most popular']
  const navigation = ['Discover']
  const [activeIndex, setActiveIndex] = useState(0)
  const [sorting, setSorting] = useState(intervals[0])
   const [creators, setCreators] = useState([])
  const client = generateClient()

  useEffect(() => {
    ;(async () => {
      const Users = await client.graphql({
        query: listUsers,
        variables: {
          filter: {
            isCreator: {
              eq: true
            },
            username: {
              eq: 'Swant0n'
            }
          }
        }
      })
      setCreators(Users.data.listUsers.items) // all users who are creators
    })()
  }, [])


  return (
    <>
      <Card className={styles.card}>
        <div className={shopstyles.control}>
          <div className={shopstyles.nav}>
            {navigation.map((x, index) => (
              <button
                className={cn(shopstyles.link, {
                  [shopstyles.active]: index === activeIndex
                })}
                onClick={() => setActiveIndex(index)}
                key={index}
              >
                {x}
              </button>
            ))}
          </div>

          <div className={shopstyles.dropdownBox}>
            <Dropdown
              className={shopstyles.dropdown}
              classDropdownHead={shopstyles.dropdownHead}
              value={sorting}
              setValue={setSorting}
              options={intervals}
              small
            />
          </div>
        </div>
        {activeIndex === 0 &&
          creators.map((creator, index) => (
            <UserDisplay creator={creator} key={index} /> // passing creators user
          ))}
      </Card>
    </>
  )
}

export default Home

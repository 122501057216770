import { useEffect, useState } from 'react'
import cn from 'classnames'
import styles from './Comments.module.sass'
import Icon from '../../../Icon'
import Item from './Item'
import TooltipGlodal from '../../../TooltipGlodal'
import Editor from '../../../Editor'
import { useSelector } from 'react-redux'
import { createComments } from '../../../../graphql/mutations'
import { generateClient } from 'aws-amplify/api'
import { Amplify } from 'aws-amplify'
import amplifyConfig from '../../../../amplifyConfig'
import awsExports from '../../../../aws-exports'
import CommentsEditor from '../../../CommentsEditor'
import { listComments } from '../../../../graphql/queries'
// import { useContext } from 'react'
// import { UserContext } from '../../../../UserContext'

const Comments = ({ item, className, onClose }) => {
  // const { Allcomments, setAllcomments } = useContext(UserContext)
  Amplify.configure(amplifyConfig)
	Amplify.configure(awsExports);

	const client = generateClient();
  const [isCommentPosted, setIsCommentPosted] = useState(false);
  const [allcomments,setAllComments] = useState([])
  const userId = useSelector(state=>state.auth.userDetails.id);
  const [content, setContent] = useState('')
  const [isLoading,setIsloading] = useState(false);
  const[showErrorMessage,setShowErrorMessage]= useState();
  console.log(userId,"userID",content,item.id)
  const handleInputChange = (newValue) => {
    setContent(newValue);
  };
  
  const fetchComments = async () => {
    try {
      const { data } = await client.graphql({
        query: listComments,
        variables: {
          filter: { postID: { contains: `${item.id}` } },
        },
      });
  
      setAllComments(data.listComments.items);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };

  useEffect(() => {
    fetchComments(); // Fetch comments on page render
  }, [item.id]);
  
  useEffect(() => {
    if (isCommentPosted) {
      fetchComments(); // Fetch comments only if a comment has been successfully posted
      setIsCommentPosted(false); // Reset the flag
    }
  }, [isCommentPosted]);
  
const handleComments=async()=>{
  try{
    setIsloading(true);

    const createResult = await client.graphql({
      query: createComments,
      variables: {
        input: {
          postID:item.id,
          content: content,
          userID:userId,
        },
      },
    });
    setContent('')
    setIsCommentPosted(true)
  setIsloading(false)
  }catch(error){
    setShowErrorMessage("Failed to submit comment, please try again")
  }
  
}



  return (
    <>
      <div className={cn(styles.comments, className)}>
        <div className={styles.head}>
          <div className={styles.title}>
            <div className={styles.counter}>
              {/* {Allcomments.length > 0 ? Allcomments.length : 0} */}
            </div>
            Comments
          </div>
          <button className={styles.close} onClick={onClose}>
            <Icon name='close' size='24' />
          </button>
        </div>
        <CommentsEditor
          item={item}
          state={content}
          onChange={handleInputChange}
          classEditor={styles.editor}
          label='Review this product?'
          tooltip='You’re product owner'
          button='Comment'
        />
        {showErrorMessage?<p className={styles.errorMessage}>{showErrorMessage}</p>:null}
        <button className={styles.submitComment} onClick={handleComments}>{isLoading?<p>Posting..</p>:<p>Post</p>}</button>
        <div className={styles.list}>
          {allcomments.length > 0 &&
            allcomments.map((x, index) => (
              <Item className={styles.item} postId={item.id} item={x} key={index} />
              ))}
        </div>
             
      </div>
      <TooltipGlodal />
    </>
  )
}

export default Comments

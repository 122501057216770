import { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Login.module.sass";
import Item from "../Item";
import TextInput from "../../../components/TextInput";
import { signOut, updatePassword } from "aws-amplify/auth";
import { createPortal } from "react-dom";
import storage from "redux-persist/lib/storage";
import { useDispatch } from "react-redux";
import { reduxLogout } from "../../../slices/authSlice";

const Login = ({ className }) => {
  // State to manage form input values
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showError, setShowError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  // Handles password update using AWS Amplify
  const dispatch = useDispatch();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };
  async function handleUpdatePassword() {
    setLoading(true);
    try {
      if (!newPassword.trim()) {
        setShowError("New password cannot be empty.");
        return;
      }

      if (newPassword !== confirmPassword) {
        setShowError("New password and confirmation password do not match.");
        return;
      }
      await updatePassword({ oldPassword, newPassword });
      setShowError("Password updated successfully");
      await signOut({ global: true });
      dispatch(reduxLogout())
      storage.removeItem('persist:root')
    } catch (err) {
      setShowError(err.message);
    } finally {
      setLoading(false);
    }
  }
  return (
    <Item
      className={cn(styles.card, className)}
      title="Login"
      classTitle="title-purple"
    >
      <p
        className={cn(styles.errorMessage, {
          [styles.successMessage]: showError.includes("successfully"),
        })}
      >
        {showError}
      </p>
      <div className={styles.fieldset}>
        {/* Input field for the old password */}
        <TextInput
          className={styles.field}
          label="Old password"
          name="old-password"
          type={showOldPassword ? 'text' : 'password'}
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          onChange={(event) => setOldPassword(event.target.value)}
          required
        />
         <div class="flex items-center gap-2 mb-4 mt-4 ">
              <input className={`${styles.showPassword} w-4 h-4 focus:ring-0 rounded focus:outline-none`} type="checkbox" value={showOldPassword} onClick={toggleOldPasswordVisibility}/>
              <label  >Show Password</label>
          </div>
        {/* Input fields for the new password and its confirmation */}
        <div className={styles.row}>
          <TextInput
            className={styles.field}
            label="New password"
            name="new-password"
            type={showPassword ? 'text' : 'password'} 
            tooltip="Maximum 100 characters. No HTML or emoji allowed"
            onChange={(event) => setNewPassword(event.target.value)}
            required
          />
          <TextInput
            className={styles.field}
            label="Confirm new password"
            name="confirm-password"
            type={showPassword ? 'text' : 'password'} 
            tooltip="Maximum 100 characters. No HTML or emoji allowed"
            onChange={(event) => setConfirmPassword(event.target.value)}
            required
          />
        </div>
        <div class="flex items-center gap-2 mb-4 mt-4 ">
              <input className={`${styles.showPassword} w-4 h-4 focus:ring-0 rounded focus:outline-none`} type="checkbox" value={showPassword} onClick={togglePasswordVisibility}/>
              <label  >Show Password</label>
          </div>
        {loading ? (
          <button className={cn("button-stroke", styles.button)}>
            Updating password...
          </button>
        ) : (
          <button
            className={cn("button-stroke", styles.button)}
            onClick={handleUpdatePassword}
          >
            Update password
          </button>
        )}
      </div>
    </Item>
  );
};

export default Login;

    import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
    import { getUser } from "../graphql/queries";
    import { generateClient } from 'aws-amplify/api';

    // Assume Amplify is properly configured
    const client = generateClient();

    // Thunk to fetch user data
    export const fetchUserData = createAsyncThunk(
    'userSubs/fetchUserData',
    async (_, thunkAPI) => {
        const userId = thunkAPI.getState().auth.userDetails.id; 
        try {
        const response = await client.graphql({
            query: getUser,
            variables: { id: userId }
        });
        if (response.data.getUser && response.data.getUser.subscriptions) {
            return { 
            userDetails: response.data.getUser,
            userSubscriptions: response.data.getUser.subscriptions
            };
        } else {
            return { userDetails: response.data.getUser, userSubscriptions: [] };
        }
        } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
        }
    }
    );

    const userSubSlice = createSlice({
    name: 'userSubs',
    initialState: {
        userDetails: null,
        userSubscriptions: [], 
        loading: 'idle',
        error: null
    },
    reducers: {
        setUserData: (state, action) => {
        state.userDetails = action.payload;
        },
        setUserSubscriptions: (state, action) => {
        state.userSubscriptions = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchUserData.pending, (state) => {
            state.loading = 'pending';
        })
        .addCase(fetchUserData.fulfilled, (state, action) => {
            state.loading = 'fulfilled';
            state.userDetails = action.payload.userDetails;
            state.userSubscriptions = action.payload.userSubscriptions;
        })
        .addCase(fetchUserData.rejected, (state, action) => {
            state.loading = 'rejected';
            state.error = action.payload;
        })
        .addCase('subscriptionsUpdated', (state) => { // For refetching 
            state.loading = 'pending'; 
            fetchUserData()(state.dispatch, state.getState);
        }); 
    }
    });

    export const { setUserData, setUserSubscriptions } = userSubSlice.actions;
    export const selectUserDetails = (state) => state.userSubs.userDetails;
    export const selectUserSubscriptions = (state) => state.userSubs.userSubscriptions;
    export const selectLoadingStatus = (state) => state.userSubs.loading;
    export const selectError = (state) => state.userSubs.error;
    export default userSubSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const shopSlice = createSlice({
    name: 'shop',
    initialState: {
        subscriptions: {}
    },
    reducers: {
        setSubscription: (state, action) => {
            const { userId, value } = action.payload;
            state.subscriptions = { ...state.subscriptions, [userId]: value };
        }
    }
});

export const { setSubscription } = shopSlice.actions;
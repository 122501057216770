import styles from "./UpgradeToPro.module.sass";
import { Link } from "react-router-dom";
import Image from "../../components/Image";


const PerformanceAnalytics = () => {
  return (
    <header className={styles.header}>
      <Link className={styles.logo} to="/" >
        <Image
          className={styles.pic}
          src="/images/logo-light.svg"
          srcDark="/images/logo-dark.svg"
          alt="Core"
        />
      </Link>
</header>
);
}


export default PerformanceAnalytics;

import { createContext, useState, useContext } from "react";

// Create a context for authentication
const SettingsContext = createContext();

// Create a provider component to wrap your app
export const SettingsProvider = ({ children }) => {
    // State variables to manage username and avatar within the settings context
    const [username, setUsername] = useState('');
    const [avatar, setAvatar] = useState({});

    // Values object to be provided through the SettingsContext
    const values = {
        username,
        setUsername,
        setAvatar,
        avatar
    }

    return (
        <SettingsContext.Provider value={values}>
            {children}
        </SettingsContext.Provider>
    );
};

// Custom hook to use the authentication context
export const useSetting = () => useContext(SettingsContext);

import { Link } from 'react-router-dom'
import styles from './CreatorModal.module.sass'
export default function InformationTerms({ onPagination }) {
    return (
        <div className='modalBody__container flex flex-col gap-6'>
            <header className="modalBody__header flex items-center">
                <div className="left flex items-center gap-4">
                    <span className='box block w-4 h-8 rounded bg-[#CABDFF]'></span>
                    <p className={`font-semibold text-xl ${styles.headText}`}>Become a creator</p>
                </div>

            </header>
            <div className={`p-6 rounded-lg flex flex-col gap-8 ${styles.body}`}>
                <header className="flex items-center gap-4">
                    <span className="box block w-4 h-8 rounded bg-[#B5E4CA]"></span>
                    <p className={`font-semibold font-xl ${styles.subText}`}>Information & terms</p>
                </header>
                <div className="flex flex-col items-center gap-3">
                    <button className="w-full h-12 rounded-xl bg-[#2A85FF]" onClick={onPagination}>Continue Onboarding</button>
                    <p className="text-center text-[#6F767E] font-semibold text-sm">
                        By continuing to Stripe you agree to <br /> Our <Link to={'/terms'} className={`${styles.subText} underline`}>Terms of Use</Link> and <Link to={'/contract'} className={`${styles.subText} underline`}>
                            Creator Contract
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    )
}

import { createBrowserRouter } from "react-router-dom";
import Home from "./screens/Home";
import Page from "./components/Page";
import SignIn from "./screens/SignIn";
import SignUp from "./screens/SignUp";
import Terms from "./screens/Legal - Terms";
import CreatorContract from "./screens/Legal - Creator Contract";
import FinancialAnalytics from "./screens/Analytics - Financial";
import PerformanceAnalytics from "./screens/Analytics - Performance";
import Settings from "./screens/Settings";
import ProfilePage from "./screens/ProfilePage";
import ProfileEdit from "./screens/ProfileEdit";
import CreatePost from "./screens/CreatePost";
import PrivateRoutes from "./PrivateRoutes";
import Stripe from "./components/Page/StripeUrl/Stripe";
import ForgotPassword from "./screens/ForgotPassword/ForgotPassword";
import GoogleSigniIn from "./screens/GoogleAuth/sign_in";
import BugReport from "./screens/BugReport/BugReport";
const router = createBrowserRouter([
  {
    path: "/contract",
    element: <CreatorContract />,
    exact: true,
  },
  {
    path: "/terms",
    element: <Terms />,
    exact: true,
  },
  {
    path: "/",
    element: <Page />,
    children: [
      { index: true, element: <Home /> },
      { path: "/:username", element: <ProfilePage /> },
      {
        path: "/my",
        element: <PrivateRoutes />,
        children: [
          {
            path: "settings",
            element: <Settings />,
          },
          {
            path: "profile",
            element: <ProfileEdit />,
          },
          {
            path: "create/post",
            element: <CreatePost />,
          },
          {
            path: "bug-report",
            element: <BugReport />,
          },
          {
            path: "financials",
            element: <FinancialAnalytics />,
          },
          {
            path: "performance",
            element: <PerformanceAnalytics />,
          },
        ],
      },
    ],
  },
  {
    path: "/my/sign-in",
    element: <SignIn />,
  },
  {
    path: "my/google_sign_in",
    element: <GoogleSigniIn />,
  },
  {
    path: "/my/sign-up",
    element: <SignUp />,
  },
  {
    path: "my/stripe",
    element: <Stripe />,
  },
  {
    path: "/my/sign-in/forgot-password",
    element: <ForgotPassword />,
  },
]);

export default router;

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import cn from "classnames";
import { Amplify } from "aws-amplify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./MyProfile.module.sass";
import Icon from "../../../components/Icon";
import { generateClient } from "aws-amplify/api";
import Modal from "../../../components/Modal";
import { useSelector } from "react-redux";
import { setSubscription } from "../../../slices/shopSlice";
import { userByUsername } from "../../../graphql/queries";
import amplifyConfig from "../../../amplifyConfig";
import { useDispatch } from "react-redux";
import AvatarModal from "../AvatarModal/AvatarModal";
import CreatorModalDetail from "../../../components/Page/CreatorModal/CreatorModalDetail";
import TagLineModal from "../TaglineModal/TaglineModal";
import SocialModal from "../SocialModal/SocialModal";

const MyProfile = ({ myProfile }) => {
  const dispatch = useDispatch();
  // const userAvatar = useSelector(state => state.user.userData[0].avatar)
  const userAvatar = useSelector((state) => state?.auth?.userDetails?.avatar);
  const username = useSelector((state) => state.user.userData[0].username);
  const tagline = useSelector((state) => state.user.userData[0].tagline);
  const creatorPrice = useSelector(
    (state) => state.auth.userDetails.creator_price
  );
  const [visibleModal, setVisibleModal] = useState(false);

  const userSocials = useSelector(
    (state) => state.user.userData[0]?.socials || []
  );

  const socials = userSocials
    .map((data) => {
      // Check if data exists and is not empty
      if (data && data.trim() !== "") {
        const matches = data.match(/"([^"]+)":"([^"]+)"/g);
        if (matches) {
          return matches.map((match) => {
            const [title, url] = match.match(/"([^"]+)":"([^"]+)"/).slice(1);
            return {
              title: title,
              url: `https://${url.replaceAll(/["}]/g, "")}`,
            };
          });
        }
      }
      return null; // Return null if no data or empty data
    })
    .flat()
    .filter(Boolean);
  // const banner = useSelector((state) => state.user.userData[0].banner);
  // State for determining if rendering current user's profile page
  const [isProfileCreator, setisProfileisCreator] = useState(false);
  // const [loading, setLoading] = useState(true);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const [AvatarvisibleModal, setAvatarVisibleModal] = useState(false);
  const [TaglinevisibleModal, setTaglineVisibleModal] = useState(false);
  const [SocialvisibleModal, setSocialVisibleModal] = useState(false);
  const { pathname } = useLocation();
  const handleButtonClick = () => {
    // addToGroup(); // Call addToGroup function
    setVisibleModal(true); // Set modal visibility to true
  };
  // Fetch subscription-related data when component mounts or userDetails changes

  useEffect(() => {
    setisProfileisCreator(false); // Reset in case if user changes his creatorship
    const UserSubscription = async () => {
      // ... GraphQL query with Amplify to fetch creator status and subscriber data
      Amplify.configure(amplifyConfig);
      const client = generateClient();

      try {
        const User = await client.graphql({
          query: userByUsername,
          variables: {
            username: myProfile,
          },
        });
        const listSubscriber = User.data.userByUsername.items[0].subscribers;
        const creator = userDetails ? userDetails.isCreator : false;

        if (creator) {
          setisProfileisCreator(true);
          // Check if the current user is subscribed
          const filteredSubscribers = listSubscriber.some(
            (subscriber) => subscriber === userDetails.id
          );
          if (filteredSubscribers) {
            dispatch(
              setSubscription({
                userId: User.data.userByUsername.items[0].id,
                value: true,
              })
            );
          }
        }
        // setLoading(false); // Fetching complete
      } catch (error) {
        // setLoading(false);
      }
    };

    UserSubscription();
  }, [userDetails.id, pathname]);


  // if (loading) {
  //   return <p>Loading...</p>; // Render loading indicator until backend fetching is completed
  // }
  return (
    <>
      <div className={styles.outer}>
        <Modal
          outerClassName={styles.outer}
          visible={visibleModal}
          onClose={() => setVisibleModal(false)}
        >
          <CreatorModalDetail ModalVisible={() => setVisibleModal(false)} />
        </Modal>
      </div>

    <div className={styles.profile}>
      <ToastContainer position="top-center" theme="dark" />
      <div className={styles.details}>
        <div className={`${styles.avatar} profile-bg relative z-[1!important]`}>
          <Modal
            outerClassName={styles.outer}
            visible={AvatarvisibleModal}
            onClose={() => setAvatarVisibleModal(false)}
          >
            <AvatarModal ModalVisible={() => setAvatarVisibleModal(false)} />
          </Modal>
          {userAvatar ? (
            <img
              onClick={() => setAvatarVisibleModal(true)}
              className={styles.backgroundImage}
              src={`${process.env.REACT_APP_S3_PUBLIC}${userAvatar}`}
              alt="Avatar"
            />
          ) : (
            <div
              onClick={() => setAvatarVisibleModal(true)}
              style={{ backgroundColor: `${userDetails?.theme}` }}
              className={`${styles.backgroundImage} profile-img text-[28px] cursor-pointer h-full rounded-full flex items-center justify-center`}
            >
              {userDetails?.username?.[0].toUpperCase()}
            </div>
          )}

          <span className="absolute banner-change w-[30px] h-[30px]">
            <svg
              className="fill-white"
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="EditIcon"
            >
              <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75z"></path>
            </svg>
          </span>
        </div>
        <div className={styles.wrap}>
          {/* <input
            className={`bg-transparent text-[20px] text-white transition-all name-input p-0 outline-none! border-none ${styles.username}`}
            value={username.toUpperCase()}
            type="text"
          /> */}
          <div className={cn('h4', styles.man)}>{username}</div>
          <Modal
            outerClassName={styles.outer}
            visible={TaglinevisibleModal}
            onClose={() => setTaglineVisibleModal(false)}
          >
            <TagLineModal oldTagLine={tagline} ModalVisible={() => setTaglineVisibleModal(false)} />
          </Modal>
          <div
            onClick={() => setTaglineVisibleModal(true)}
            className={styles.info}
          >
            {tagline ? (
              <span>
                <Icon className={styles.editTagline} name="edit" size="15" />
              <p >{tagline}</p>  
              </span>
            ) : (
              <button
                onClick={() => setTaglineVisibleModal(true)}
                className={`transition-all me-2 py-2 px-4 rounded-lg flex text-[#6F767E] items-center ${styles.Tagline}`}
              >
                Add Tagline
              </button>
            )}
          </div>
        </div>
      </div>
    
      <div className={styles.contacts}>
        <div className={styles.socials}>
          <Modal
            outerClassName={styles.outer}
            visible={SocialvisibleModal}
            onClose={() => setSocialVisibleModal(false)}
          >
            <SocialModal ModalVisible={() => setSocialVisibleModal(false)} />
          </Modal>
          <button
            onClick={() => setSocialVisibleModal(true)}
            className={`transition-all me-2 py-2 px-4 rounded-lg flex text-[#6F767E] items-center ${styles.Request_btn}`}
          >
            {socials.length === 0 ? "Add socials" : ""}
            <svg
              className={`w-[20px] ${
                socials.length === 0 ? "ms-2" : ""
              } transition-all fill-[#6F767E]`}
              focusable="false"
              aria-hidden="true"
              viewBox="0 0 24 24"
              data-testid="AddIcon"
            >
              <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z"></path>
            </svg>
          </button>
          <div className="flex items-center mt-3 md:mt-0 justify-center ">
            {socials.map((x, index) => {
              const urlParts = x.url.split("/");
             if (urlParts.length > 3 && urlParts[3].trim() !== "") {
              return(
                <a
                className={styles.social}
                href={x.url}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
              >
                <Icon name={x.title} size="24" />
              </a>
              )
            }
            else return null
          })}
          </div>
        </div>

        {isProfileCreator ? ( // if viewing profile page
          <div>
            <button
              disabled="true"
              className={cn(
                "price_button rounded-lg h-[48px] px-5 transition-all cursor-pointer  text-[#6F767E]",
                styles.Request_btn
              )}
            >
              Your subscription price is ${creatorPrice}
            </button>
          </div>
        ) : (
          <div>
            {
              <button
                onClick={handleButtonClick}
                className={cn("button", styles.button)}
              >
                Become a creator
              </button>
            }
          </div>
        )}
      </div>
    </div>
    </>
  );
};

export default MyProfile;

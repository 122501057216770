import { createSlice } from '@reduxjs/toolkit';

export const postSlice = createSlice({
    name: 'post',
    initialState: {
        productTitle: "",
        productDescription: "",
        uploadedContent: "",
        thumbnailImage: "",
    },
    reducers: {
        setProductTitle: (state, action) => {
            state.productTitle = action.payload;
        },
        setProductDescription: (state, action) => {
            state.productDescription = action.payload;
        },
        setUploadedContent: (state, action) => {
            state.uploadedContent = action.payload;
        },
        setThumbnailImage: (state, action) => {
            state.thumbnailImage = action.payload;
        },
    },

})

export const { setProductTitle, setProductDescription, setUploadedContent, setThumbnailImage } = postSlice.actions;
import { useState } from "react";
import cn from "classnames";
import styles from "./SignIn.module.sass";
import {  signInWithRedirect } from "aws-amplify/auth";
import { use100vh } from "react-div-100vh";
import { Link, useNavigate } from "react-router-dom";
import TextInput from "../../components/TextInput"; // Adjust path if necessary
import Image from "../../components/Image"; // Adjust path if necessary
import {
  signIn,
} from "aws-amplify/auth";
import { Amplify } from "aws-amplify";
import awsExports from "../../aws-exports";
import { useDispatch } from "react-redux";
import {
  signInRedux as setIsAuthenticated,
} from "../../slices/authSlice";  
import amplifyConfig from "../../amplifyConfig";
import Icon from "../../components/Icon";

const SignIn = () => {
  const dispatch = useDispatch();

  Amplify.configure(amplifyConfig);
  Amplify.configure(awsExports);

  const heightWindow = use100vh();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const { isSignedIn } = await signIn({ username, password });
      
      dispatch(setIsAuthenticated(isSignedIn));
      navigate("/");
    } catch (error) {
      setError("Error signing in: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleForgotPassword = () => {
    navigate("/my/sign-in/forgot-password"); // Navigate to the password reset page
  };

function handleSignInClick(customState) {
  signInWithRedirect({
    provider: "Google",
    customState
  });
}

  return (
    <div className={styles.login} style={{ minHeight: heightWindow }}>
      <div className={styles.wrapper}>
        <Link className={styles.logo} to="/">
          <Image
            className={styles.pic}
            src="/images/logo-light.svg"
            srcDark="/images/logo-dark.svg"
            alt="Core"
          />
        </Link>
        <div className={cn("h2", styles.title)}>Sign in</div>
        <div className={styles.head}>
          <div className={styles.subtitle}>Sign up with Open account</div>
          <div className={styles.btns}>
            <button onClick={()=>handleSignInClick()} className={cn("button-stroke", styles.button)}>
              <img src="/images/content/google.svg" alt="Google" />
              Google
            </button>
          
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.subtitle}>Or continue with email address</div>
          <form onSubmit={handleSubmit}>
            {error && <div className="text-red-500 mb-2">{error}</div>}
            <TextInput
              className={styles.field}
              name="email"
              type="email"
              placeholder="Your email"
              required
              icon="mail"
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextInput
              className={styles.field}
              name="password"
              type={showPassword ? 'text' : 'password'} 
              placeholder="Password"
              required
              icon="lock"
              onChange={(e) => setPassword(e.target.value)}
            />
          

          <div class="flex items-center gap-2 mb-4 mt-4 ">
              <input className={`${styles.showPassword} w-4 h-4 focus:ring-0 rounded focus:outline-none`} type="checkbox" value={showPassword} onClick={togglePasswordVisibility}/>
              <label  >Show Password</label>
          </div>

            <button
              id="signinBtn"
              className={cn("button", styles.button)}
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? "Signing In..." : "Sign In"}
            </button>
            <button className={styles.forgotPassword} onClick={handleForgotPassword}>
              Forgot Password?
            </button>
          </form>
          <div className={styles.note}>
            This site is protected by reCAPTCHA and the Google Privacy Policy.
          </div>
          <div className={styles.info}>
            Don’t have an account?{" "}
            <Link className={styles.link} to="/my/sign-up">
              Sign up
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;

const NoResult = ({ img, resultHeading, resultSubText, resultButton }) => {
  return (
    <div className="w-full flex flex-col justify-center items-center">
        <img className="max-w-[220px] max-h-[160px] object-cover" alt="Files illustration" src={img} />
        <div className={'result-information__container mt-6 text-center'}>
            <p className="result-heading font-semibold text-xl text-white">{resultHeading}</p>
            <p className="result-subText text-base text-[#94969C] font-regular">{resultSubText}</p>
        </div>
        <div className="result-button__container mt-8">
            <button>
                {resultButton}
            </button>
        </div>
    </div>
  )
}

export default NoResult
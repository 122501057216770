import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./Item.module.sass";
import Icon from "../../../Icon";

const Item = ({ className, item, onClick, clearInputValue }) => {

	return (
		<div className={cn(styles.item, className)}>
			<Link to={`/${item.username}`} className={styles.link} onClick={() => {
				onClick()
				clearInputValue()
			}} >
				<div
					className={`${styles.preview}`}
				>
					{item.avatar ? (
						<img
							src={`${process.env.REACT_APP_S3_PUBLIC}${item.avatar}`}
							alt="Product"
						/>
					) : (
						<div style={{ backgroundColor: `${item?.theme}` }} className={`profile-img text-[28px] h-full rounded-full flex items-center justify-center`}>{item?.username?.[0].toUpperCase()}</div>
					)}
				</div>
				<div className={styles.details}>

					<div className={styles.title}>
						{item.username}
					</div>
				</div>
			</Link>
			<button className={styles.close}>
				<Icon name="close" size="24" />
			</button>
		</div>
	);
};

export default Item;

// src/amplifyConfig.js
const amplifyConfig = {
    API: {
        GraphQL: { 
            // Use your existing GraphQL configuration
            endpoint: process.env.REACT_APP_ENDPOINT,
            region: process.env.REACT_APP_REGION,
            defaultAuthMode: process.env.REACT_APP_DEFAULTAUTHMODE,
            apiKey: process.env.REACT_APP_APIKEY
        }

    }
}                                                                                                                                                                                                                                                                                                                                                                                                                                                       

export default amplifyConfig;

import { useEffect, useState } from 'react'
import styles from './Follower.module.sass'
import cn from 'classnames'
import heart_hand from './images/heart-hand.svg'
import like_icon from './images/arrow-up.svg'
import TipModal from '../../../components/Page/TipModal/TipModal.js'
// import { useContext } from 'react'
import Modal from '../../../components/Modal/index.js'
import { Amplify } from 'aws-amplify'
// import { UserContext } from '../../../UserContext.js'
import { useSelector } from 'react-redux'
import { generateClient } from 'aws-amplify/api'
import { getUser } from '../../../graphql/queries.js'
import { getSubscriberRequest } from '../../../graphql/queries.js'
// import { useAuth } from '../../../AuthContext.js'
import amplifyConfig from '../../../amplifyConfig.js'
const Follower = ({ className, item, followerId }) => {
  const [visibleModal, setVisibleModal] = useState(false)
  const [followerAvatar, setFollowerAvatar] = useState(null);
  const [followerName, setFollowerName] = useState(null);
  const [donationDetails, setDonationDetails] = useState(null)
  const donationAmount = donationDetails?.reduce((total, donation) => total + donation.amount, 0);
  const [bgColor, setBgColor] = useState(null)
  // const { userData, userAvatar } = useContext(UserContext)
  const client = generateClient();
  // const { userDetails } = useAuth()
  const userDetails = useSelector(state => state.auth.userDetails)
  // const [like,setLike]=useState(false) // State to manage like status
  Amplify.configure(amplifyConfig)
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await client.graphql({
          query: getUser,
          variables: {
            id: followerId
          }
        })
        setFollowerAvatar(response.data.getUser.avatar)
        setFollowerName(response.data.getUser.username)
        setBgColor(response.data.getUser.theme)
      } catch (err) {
        console.error(err)
      }
    }
    fetchUserData();
  }, []);
  useEffect(() => {
    const fetchSubscriberRequest = async () => {
      try {
        const response = await client.graphql({
          query: getSubscriberRequest,
          variables: {
            id: item.id
          }
        })
       
        setDonationDetails(response.data.getSubscriberRequest.donations)
      } catch (err) {
        console.error(err)
      }
    }
    fetchSubscriberRequest();
  }, [])
  return (
    <div className={cn(styles.follower, className)}>
      <div className={styles.details}>
        <div className={styles.wrap}>
          <div className={styles.fan}>
            <div className={styles.avatar}>
              {followerAvatar ? (
                <img src={`${process.env.REACT_APP_S3_PUBLIC}${followerAvatar}`} alt="Avatar" />
              ) : (
                <div style={{ backgroundColor: `${bgColor}` }} className={`profile-img text-[18px] h-full rounded-full flex items-center justify-center`}>{followerName?.[0]?.toUpperCase()}</div>
              )}
            </div>
            <Modal
              outerClassName={styles.outer}
              visible={visibleModal}
              onClose={() => setVisibleModal(false)}
            >
              <TipModal item={item} />
            </Modal>
            <div className={styles.list}>
              <div className={styles.counter}>
                { /* Display the poster's username */}
                <div className={styles.man}>{followerName}</div>
                {/* Indication that a subscriber request was posted */}
                <span>
                  Posted a{' '}
                  <span style={{ color: 'white' }}>subscriber request</span>
                </span>{' '}
              </div>

            </div>
          </div>

          { /* Display the subscriber request details */}
          <div className={styles.title}> <h2>{item.title}</h2>  </div>
          <div className={styles.description}> <p>{item.content}</p> </div>

          <div className={styles.btns}>
            <button>
              {donationDetails?.length > 0 && (
                <>
                  <span className='total-amount me-2'>${donationAmount > 0 ? donationAmount : 0}</span>
                  tipped by
                  <span className='ms-2'>{donationDetails?.length}{donationDetails?.length > 1 ? ' people' : ' person'}</span>
                </>
              )}
              {!donationDetails && (
                <span className='total-amount'>No one has donated yet</span>
              )}
            </button>

            <button
              onClick={() => setVisibleModal(true)}
              className={styles.tip}
            >
              <img src={heart_hand} alt='tip' />
              <span>Tip</span>
            </button>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Follower

import { useState, useRef } from "react";
import cn from "classnames";
import styles from "./Settings.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Dropdown from "../../components/Dropdown";
import ProfileInformation from "./ProfileInformation";
import Login from "./Login";
import { useSetting } from "./SettingsContext";
import { updateUser } from "../../graphql/mutations";
// import { useAuth } from "../../AuthContext";
import { generateClient } from "aws-amplify/api";
import { uploadData } from "aws-amplify/storage";
import { userDetails } from "../../slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import DeleteAccountModal from "./DeleteAccountModal";
const Settings = () => {
	// Define navigation items and associated scrolling actions
	const navigation = [
		{
			title: "Basics",
			action: () =>
				scrollToProfile.current.scrollIntoView({ behavior: "smooth" }),
		},
		{
			title: "Account",
			action: () =>
				scrollToLogin.current.scrollIntoView({ behavior: "smooth" }),
		},
	];

	// Create options array for the dropdown 
	const options = [];
	navigation.map((x) => options.push(x.title));

	// State management for active tab and scrolling refs
	const [activeTab, setActiveTab] = useState(options[0]);
	const [deleteModalActive, setDeleteModalActive] = useState(false);
	const [activeIndex, setActiveIndex] = useState(0);
	const scrollToProfile = useRef(null);
	const scrollToLogin = useRef(null);

	// Access settings context data and functions from useAuth
	// const { userDetails, setUserDetails } = useAuth();
	const dispatch = useDispatch();
	const userDetailsValue = useSelector(state => state.auth.userDetails)
	const { username, avatar, setAvatar } = useSetting();

	// Generate a GraphQL client
	const client = generateClient();

	// Handles navigation button clicks, tab switching, and scrolling 
	const handleClick = (x, index) => {
		setActiveIndex(index);
		x.action();
	};

	async function saveSettings() {


		try {
			if (avatar.key === "default") {
				// Logic to handle reverting to the default avatar
				await client.graphql({
					query: updateUser,
					variables: {
						input: {
							id: userDetailsValue.id,
							username: username || userDetailsValue.username,
							avatar: null,
						},
					},
				});
				window.location.reload(); // Force refresh after avatar reset
			} else if (avatar.data) {
				// logic to handle uploading and saving a new avatar
				const uploadResult = await uploadData({
					key: avatar.key,
					data: avatar.data,
					options: { accessLevel: 'guest' }, // Set appropriate access level for uploaded images
				}).result;
				setAvatar({ key: uploadResult.key, data: avatar.data });
			}

			// Update user details with the avatar key
			// setUserDetails(prevDetails => ({ ...prevDetails, avatar: avatar.key }));
			dispatch(userDetails({ ...userDetailsValue, avatar: avatar.key }))
		} catch (error) {
			console.error("Error in saving settings:", error);
		}
	}


	return (
		<>
			<DeleteAccountModal isVisible={deleteModalActive} onClose={() => setDeleteModalActive(false)} />
			<div className={styles.settings}>
				<div className={styles.menu}>
					{navigation.map((x, index) => (
						<button
							className={cn(styles.button, {
								[styles.active]: activeIndex === index,
							})}
							key={index}
							onClick={() => handleClick(x, index)}
						>
							{x.title}
						</button>
					))}
				</div>
				<div className={styles.wrapper}>
					{/* Tab Selection Dropdown */}
					<Dropdown
						className={styles.dropdown}
						classDropdownHead={styles.dropdownHead}
						value={activeTab}
						setValue={setActiveTab}
						options={options}
					/>
					<div className={styles.list}>
						{/* Conditionally render settings sections based on active tab */}
						<div
							className={cn(styles.item, {
								[styles.active]: activeTab === options[0],
							})}
						>
							<div
								className={styles.anchor}
								ref={scrollToProfile}
							></div>
							<ProfileInformation />
						</div>
						<div
							className={cn(styles.item, {
								[styles.active]: activeTab === options[1],
							})}
						>
							<div
								className={styles.anchor}
								ref={scrollToLogin}
							></div>
							<Login />
						</div>
					</div>
					{/* Delete Account Button */}
					<button onClick={() => setDeleteModalActive(true)} className="block mt-8 text-[#A52222] hover:text-[#a52222ad]">Delete your OnlyGamers account</button>
					{/* Save Button */}
					<button className={cn("button", styles.button)} onClick={saveSettings}>Save</button>
				</div>
			</div>
			<TooltipGlodal />
		</>
	);
};

export default Settings;

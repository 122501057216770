import { useEffect, useState } from 'react'
import styles from './RequestModal.module.sass'
import cn from 'classnames'
import { Editor as ReactEditor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import TextInput from '../../../components/TextInput'
import { Link } from 'react-router-dom'
import { Amplify } from 'aws-amplify'
import { generateClient } from 'aws-amplify/api'
import { createSubscriberRequest } from '../../../graphql/mutations'
// import { useContext } from 'react'
// import { UserContext } from '../../../UserContext'
// import { useAuth } from '../../../AuthContext'
import amplifyConfig from '../../../amplifyConfig'
import { useSelector } from 'react-redux'
const RequestModal = ModalVisible => {
  // const { userData } = useContext(UserContext)
  const userData = useSelector((state) => state.user.userData);
  const [editorState, setEditorState] = useState(null)
  // const { userDetails } = useAuth()
  const userDetails = useSelector(state => state.auth.userDetails)
  const [price, setPrice] = useState(0)
  const [title, setTitle] = useState('')
  const [stripeConnected, setStripeConnected] = useState(false)

  const handleEditorChange = editorState => {
    setEditorState(editorState)
  }
  const handleConnectToStripe = () => {
    const addrequest = async () => {
      Amplify.configure(amplifyConfig)
      const client = generateClient()
      try {

        await client.graphql({
          query: createSubscriberRequest,
          variables: {
            input: {
              id: userDetails.id,
              user_id: userDetails.id,
              creator_id: userData[0].id,
              title: title,
              content: editorState.getCurrentContent().getPlainText(),
              status: true,
              donation: price
            }
          }
        })
      } catch (error) {
      }
    }
    addrequest()
  }
  const handleAddRequest = () => {
    const addrequest = async () => {
      Amplify.configure(amplifyConfig)
      const client = generateClient()
      try {
        await client.graphql({
          query: createSubscriberRequest,
          variables: {
            input: {
              user_id: userDetails.id,
              creator_id: userData[0].id,
              title: title,
              content: editorState.getCurrentContent().getPlainText(),
              status: true,
              donation: price
            }
          }
        })

      } catch (error) {
      }
    }
    addrequest()
  }

  return (
    <>
      <div className={styles.details}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={cn('title-green', styles.title)}>Add Request</div>

            <TextInput
              className={styles.field}
              label='Title'
              name='title'
              optional='Important'
              type='text'
              value={title}

              onChange={event => setTitle(event.target.value)}
            />

            <div className={styles.description}>
              <h5>Description</h5>
              <div className={styles.editor}>
                <ReactEditor
                  editorState={editorState}
                  toolbarClassName={styles.editorToolbar}
                  wrapperClassName={styles.editorWrapper}
                  editorClassName={styles.editorMain}
                  onEditorStateChange={handleEditorChange}
                  toolbar={{
                    options: [
                      'inline',
                      'emoji',
                      'link',
                      'list',
                      'textAlign',
                      'history'
                    ],
                    inline: { options: ['bold', 'italic', 'underline'] },
                    link: { options: ['link'] },
                    list: { options: ['unordered'] },
                    textAlign: { options: ['center'] }
                  }}
                />
              </div>
            <div className={styles.price}>
              <TextInput
                className={styles.field}
                label='Donation Amount'
                optional='Optional'
                name='title'
                type='text'
                currency={'$'}
                value={price}
                onChange={event => {
                  try {
                    let value = Number(event.target.value)
                    setPrice(value)
                  } catch (error) {
                  }
                }}
                required
              />
            </div>
            <div className={styles.btns}>
              {price > 0 ? (
                // Display the "Connect to Stripe" button if price is greater than 0
                <button
                  style={{ backgroundColor: 'rgba(89, 75, 185, 1)' }}
                  className={cn('button', styles.button)}
                  onClick={handleConnectToStripe}
                >
                  Connect to Stripe
                </button>
              ) : (
                // Display the "Add Request" button if price is 0 or less
                <button
                  className={cn('button', styles.button)}
                  onClick={handleAddRequest}
                >
                  Add Request
                </button>
              )}
            </div>
            {price > 0 ? (
              <div className={styles.terms}>
                By continuing to Stripe, you agree to
                <div>
                  Our{' '}
                  <Link to='/terms' target='_blank'>
                    Terms of Service
                  </Link>{' '}
                  and{' '}
                  <Link to='/contract' target='_blank'>
                    Creator Contract
                  </Link>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
        </div>
      </div>
    </>
  )
}

export default RequestModal

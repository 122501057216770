import styles from "./UserDisplay.module.sass";
import stylesSkeleton from "../SkeletonLoader/Skeleton.module.sass";
import cn from "classnames";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { generateClient } from "aws-amplify/api";
import { postsByUserID } from "../../graphql/queries";
import ALLUserProduct from "./../AllUserProducts/Index";

const UserDisplay = ({ creator }) => {
  const [products, setProducts] = useState([]);
  const [avatar, setAvatar] = useState(null);
  const imgSrc =
    "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Default_pfp.svg/340px-Default_pfpsvg.png";
  const [isLoading, setIsLoading] = useState(true);
  const client = generateClient();
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const Users = await client.graphql({
          query: postsByUserID,
          variables: {
            userID: creator.id,
          },
        });
        setProducts(Users.data.postsByUserID.items);
      } catch (err) {
      
      } finally {
        setIsLoading(false);
      }
    })();
  }, [creator.id]);

  return (
    <>
      <div className={styles.container}>
        {isLoading && (
          <div className={stylesSkeleton.skeletonContainer}>
            <div className={stylesSkeleton.skeletonProfileDetailsContainer}>
              <div className={stylesSkeleton.skeletonLeftContainer}>
                <div className={stylesSkeleton.skeletonAvatar}></div>
                <div className={stylesSkeleton.skeletonProfileContainer}>
                  <h2
                    content="skeleton"
                    className={stylesSkeleton.skeletonUserName}
                  ></h2>
                  <p className={stylesSkeleton.skeletonPostCount}></p>
                </div>
              </div>
              <div className={stylesSkeleton.skeletonButtonContainer}>
                <div className={stylesSkeleton.skeletonButton}></div>
                <div className={stylesSkeleton.skeletonButton2}></div>
              </div>
            </div>
            <div className={stylesSkeleton.skeletonVideos}>
              <div className={stylesSkeleton.skeletonVideo}>
                <div className={stylesSkeleton.skeletonProductImage}></div>
                <div className={stylesSkeleton.skeletonProductDetails}>
                  <div className={stylesSkeleton.skeletonProductTitle}></div>
                  <div className={stylesSkeleton.skeletonProductStats}>
                    <svg
                      fill="#6f767e"
                      width="24"
                      height="24"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.867 3.475L8 3.611l.133-.136c1.495-1.523 3.923-1.523 5.418 0a3.93 3.93 0 0 1 0 5.485l-4.123 4.202a2 2 0 0 1-2.855 0L2.449 8.961a3.93 3.93 0 0 1 0-5.485c1.495-1.523 3.923-1.523 5.418 0zM3.4 4.409c-.979.998-.979 2.62 0 3.618l4.123 4.202c.261.266.69.266.952 0l4.123-4.202c.979-.998.979-2.62 0-3.618a2.45 2.45 0 0 0-3.514 0l-.609.621c-.125.128-.297.2-.476.2s-.35-.072-.476-.2l-.609-.621a2.45 2.45 0 0 0-3.514 0z"></path>
                    </svg>
                    <span className={stylesSkeleton.skeletonSpan}></span>
                  </div>
                </div>
              </div>
              <div className={stylesSkeleton.skeletonVideo}>
                <div className={stylesSkeleton.skeletonProductImage}></div>
                <div className={stylesSkeleton.skeletonProductDetails}>
                  <div className={stylesSkeleton.skeletonProductTitle}></div>
                  <div className={stylesSkeleton.skeletonProductStats}>
                    <svg
                      fill="#6f767e"
                      width="24"
                      height="24"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.867 3.475L8 3.611l.133-.136c1.495-1.523 3.923-1.523 5.418 0a3.93 3.93 0 0 1 0 5.485l-4.123 4.202a2 2 0 0 1-2.855 0L2.449 8.961a3.93 3.93 0 0 1 0-5.485c1.495-1.523 3.923-1.523 5.418 0zM3.4 4.409c-.979.998-.979 2.62 0 3.618l4.123 4.202c.261.266.69.266.952 0l4.123-4.202c.979-.998.979-2.62 0-3.618a2.45 2.45 0 0 0-3.514 0l-.609.621c-.125.128-.297.2-.476.2s-.35-.072-.476-.2l-.609-.621a2.45 2.45 0 0 0-3.514 0z"></path>
                    </svg>
                    <span className={stylesSkeleton.skeletonSpan}></span>
                  </div>
                </div>
              </div>
              <div className={stylesSkeleton.skeletonVideo}>
                <div className={stylesSkeleton.skeletonProductImage}></div>
                <div className={stylesSkeleton.skeletonProductDetails}>
                  <div className={stylesSkeleton.skeletonProductTitle}></div>
                  <div className={stylesSkeleton.skeletonProductStats}>
                    <svg
                      fill="#6f767e"
                      width="24"
                      height="24"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.867 3.475L8 3.611l.133-.136c1.495-1.523 3.923-1.523 5.418 0a3.93 3.93 0 0 1 0 5.485l-4.123 4.202a2 2 0 0 1-2.855 0L2.449 8.961a3.93 3.93 0 0 1 0-5.485c1.495-1.523 3.923-1.523 5.418 0zM3.4 4.409c-.979.998-.979 2.62 0 3.618l4.123 4.202c.261.266.69.266.952 0l4.123-4.202c.979-.998.979-2.62 0-3.618a2.45 2.45 0 0 0-3.514 0l-.609.621c-.125.128-.297.2-.476.2s-.35-.072-.476-.2l-.609-.621a2.45 2.45 0 0 0-3.514 0z"></path>
                    </svg>
                    <span className={stylesSkeleton.skeletonSpan}></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!isLoading && (
          <>
            <div className={styles.control}>
              <div className={styles.nav}>
                <div className={styles.header}>
                  <div className={styles.avatar}>
                    {creator.avatar ? (
                      <img
                        src={`${process.env.REACT_APP_S3_PUBLIC}${creator.avatar}`}
                        alt="ProfileImage"
                        style={{
                          maxWidth: "unset",
                          width: "50px",
                          height: "50px",
                        }}
                      />
                    ) : (
                      <div
                        style={{ backgroundColor: `${creator?.theme}` }}
                        className={`profile-img text-[28px] h-full rounded-full flex items-center justify-center`}
                      >
                        {creator?.username?.[0].toUpperCase()}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col">
                    <h2 className={`text-[1rem] ${styles.username}`}>
                      {creator.username}
                    </h2>
                    <div className="flex gap-4 text-[0.65rem] text-gray-500">
                      <p className={styles.p}>
                        {products.length > 1
                          ? `${products.length} posts`
                          : `${products.length} post`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.buttons}>
                <NavLink
                  style={{ marginRight: "18px" }}
                  to={`/${creator.username}`}
                  className={cn("button_creator")}
                >
                  Check Profile
                </NavLink>

                <NavLink to={`/${creator.username}`} className={cn("button")}>
                  {`Subscription: $${creator.creator_price}`}
                </NavLink>
              </div>
            </div>
            <div className={styles.products}>
              {products.map((x, index) => (
                <ALLUserProduct
                  className={styles.product}
                  item={x}
                  key={index}
                  withoutСheckbox
                />
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default UserDisplay;

import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import cn from 'classnames'
import { Amplify } from 'aws-amplify'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import styles from './Profile.module.sass'
import { onCreateCreatorSubscription } from '../../../graphql/subscriptions'
import { listCreatorSubscriptions } from '../../../graphql/queries'
import Icon from '../../../components/Icon'
import { generateClient } from 'aws-amplify/api'
import SubModal from '../../../components/Page/AddSubModal/SubModal'
import Modal from '../../../components/Modal'
import { useSelector } from 'react-redux'
import { setSubscription } from '../../../slices/shopSlice'
import { userByUsername } from '../../../graphql/queries'
import amplifyConfig from '../../../amplifyConfig'
import { useDispatch } from 'react-redux'
import { fetchUserData } from '../../../slices/userSubsSlice'
import ProfileEdit from '../../ProfileEdit'
const Profile = ({ user }) => {
  const dispatch = useDispatch()
  const userAvatar = useSelector(state => state?.user?.userData[0]?.avatar)
  const username = useSelector(state => state?.user?.userData[0]?.username)
  const creatorId = useSelector(state => state?.user?.userData[0]?.id)
  const tagline = useSelector(state => state?.user?.userData[0]?.tagline)
  const userSocials = useSelector(
    state => state.user.userData[0]?.socials || []
  )

  const socials = userSocials
    .map(data => {
      // Check if data exists and is not empty
      if (data && data.trim() !== '') {
        const matches = data.match(/"([^"]+)":"([^"]+)"/g)
        if (matches) {
          return matches.map(match => {
            const [title, url] = match.match(/"([^"]+)":"([^"]+)"/).slice(1)
            return {
              title: title,
              url: `https://${url.replaceAll(/["}]/g, '')}`
            }
          })
        }
      }
      return null // Return null if no data or empty data
    })
    .flat()
    .filter(Boolean)
  // const banner = useSelector((state) => state.user.userData[0].banner);
  // State for determining if rendering current user's profile page
  const [isProfileCreator, setisProfileisCreator] = useState(false)
  const [loading, setLoading] = useState(true)
  const isCreator = useSelector(state => state.user.userData[0]?.isCreator)
  // const { subscriptions, setSubscription } = useShop() // Manage subscription
  const subscriptions = useSelector(state => state.userSubs.userSubscriptions)
  // const { userDetails } = useAuth()
  const userDetails = useSelector(state => state.auth.userDetails)
  const userData = useSelector(state => state.user.userData[0]);
  // State for banner image and modal visibility
  const [visibleSubModal, setVisibleSubModal] = useState(false)
  const { pathname } = useLocation()

  // Fetch subscription-related data when component mounts or userDetails changes

  const client = generateClient()
  useEffect(() => {
    if (user[0].id) {
      setisProfileisCreator(false) // Reset in case previous user was a creator

      const UserSubscription = async () => {
        // ... GraphQL query with Amplify to fetch creator status and subscriber data
        Amplify.configure(amplifyConfig)


        try {
          const User = await client.graphql({
            query: userByUsername,
            variables: {
              username: pathname.slice(1)
            }
          })
          const listSubscriber = User.data.userByUsername.items[0].subscribers
          const creator = User.data.userByUsername.items[0].isCreator

          if (creator) {
            setisProfileisCreator(true)

            // Check if the current user is subscribed
            const filteredSubscribers = listSubscriber.some(
              subscriber => subscriber === userDetails.id
            )

            if (filteredSubscribers) {
              dispatch(
                setSubscription({
                  userId: User.data.userByUsername.items[0].id,
                  value: true
                })
              )
            }
          }
          setLoading(false) // Fetching complete
        } catch (error) {
          setLoading(false)
        }
      }

      UserSubscription()
    }
  }, [userDetails?.id, pathname, user])


  useEffect(() => {
    // Dispatch the thunk to fetch data on component mount
    dispatch(fetchUserData());
  }, [dispatch, user]);
  if (loading) {
    return <p>Loading...</p> // Render loading indicator until backend fetching is completed
  }
  return (
     <>
      <div className={styles.outer}>
        <Modal
          outerClassName={styles.outer}
          visible={visibleSubModal}
          onClose={() => setVisibleSubModal(false)}
        >
          <SubModal
            ModalVisible={() => setVisibleSubModal(false)}
            state={subscriptions.some((x) => x.userId === creatorId && x.status === true) ? 'Unsubscribe' : subscriptions.some((x) => x.userId === creatorId && x.status === false) ? 'Resubscribe' : "Subscribe"}
          />
        </Modal>
      </div>
    <div className={styles.profile}>
      <ToastContainer position='top-center' theme='dark' />
      <div className={styles.details}>
        <div className={styles.avatar}>
          {userAvatar ? (
            <img src={`${process.env.REACT_APP_S3_PUBLIC}${userAvatar}`} alt="Avatar" />
          ) : (
            <div style={{ backgroundColor: `${userData?.theme}` }} className={`profile-img text-[28px] h-full rounded-full flex items-center justify-center`}>{userData?.username?.[0].toUpperCase()}</div>
          )}
        </div>
        <div className={styles.wrap}>
          <div className={cn('h4', styles.man)}>{username}</div>
          <div className={styles.info}>{tagline && tagline}</div>
        </div>
      </div>
    
      <div className={styles.contacts}>
        <div className={styles.socials}>
          {socials.map((x, index) => (
            <a
              className={styles.social}
              href={x.url}
              target='_blank'
              rel='noopener noreferrer'
              key={index}
            >
              <Icon name={x.title} size='24' />
            </a>
          ))}
        </div>

        {/* Conditional rendering of subscribe/unsubscribe button */}
        {isCreator && (
          <div>
            {pathname.slice(1) !== userDetails.username &&
              subscriptions.some((x) => x.userId === creatorId && x.status === true) && (
                <button
                  className={cn('button', styles.button)}
                  onClick={() => setVisibleSubModal(true)}
                  style={{ backgroundColor: 'darkgray' }}
                >
                  Unsubscribe <Icon name={'union'} />
                </button>
              )}
            {pathname.slice(1) !== userDetails.username &&
              (!subscriptions.some((x) => x.userId === creatorId) || subscriptions.length === 0) && (
                <button
                  className={cn('button', styles.button)}
                  onClick={() => setVisibleSubModal(true)}
                >
                  Subscribe for ${`${user[0].creator_price}`}
                </button>
              )}
            {pathname.slice(1) !== userDetails.username &&
              subscriptions.some((x) => x.userId === creatorId && x.status === false) && (
                <button
                  className={cn('button', styles.button)}
                  onClick={() => setVisibleSubModal(true)}
                >
                  Resubscribe
                </button>
              )}

          </div>
        )}
      </div>
    </div>
     </>
  )
}

export default Profile

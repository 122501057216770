import React from 'react'
import cn from 'classnames'
import styles from '../BannerModal/BannerModal.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { Amplify } from 'aws-amplify'
import awsmobile from '../../../aws-exports'
import { generateClient } from 'aws-amplify/api'
import File from '../../../components/File'
import { updateUser } from '../../../graphql/mutations'
import { uploadData } from 'aws-amplify/storage'
import { TextInput } from 'flowbite-react'
const TagLineModal = ({oldTagLine}) => {
  const dispatch = useDispatch()
  const userDetails = useSelector(state => state?.auth?.userDetails)
  Amplify.configure(awsmobile)
  const client = generateClient()
  // State management for banner file upload
  const [tagLine, setTagLine] = useState(oldTagLine)
  const [isSaving, setIsSaving] = useState(false) // State to track button disable
  // Function to handle banner file selection
  async function saveSettings () {
    setIsSaving(true)
    try {
    } catch (error) {
     
    } finally {
      // Update user data with GraphQL - Logic to determine what fields to update
      const updateResult = await client.graphql({
        query: updateUser,
        variables: {
          input: {
            id: userDetails.id,
            tagline:
            userDetails.tagLine === '' && tagLine
              ? tagLine
              : userDetails.tagLine !== '' &&
                tagLine &&
                tagLine !== userDetails.tagLine
              ? tagLine
              : userDetails.tagLine
          }
        }
      })
    
      dispatch({ type: 'UPDATE_USER', payload: updateResult.data.updateUser }) // Dispatch action to update user

      window.location.reload() // Reload the window 
      setIsSaving(false);
    }
  }

  return (
    <>
      <div className={styles.details}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={cn('title-green', styles.title)}>Add Tagline</div>
            <div className={styles.files}>
            <TextInput
              className={styles.field}
              label='Set Tag line'
              name='title'
              value={tagLine}
              type='text'
              maxLength={100} 
              tooltip='Maximum 100 characters. No HTML or emoji allowed'
              onChange={event => setTagLine(event.target.value)}
              required
            />
            </div>
            <div className={styles.btns}>
              {' '}
              <button
                className={cn('button', styles.button)}
                onClick={saveSettings}
                disabled={isSaving} // Disable the button when isSaving is true
              >
                {isSaving ? 'Saving...' : 'Save'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TagLineModal

import React from 'react'
import cn from 'classnames'
import styles from '../BannerModal/BannerModal.module.sass'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { Amplify } from 'aws-amplify'
import awsmobile from '../../../aws-exports'
import { generateClient } from 'aws-amplify/api'
import { updateUser } from '../../../graphql/mutations'
import { TextInput } from 'flowbite-react'
import Card from '../../../components/Card'
import Icon from '../../../components/Icon'
const SocialModal = () => {
  const dispatch = useDispatch()
  const userDetails = useSelector(state => state?.auth?.userDetails)
  Amplify.configure(awsmobile)
  const client = generateClient()
  // State management for banner file upload
  const [socials, setSocials] = useState({
    Twitch: '',
    Youtube: '',
    Twitter: ''
  })

  const actualSocials = ({
    Twitch: `www.twitch.tv/${socials.Twitch}`,
    Youtube: `www.youtube.com/${socials.Youtube}`,
    Twitter: `www.twitter.com/${socials.Twitter}`
  })
  const [isSaving, setIsSaving] = useState(false) // State to track button disable
  // Function to handle banner file selection
  async function saveSettings () {
    setIsSaving(true)
    try {
    } catch (error) {
   
    } finally {
      // Update user data with GraphQL - Logic to determine what fields to update
      const updateResult = await client.graphql({
        query: updateUser,
        variables: {
          input: {
            id: userDetails.id,
            socials:
              !userDetails.socials && socials
                ? JSON.stringify(actualSocials)
                : JSON.stringify(actualSocials) ===
                  JSON.stringify(userDetails.socials)
                ? JSON.stringify(userDetails.socials)
                : null
          }
        }
      })
       dispatch({ type: 'UPDATE_USER', payload: updateResult.data.updateUser }) // Dispatch action to update user

      window.location.reload() // Reload the window
      setIsSaving(false)
    }
  }

  const handleSocialChange = (e, identifier) => {
    setSocials(prev => {
      return {
        ...prev,
        [identifier]: e.target.value
      }
    })
  }

  return (
    <>
      <div className={styles.details}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={cn('title-green', styles.title)}>Add Socials</div>
            <div className={styles.inputBox}>
              <Icon name='Twitch' size='28' />
              <div className='input-container flex border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500 p-2.5 text-sm rounded-lg w-full'>
                <span className='prefix pointer-events-none select-none'>https://www.twitch.tv/</span>
                <input  value={socials.Twitch} onChange={(e) => handleSocialChange(e, 'Twitch')} className='bg-transparent'></input>
              </div>
            </div>
            <div className={styles.inputBox}>
            <Icon name='Youtube' size='28' />
            <div className='input-container flex border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500 p-2.5 text-sm rounded-lg w-full'>
                <span className='prefix pointer-events-none select-none'>https://www.youtube.com/@</span>
                <input value={socials.Youtube} onChange={(e) => handleSocialChange(e, 'Youtube')}  className='bg-transparent'></input>
              </div>
            </div>
            <div className={styles.inputBox}>
            <Icon name='Twitter' size='28' />
            <div className='input-container flex border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-cyan-500 focus:ring-cyan-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-cyan-500 dark:focus:ring-cyan-500 p-2.5 text-sm rounded-lg w-full'>
                <span className='prefix pointer-events-none select-none'>https://twitter.com/</span>
                <input  value={socials.Twitter} onChange={(e) => handleSocialChange(e, 'Twitter')} className='bg-transparent'></input>
              </div>
            </div>
            <div className={styles.btns}>
              {' '}
              <button
                className={cn('button', styles.button)}
                onClick={saveSettings}
                disabled={isSaving} // Disable the button when isSaving is true
              >
                {isSaving ? 'Saving...' : 'Save'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SocialModal

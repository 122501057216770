import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: "user",
    initialState: {
        userData: null,
        userAvatar: null,
        userBanner: null,
        postImages: {},
        posts: [],
        Allcomments: [],
    },
    reducers: {
        setUserData: (state, action) => {
            state.userData = action.payload;
        },
        setAvatar: (state, action) => {
            state.userAvatar = action.payload;
        },
        setUserBanner: (state, action) => {
            state.userBanner = action.payload;
        },
        setPostImage: (state, action) => {
            const { postId, imageUrl } = action.payload;
            state.postImages = { ...state.postImages, [postId]: imageUrl };
        },
        setPosts: (state, action) => {
            state.posts = action.payload;
        },
        setAllComments: (state, action) => {
            state.Allcomments = action.payload;
        },
        setActiveSubscriptions: (state, action) => {
            state.activeSubscriptions = action.payload
        },
    }
})

export const { setUserData, setAvatar, setUserBanner, setPostImage, setPosts, setAllComments } = userSlice.actions;

import { useState } from "react";
import cn from "classnames";
import styles from "./UploadContent.module.sass";
import Card from "../../../components/Card";
import File from "../../../components/File";
import ImagePreview from "./ImagePreview_Component/index";
import { createPortal } from "react-dom";
import { setUploadedContent } from "../../../slices/postSlice";
import { useDispatch } from "react-redux";
import { useFileContext } from "../FileContext";
import { useRef ,useEffect} from "react";
const UploadContent = ({ className }) => {

  
	const dispatch = useDispatch();
	createPortal(ImagePreview, document.getElementById("root"));
	const [fileName, setFileName] = useState("Click or drop file");
	const { setVideoFile, setVideoKey  } = useFileContext()
	const videoRef = useRef(null); // Ref for video element
	const [videoUrl, setVideoUrl] = useState(""); // State to store video UR
	const [showError, setShowError] = useState(false);
    const [errorMsg, seterrorMsg] = useState("");// validation check for correct extension of image
	createPortal(<div id="video-preview" />, document.getElementById("root"));
	useEffect(() => {
		let timeoutId; // Automatically hide error messages after 2 seconds if there is an error
		if (showError) {
		  timeoutId = setTimeout(() => setShowError(false), 3500);
		} else {
		  setShowError(false); // Hide error messages immediately if there is no error
		}
		return () => {
		  clearTimeout(timeoutId); //Clear the timeout when the component unmounts or when showError changes
		};
	  }, [showError]);
	const handleFileChange = (event) => {
		const allowedVideoFormats = ["mp4", "webm", "ogg", "avi", "mov", "wmv", "flv", "mkv"];
		let file = event.target.files[0];
		if(file){
			const fileInfo = {
				name: file.name,
				size: file.size,
				type: file.type,
				// Add more properties if needed
			};
			const fileExtension = file.name.split(".").pop().toLowerCase();
			
			if (!allowedVideoFormats.includes(fileExtension)) {
				setShowError(true);
				seterrorMsg(
				  "Invalid file format! Please select an image file with one of the following extensions: mp4, ogg, avi, mov, wmv,flv,mkv."
				);
				setVideoUrl("");
				setFileName("Click or drop file");
			  }
			  else{
			setVideoFile(file);
			setVideoKey(file.name)
			setFileName(fileInfo.name);
			dispatch(setUploadedContent(fileInfo));
			const videoUrl = URL.createObjectURL(file);
			setVideoUrl(videoUrl);
			  }
		}
	}
	return (
		<Card
			className={cn(styles.card, className)}
			title="Upload Content"
			classTitle="title-blue"
		>
			<div className={styles.images}>
				<File
					className={styles.field}
					title={fileName}
					label="Video upload"
					tooltip="Maximum 100 characters. No HTML or emoji allowed"
					onChange={handleFileChange}
				/>
			 {showError && (
                <span className={styles.error}>
                  <br />

                  {errorMsg}
                </span>
              )}
			</div>
			{videoUrl && (
        <video
		className={styles.video}
          ref={videoRef}
          controls
         
          src={videoUrl}
          
        />
      )}
    </Card>
  );
};

export default UploadContent;

import ReactDOM from "react-dom/client";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { persistor, store } from "./store"; // Import both store and persistor
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { SettingsProvider } from "./screens/Settings/SettingsContext";
import { record } from "aws-amplify/analytics";
Amplify.configure(awsExports);

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <SettingsProvider>
        <App />
      </SettingsProvider>
    </PersistGate>
  </Provider>
);

import { Outlet, Navigate } from "react-router-dom";
// import { useAuth } from './AuthContext'
import { useSelector } from "react-redux";

const PrivateRoutes = () => {
  // const { isAuthenticated } = useAuth();
  const isAuthenticated = useSelector((state) => state.auth.isAutheticated);

  return isAuthenticated ? <Outlet /> : <Navigate to="/my/sign-in" />;
};

export default PrivateRoutes;

import { useState } from "react";
import cn from "classnames";
import styles from "./Panel.module.sass";
import { useNavigate } from "react-router-dom";
// import { usePost } from "../PostContext";
// import { useAuth } from "../../../AuthContext";
import { useSelector } from "react-redux";
import { uploadData } from "aws-amplify/storage";
import { createPosts, updatePosts } from "../../../graphql/mutations";	
import { generateClient } from "aws-amplify/api";
import { useFileContext } from "../FileContext";
import amplifyConfig from "../../../amplifyConfig";
import awsExports from "../../../aws-exports";
import { Amplify } from "aws-amplify";
import LoadingScreen from "../../../components/LoadingScreen/LoadingScreen";

const Panel = ({ setVisiblePreview, setVisibleSchedule }) => {
	const [isLoading, setIsloading] = useState(false);
	const navigate = useNavigate();
	Amplify.configure(amplifyConfig)
	Amplify.configure(awsExports);

	const client = generateClient();


	const productTitle = useSelector(state => state.post.productTitle)
	const productDescription = useSelector(state => state.post.productDescription)
	const userDetails = useSelector(state => state.auth.userDetails)
	const { thumbnail, thumbnailKey, videoFile, videoKey } = useFileContext();


	async function handlePost() {
		setIsloading(true);

		// Initial creation of the post record
		const createResult = await client.graphql({
			query: createPosts,
			variables: {
				input: {
					title: productTitle,
					description: productDescription,
					userID: userDetails.id,
				},
			},
		});

		
		try {
		const thumb = await uploadData({
				key: `${createResult.data.createPosts.id}/${videoKey}`,
				data: videoFile,

				options: {
					accessLevel: "guest", // defaults to `guest` but can be 'private' | 'protected' | 'guest'
				},
			}).result;

			
			const vid = await uploadData({

				key: `${createResult.data.createPosts.id}/${thumbnailKey}`,
				data: thumbnail,


				options: {
					accessLevel: "guest", // defaults to `guest` but can be 'private' | 'protected' | 'guest'
				},
			}).result;
		


		} catch (error) {
		
		} finally {
			// Update the post record with the storage keys
			await client.graphql({
				query: updatePosts,
				variables: {
					input: {
						id: createResult.data.createPosts.id,
						title: productTitle,
						description: productDescription,
						userID: userDetails.id,

						uploadContent: `${createResult.data.createPosts.id}/${videoKey}`,
						thumbnail: `${createResult.data.createPosts.id}/${thumbnailKey}`,

					},
				},
			});
			setIsloading(false);
			navigate(`/`)  // Redirect to the user's profile page
		}
	}

	return (
		<div className={cn("panel", styles.panel)}>
				{isLoading?<LoadingScreen/>:
			<div className={styles.btns}>

				<button
					className={cn("button", styles.button)}
					onClick={handlePost}
				>
					Publish now
				</button>
			</div>}
		</div>
	);
};

export default Panel;

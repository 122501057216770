import  { useState , useEffect} from "react";
import styles from "./Product.module.sass";
import cn from "classnames";
import Details from "./Details";
import Comments from "./Comments";
import { useRef } from "react";
import { Amplify } from "aws-amplify";
import { generateClient } from "aws-amplify/api";
import { listComments } from "../../../graphql/queries";
// import { useContext } from "react";
// import { UserContext } from "../../../UserContext";
import amplifyConfig from "../../../amplifyConfig";
import { useDispatch } from "react-redux";
import { setAllComments } from "../../../slices/userSlice";

const Product = ({item,likes}) => {
  const dispatch = useDispatch();
  // const { Allcomments, setAllcomments} = useContext(UserContext);
  const comRef = useRef([])
  useEffect(() => {
    const ListComments = async () => {
      Amplify.configure(amplifyConfig)
      const client = generateClient()
      try {
        const list_comments = await client.graphql({
          query: listComments,
          variables: {
            filter: {
              postID: { eq: item.id } 
            },
            limit: 10, // Optional: You can specify a limit to the number of comments to retrieve
            nextToken: null // Optional: You can specify a token for pagination, if needed
          }
        })

        comRef.current = list_comments.data.listComments.items
        // setAllcomments(comRef.current)
        dispatch(setAllComments(comRef.current))
    
        
      } catch (error) {
        console.error('Error fetching comments:', error)
      }
    }
    ListComments()
  }, [])


  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClose = () => {
    setActiveIndex(0);
    setVisible(false);
  };

  return (
    <div className={cn(styles.product, { [styles.active]: visible })}>
      <Details
        item={item}
        likes={likes}
        className={styles.details}
        setValue={setVisible}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
      {/* <Comments item={item} className={styles.comments} onClose={() => handleClose()} />
       */}
    </div>
  );
};

export default Product;

import{ useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";


const Dropdown = ({
  className,
  classDropdownHead,
  classDropdownLabel,
  value,
  setValue,
  options,
  label,
  tooltip,
  small,
  upBody,
}) => {
  const [visible, setVisible] = useState(false);

  

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
     
    </OutsideClickHandler>
  );
};

export default Dropdown;
